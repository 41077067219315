import axios from '../plugins/admin';

import qs from 'qs';

// form 表单传参
// eslint-disable-next-line no-unused-vars
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 房源详情
const adminOfficeDetails = async params =>
  axios.get('/office/lease/detail', { params });

export { adminOfficeDetails };
