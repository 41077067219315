<template>
  <div class="b-map" id="bmap">
    <slot></slot>
  </div>
</template>
    
<script>
/*eslint-disable*/
import { BMPGL } from 'plugins/bmp'
import { searchAreaRect } from 'services/common';
import { searchRect, searchPage } from 'services/office';
export default {
  name: 'BMap',
  provide () {
    getMap: this.getMap
  },
  inject: ['reload'],  //注入依赖
  props: {
    adress: {
      type: Object,
      default: { name: '北京', lng: 16.40969, lat: 39.89945 }
    },
    points: Array,
    citys: {
      type: Array,
      default: []
    },
    searchParams: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      ak: 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw',
      point: [],
      city: {},
      street: [],
      streetId: '',
      house: [],
      houseId: '',
      // 储存
      store: {
        district: {},
        street: {}
      },
      // 默认样式-小三角
      triangleStyle: {
        position: 'absolute',
        top: '50px',
        left: '50%',
        backgroundColor: 'transparent',
        borderColor: '#0569FF transparent transparent transparent',
        borderStyle: 'solid',
        borderWidth: '12px',
        overflow: 'hidden',
        padding: '0px',
        transform: 'translate(-50%, 100%)'
      },
      // 选中样式-小三角
      triangleSelStyle: {
        position: 'absolute',
        top: '50px',
        left: '50%',
        backgroundColor: 'transparent',
        borderColor: '#EC6600 transparent transparent transparent',
        borderStyle: 'solid',
        borderWidth: '12px',
        overflow: 'hidden',
        padding: '0px',
        transform: 'translate(-50%, 100%)'
      }
    };
  },
  computed: {
    locationCity: {
      get () {
        return this.$store.state.locationCity
      }
    }
  },
  watch: {
    adress (val) {
      console.log(val);
      if (this.map) {
        let map = this.map
        map.centerAndZoom(this.adress.name, 12)
        //开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true)
        map.setHeading(0)
      }
    },
    citys (val) {
      console.log(this.map);
      if (this.map) {
        const city = localStorage.city
        this.city = JSON.parse(city)
        this.setRegion(val)
      }
    }
  },
  beforeCreate () {
    window._bd_share_main = ""
    window.BMap_loadScriptTime = (new Date()).getTime()
  },
  async mounted () {
    // 储存市
    const city = localStorage.city
    this.store.city = JSON.parse(city)
    let vm = this;
    this.$nextTick(() => {
      this.getMap().then(Map => {
        // 地图相关
        console.log('Map---', Map);
        // window.onload = async () => {
        let map = new Map("bmap", {
          enableRotate: false,
          enableTilt: false
        });
        console.log('map---', map);
        map.centerAndZoom(this.adress.name, 12)
        //开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true)
        // 禁用旋转和翻转
        this.map = map
        if (this.points) {
          console.log('----', this.points);
          this.addMark(...this.points)
        }
        // 判断一段时间内地图是否有初始化
        let t = false
        map.addEventListener('tilesloaded', (type, target) => {
          console.log('tilesloaded----', type, target);
          t = true
          // vm.$nextTick(() => {
          vm.getMapZoom()
          // })
          // 这里边写加载完成之后的执行操作
        })
        // 判断3秒内是否初始化，如果没有就自动刷新页面
        // let ti = setTimeout(() => {
        //   if (!t) {
        //     this.$router.go(0)
        //     // this.reload();
        //     clearInterval(ti)
        //   }
        // }, 5000)

        map.addEventListener('click', function (e) {
        });
        map.addEventListener("movestart", function (e) {
          console.log('地图开始移动了');
        });
        map.addEventListener("zoomend", function (e) {
          vm.getMapZoom()
        });
        map.addEventListener("dragend", function (e) {
          vm.getMapZoom()
          // vm.getDragEnd()
        });
      })
    })
    // }
  },
  methods: {
    getMap () {
      return new Promise(resolve => {
        if (window.BMapGL && window.BMapGL.Map) {
          let Map = window.BMapGL.Map;
          resolve(Map)
        } else {
          let count = 0
          let timer = setInterval(() => {
            count++
            if (count < 200) {
              if (window.BMapGL && window.BMapGL.Map) {
                let Map = window.BMapGL.Map;
                resolve(Map)
                clearInterval(timer)
              }
            } else {
              this.$router.go(0)
              // this.reload();
              clearInterval(timer)
            }
          })
        }
      })
    },
    // 地图添加点坐标
    addMark (lng, lat) {
      console.log(lng, lat);
      this.marker = new BMapGL.Marker(new BMapGL.Point(lng, lat));
      this.map.addOverlay(this.marker);
      // this.$emit('setPoint', [lng, lat])
      // this.point = [lng, lat]
    },
    // 创建区
    setRegion (citys) {
      console.log('citys=>', citys);
      let map = this.map
      let vm = this
      // 绘制行政区
      map.clearOverlays();
      for (let i = 0; i < citys.length; i++) {
        var pt = new BMapGL.Point(citys[i].lng, citys[i].lat);
        var opts = {
          position: pt, // 指定文本标注所在的地理位置
          offset: new BMapGL.Size(-40, -40) // 设置文本偏移量
        };
        // 创建文本标注对象
        citys[i].label = new BMapGL.Label('', opts);
        // 外边的圆形
        citys[i].label1 = new BMapGL.Label(citys[i].name, opts);
        // 自定义文本标注样式
        citys[i].label.setStyle({
          color: '#fff',
          background: 'rgba(25, 115, 250, 1)',
          borderRadius: '50%',
          border: '0px',
          fontSize: '16px',
          height: '76px',
          // boxShadow: ' 0 0 6px 0 rgba(13,4,9,0.5)',
          width: '76px',
          zIndex: '100 !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '微软雅黑'
        });
        // 自定义文本标注样式
        citys[i].label1.setStyle({
          color: '#fff',
          background: 'rgba(25, 115, 250, 0.4)',
          borderRadius: '50%',
          border: '0px',
          height: '98px',
          // boxShadow: ' 0 0 6px 0 rgba(13,4,9,0.5)',
          width: '98px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          fontFamily: '微软雅黑',
          transform: 'translate(-11px, -11px)'
        });
        citys[i].label1.params = citys[i]
        map.addOverlay(citys[i].label);
        map.addOverlay(citys[i].label1);
        // 点标记添加点击事件
        citys[i].label1.addEventListener('click', function (e) {
          console.log('区县=》', e)
          vm.getStreet(e.target.params)
        });
      }
    },
    searchStreet (id) {
      console.log('this.citys=》', this.citys)
      this.citys.forEach(item => {
        console.log('this.citys=》', id, item.id)
        if (id === item.id) this.getStreet(item)
      })
    },
    // 获取街道
    async getStreet (params) {
      const city = localStorage.city
      this.city = JSON.parse(city)
      this.city.lng = params.lng
      this.city.lat = params.lat
      this.city.fullName = params.fullName
      let map = this.map
      var point = new BMapGL.Point(this.city.lng, this.city.lat);
      map.centerAndZoom(point, 14);
      map.enableScrollWheelZoom();
      this.getMapZoom()
    },
    // 创建街道
    setStreet (citys, zoom = 14) {
      console.log('street', citys);
      let vm = this
      let map = this.map
      map.clearOverlays();
      for (let i = 0; i < citys.length; i++) {
        var pt = new BMapGL.Point(citys[i].lng, citys[i].lat);
        var opts = {
          position: pt, // 指定文本标注所在的地理位置
          offset: new BMapGL.Size(0, 0) // 设置文本偏移量
        };
        // 创建文本标注对象
        citys[i].label = new BMapGL.Label(citys[i].name, opts);
        citys[i].triangleLabel = new BMapGL.Label('', opts);
        // 默认样式
        let style = {
          color: '#fff',
          borderRadius: '14px',
          backgroundColor: '#0569FF',
          border: '0px',
          padding: '8px 23px',
          fontSize: '16px',
          height: '30px',
          lineHeight: '30px',
          boxShadow: ' 0 4px 6px 0 rgba(5, 105, 255,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '思源黑体',
          transform: 'translateX(-50%)'
        }
        // 选中样式
        let selStyle = {
          color: '#fff',
          borderRadius: '14px',
          backgroundColor: '#EC6600',
          border: '0px',
          padding: '8px 23px',
          fontSize: '16px',
          boxShadow: ' 0 4px 6px 0 rgba(236, 102, 0,0.5)',
          height: '30px',
          lineHeight: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '思源黑体',
          transform: 'translateX(-50%)'
        }
        if (vm.streetId === citys[i].id) {
          citys[i].label.setStyle(selStyle);
          citys[i].triangleLabel.setStyle(vm.triangleSelStyle);
        } else {
          citys[i].label.setStyle(style);
          citys[i].triangleLabel.setStyle(vm.triangleStyle);
        }
        citys[i].label.params = citys[i]
        citys[i].triangleLabel.params = citys[i]
        map.addOverlay(citys[i].label);
        map.addOverlay(citys[i].triangleLabel);
        // 点标记添加点击事件
        citys[i].label.addEventListener('click', function (e) {
          console.log('街道=》', e.target.params, vm.street)
          vm.getHouse(e.target.params)
          vm.streetId = e.target.params.id
          // 设置选中街道
          vm.street.forEach(item => {
            map.removeOverlay(item.label);
            map.removeOverlay(item.triangleLabel);
            if (item.id === e.target.params.id) {
              // 选中样式
              item.label.setStyle(selStyle);
              item.triangleLabel.setStyle(vm.triangleSelStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            } else {
              // 默认样式
              item.label.setStyle(style);
              item.triangleLabel.setStyle(vm.triangleStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            }
          })
          // 
        });
        citys[i].triangleLabel.addEventListener('click', function (e) {
          console.log('街道=》', e.target.params, vm.street)
          vm.getHouse(e.target.params)
          vm.streetId = e.target.params.id
          // 设置选中街道
          vm.street.forEach(item => {
            map.removeOverlay(item.label);
            map.removeOverlay(item.triangleLabel);
            if (item.id === e.target.params.id) {
              // 选中样式
              item.label.setStyle(selStyle);
              item.triangleLabel.setStyle(vm.triangleSelStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            } else {
              // 默认样式
              item.label.setStyle(style);
              item.triangleLabel.setStyle(vm.triangleStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            }
          })
          // 
        });

      }
    },
    searchHouse (id) {
      this.street.forEach(item => {
        if (id === item.id) this.getHouse(item)
      })
    },
    // 获取楼盘
    async getHouse (params) {
      // 储存点击的街道
      this.store.street = params
      const city = localStorage.city
      this.city = JSON.parse(city)
      this.city.lng = params.lng
      this.city.lat = params.lat
      this.city.fullName = params.fullName
      let map = this.map
      var point = new BMapGL.Point(this.city.lng, this.city.lat);
      map.centerAndZoom(point, 16);
      map.enableScrollWheelZoom();
      this.getMapZoom()
    },
    // 创建楼盘
    setHouse (citys, zoom = 16) {
      let map = this.map
      let vm = this
      // 绘制行政区
      map.clearOverlays();
      for (let i = 0; i < citys.length; i++) {
        var pt = new BMapGL.Point(citys[i].lon, citys[i].lat);
        var opts = {
          position: pt, // 指定文本标注所在的地理位置
          offset: new BMapGL.Size(0, 0) // 设置文本偏移量
        };
        let name = `${citys[i].name}  ·  ${citys[i].leaseOnlineNumber}套`
        // 创建文本标注对象
        citys[i].label = new BMapGL.Label(name, opts);
        citys[i].triangleLabel = new BMapGL.Label('', opts);
        // 默认样式
        let style = {
          color: '#fff',
          borderRadius: '14px',
          backgroundColor: '#0569FF',
          border: '0px',
          padding: '8px 23px',
          fontSize: '14px',
          boxShadow: ' 0 4px 6px 0 rgba(5, 105, 255,0.5)',
          height: '30px',
          lineHeight: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '思源黑体',
          transform: 'translateX(-50%)'
        }
        // 选中样式
        let selStyle = {
          color: '#fff',
          borderRadius: '14px',
          backgroundColor: '#EC6600',
          border: '0px',
          padding: '8px 23px',
          fontSize: '14px',
          boxShadow: ' 0 4px 6px 0 rgba(236, 102, 0,0.5)',
          height: '30px',
          lineHeight: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '思源黑体',
          transform: 'translateX(-50%)'
        }
        if (vm.houseId === citys[i].id) {
          citys[i].label.setStyle(selStyle);
          citys[i].triangleLabel.setStyle(vm.triangleSelStyle);
        } else {
          citys[i].label.setStyle(style);
          citys[i].triangleLabel.setStyle(vm.triangleStyle);
        }
        citys[i].label.params = citys[i]
        citys[i].triangleLabel.params = citys[i]
        map.addOverlay(citys[i].label);
        map.addOverlay(citys[i].triangleLabel);
        // 点标记添加点击事件
        citys[i].label.addEventListener('click', function (e) {
          console.log('房屋=》', e.target.params, vm.house)
          vm.houseId = e.target.params.id
          vm.$emit('getItem', e.target.params)
          vm.getHousePage(1, e.target.params)
          vm.$parent.show = true
          // 设置选中房屋
          vm.house.forEach(item => {
            map.removeOverlay(item.label);
            map.removeOverlay(item.triangleLabel);
            if (item.id === e.target.params.id) {
              // 选中样式
              item.label.setStyle(selStyle);
              item.triangleLabel.setStyle(vm.triangleSelStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            } else {
              // 默认样式
              item.label.setStyle(style);
              item.triangleLabel.setStyle(vm.triangleStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            }
          })
          // 
        });
        citys[i].triangleLabel.addEventListener('click', function (e) {
          console.log('房屋=》', e.target.params, vm.house)
          vm.houseId = e.target.params.id
          vm.$emit('getItem', { ...e.target.params, lat: e.target.params.lat, lng: e.target.params.lon })
          vm.getHousePage(1, e.target.params)
          vm.$parent.show = true
          // 设置选中房屋
          vm.house.forEach(item => {
            map.removeOverlay(item.label);
            map.removeOverlay(item.triangleLabel);
            if (item.id === e.target.params.id) {
              // 选中样式
              item.label.setStyle(selStyle);
              item.triangleLabel.setStyle(vm.triangleSelStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            } else {
              // 默认样式
              item.label.setStyle(style);
              item.triangleLabel.setStyle(vm.triangleStyle);
              map.addOverlay(item.label);
              map.addOverlay(item.triangleLabel);
            }
          })
          // 
        });

      }
    },
    // 地图缩放事件
    async getMapZoom () {
      console.log(this.map.getZoom(), this.citys) // 获取缩放级别
      // 获取地图可视范围
      let bs = this.map.getBounds();
      let min = bs.getSouthWest()
      let max = bs.getNorthEast()
      console.log('西南角', bs.getSouthWest(), '东北角', bs.getNorthEast())
      // 其余操作
      let zoom = this.map.getZoom()
      // zoom 14 > 区  16 > 街道 >= 14  16 >= 房源
      if (zoom < 14) {
        // 获取区
        // this.$parent.show = false
        this.$parent.districtId = ''
        this.$parent.streetId = ''
        this.setRegion(this.citys)
      } else if (zoom < 16 && zoom >= 14) {
        // 获取街道
        let params = {
          type: 4,
          cityId: this.city.cityId,
          minLat: min.lat,
          minLng: min.lng,
          maxLat: max.lat,
          maxLng: max.lng,
        }
        let res = await searchAreaRect(params)
        if (res.data.status === 200) {
          this.street = res.data.data
          this.$nextTick(() => {
            this.setStreet(this.street, 14)
          })
        }
      } else {
        this.$parent.$refs.PlantMapList.show = true
        // 获取房源
        let params = {
          cityId: this.city.cityId,
          minLat: min.lat,
          minLng: min.lng,
          maxLat: max.lat,
          maxLng: max.lng,
          ...this.searchParams
        }
        let res = await searchRect(params)
        if (res.data.status === 200) {
          this.house = res.data.data
          this.$nextTick(() => {
            this.setHouse(this.house, 14)
          })
        }
      }
    },
    // 获取房源分页
    async getHousePage (page, build) {
      let params = {
        cityId: this.city.cityId,
        buildingId: build.id,
        page,
        ...this.searchParams
      }
      let res = await searchPage(params)
      if (res.data.status === 200) {
        this.$emit('getHousePage', res.data, build)
      }
    },
    // 房源列表定位
    houseLocation (e) {
      console.log(e);
      var point = new BMapGL.Point(e.lon || e.showLng, e.lat || e.showLat);
      this.map.centerAndZoom(point, 16);
      this.houseId = e.id
      // 默认样式
      let style = {
        color: '#fff',
        borderRadius: '14px',
        backgroundColor: '#0569FF',
        border: '0px',
        padding: '8px 23px',
        fontSize: '14px',
        boxShadow: ' 0 0 6px 0 rgba(13,4,9,0.5)',
        height: '28px',
        lineHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: '思源黑体',
        transform: 'translateX(-50%)'
      }
      // 选中样式
      let selStyle = {
        color: '#fff',
        borderRadius: '14px',
        backgroundColor: '#EC6600',
        border: '0px',
        padding: '8px 23px',
        fontSize: '14px',
        boxShadow: ' 0 0 6px 0 rgba(13,4,9,0.5)',
        height: '28px',
        lineHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: '思源黑体',
        transform: 'translateX(-50%)'
      }
      this.house.forEach(item => {
        this.map.removeOverlay(item.label);
        this.map.removeOverlay(item.triangleLabel);
        if (item.id === e.id) {
          // 选中样式
          item.label.setStyle(selStyle);
          item.triangleLabel.setStyle(this.triangleSelStyle);
          this.map.addOverlay(item.label);
          this.map.addOverlay(item.triangleLabel);
        } else {
          // 默认样式
          item.label.setStyle(style);
          item.triangleLabel.setStyle(this.triangleStyle);
          this.map.addOverlay(item.label);
          this.map.addOverlay(item.triangleLabel);
        }
      })
    },
    // 自定义覆盖物
    getMark (lng, lat, title) {
      let map = this.map
      // map.enableKeyboard();
      // map.enableScrollWheelZoom();

      // 自定义展示内容
      function createDOM () {
        var div = document.createElement('div');
        div.style.zIndex = BMapGL.Overlay.getZIndex(this.point.lat);
        div.style.backgroundColor = '#EC6600';
        div.style.color = '#fff';
        div.style.whiteSpace = 'nowrap';
        div.style.MozUserSelect = 'none';
        div.style.boxShadow = ' 0 0 6px 0 rgba(13,4,9,0.5)';
        div.style.borderRadius = '50px';
        div.style.display = 'flex';
        div.style.justifyContent = 'center';
        div.style.alignItems = 'center';
        div.style.transform = 'translateX(50%)'

        var title = document.createElement('div');
        title.style.display = 'block';
        title.style.fontSize = '16px';
        title.style.padding = '5px 10px';
        div.appendChild(title);
        title.appendChild(document.createTextNode(this.properties.title));

        var arrow = document.createElement('div');
        arrow.style.position = 'absolute';
        arrow.style.top = '30px';
        arrow.style.left = '50%-8px';
        arrow.style.width = '0';
        arrow.style.height = '0';
        arrow.style.borderColor = '#EC6600 transparent transparent transparent';
        arrow.style.borderStyle = 'solid';
        arrow.style.borderWidth = '10px';
        arrow.style.overflow = 'hidden';
        div.appendChild(arrow);
        return div;
      }
      // 创建自定义覆盖物
      console.log(lng, lat);
      var customOverlay = new BMapGL.CustomOverlay(createDOM, {
        point: new BMapGL.Point(lng, lat),
        opacity: 0.5,
        offsetY: 0,
        offsetX: 0,
        properties: {
          title,
        }
      });
      map.addOverlay(customOverlay);
      return customOverlay
    }


  }
};
</script>
<style lang="less" scoped>
.b-map {
  width: 100%;
  height: 100%;
}

/deep/ .anchorBL {
  // display: none;
}
/deep/ .BMap_cpyCtrl {
  // display: none;
}
</style>
    