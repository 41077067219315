<template>
  <div class="indoorModel">
    <div id="indoorModel"></div>
    <div class="cut" @click="toModel">
      <span>孪生园区</span>
      <i class="icon icon-model-left"></i>
    </div>
    <div class="operation">
      <div class="indoorModel-show open" @click="showTo">
        <i v-if="show" class="icon icon-model-top-7"></i>
        <i v-else class="icon icon-model-top-8"></i>
        <p>{{ show ? '收起' : '展开' }}</p>
      </div>
      <transition name="plus-icon">
        <div class="item" v-if="show">
          <div
            class="indoorModel-show"
            :class="pattern === 1 ? 'active' : ''"
            @click="zoomIn"
          >
            <i class="icon icon-model-top-1"></i>
            <p>前进模式</p>
          </div>
          <div
            class="indoorModel-show"
            :class="pattern === 2 ? 'active' : ''"
            @click="zoomOut"
          >
            <i class="icon icon-model-top-2"></i>
            <p>后退模式</p>
          </div>
          <div
            class="indoorModel-show"
            :class="pattern === 3 ? 'active' : ''"
            @click="rotateLeft"
          >
            <i class="icon icon-model-top-3"></i>
            <p>视角模式</p>
          </div>
        </div>
      </transition>

      <transition name="plus-icon">
        <div class="item" v-if="show">
          <div class="indoorModel-show" @click="rotateRight">
            <i class="icon icon-model-top-4"></i>
            <p>重置视角</p>
          </div>
          <!-- <div class="indoorModel-show" @click="flipUp">
            <i class="icon icon-model-top-5"></i>
            <p>后退</p>
          </div> -->
          <div
            class="indoorModel-show"
            :class="operation === 3 ? 'active' : ''"
            @click="setOperation"
          >
            <i class="icon icon-model-top-6"></i>
            <p>操作说明</p>
          </div>
        </div>
      </transition>
    </div>
    <transition name="plus-icon">
      <div class="operation-model" v-if="operation === 3">
        <div class="title">
          操作说明 <i class="el-icon-close" @click="operation = null"></i>
        </div>
        <div class="li">
          <div class="tit">前进模式</div>
          <div class="content">
            你可以长按鼠标，模拟室内第一视角的看房模式，并根据鼠标的位置，调整行走的方向。
          </div>
        </div>
        <div class="li">
          <div class="tit">后退模式</div>
          <div class="content">该模式下，您是以后退的模式行走。</div>
        </div>
        <div class="li">
          <div class="tit">视角模式</div>
          <div class="content">
            该模式下，您固定当前位置，只能调整视角，方便您查看室内环境。
          </div>
        </div>
        <div class="li">
          <div class="tit">重置视角</div>
          <div class="content">回到默认初始位置。</div>
        </div>
        <!-- <div class="li">
          <div class="tit">后退</div>
          <div class="content">当前位置往后退1米。</div>
        </div> -->
      </div>
    </transition>
    <div
      class="panel"
      :style="{ top: top + 'px', left: left + 'px' }"
      v-if="visible"
    >
      <div class="name">{{ details.name }}</div>
      <main>
        <div class="p" v-if="details['材质']">
          <label>材质：</label>
          <span>{{ details['材质'] }}</span>
        </div>
        <div class="p" v-if="details['高度']">
          <label>高度：</label>
          <span>{{ details['高度'] }}</span>
        </div>
        <div class="p" v-if="details['载重']">
          <label>载重：</label>
          <span>{{ details['载重'] }}</span>
        </div>
      </main>
    </div>
    <div class="list">
      <i
        class="el-icon-arrow-left"
        v-show="dataList.length > 8"
        @click="toLeft"
      ></i>
      <main ref="container">
        <div class="list-box" :style="{ transform: `translateX(${length}px)` }">
          <div
            class="list-item"
            v-for="(item, index) in dataList"
            :key="index"
            @click="changeSkyBox(item, index)"
          >
            <div class="item" :class="currentIndex === index ? 'active' : ''">
              {{ dataList[index].name }}
            </div>
          </div>
        </div>
      </main>
      <i
        class="el-icon-arrow-right"
        v-show="dataList.length > 8"
        @click="toRight"
      ></i>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
// const modelData = window.modelData
export default {
  name: 'indoorModel',
  components: {
  },
  data () {
    return {
      modelData: {},
      v_data: {},
      show: true,
      layeringModel: null,
      provider: null,
      pattern: 1,
      operation: 3,
      detailsId: '',
      data: {
        "Rectangle316": {
          "name": "地板",
          "材质": "水磨石材质",
          "location": { "target": [3.334420532902988, 0.5113766074211374, 0.5113766074211374], "position": [0, 2, 0] }
        },
        "Rectangle313": {
          "name": "承重柱",
          "高度": "4m",
          "location": { "target": [23.7001171875, 2.76521998687876, 5.76521998687876], "position": [20.464215467029266, 2, 2.649587235692582] }
        },
        "Line074": {
          "name": "消防管道",
          "材质": "球墨给水铸铁管",
          "location": { "target": [16.57694302972766, 4.379999212493115, 0.07734643816344279], "position": [12.242915171000613, 2, -0.22645408130786437] }
        },
        "对象086": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [-2.780179687499995, 2.139800566718651, -13.492772686063873], "position": [1.4266549325517468, 2, -10.874126616080648] }
        },
        "对象085": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [-2.780179687499995, 2.1439002248198418, -15.889916908639332], "position": [1.4266549325517468, 2, -10.874126616080648] }
        },
        "对象084": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [2.377226562499999, 2.1405283029306847, -13.448361121874285], "position": [-1.3365275632339868, 2, -11.930677948927203] }
        },
        "对象083": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [2.377226562499999, 2.115895551022064, -15.881362321382333], "position": [-1.3365275632339868, 2, -11.930677948927203] }
        },
        "对象088": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [-4.198024074931212, 2.1326170185736935, -9.573795898437501], "position": [-6.3323363141873, 2, -6.502629756828553] }
        },
        "对象091": {
          "name": "货梯",
          "载重": "2500kg",
          "驱动方式": "液压驱动",
          "速度": "0.25m/s",
          "尺寸": "2000mm*1400mm*2200mm",
          "location": { "target": [-72.48071142578125, 2.0357420584773505, 9.360747627479494], "position": [-53.82836286499088, 2, 12.630172502686735] }
        },
        "对象090": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [-72.48071142578125, 2.0036001338654934, 13.104005792725697], "position": [-53.82836286499088, 2, 12.630172502686735] }
        },
        "对象089": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [-72.48071142578125, 2.003607798909445, 16.19985459422167], "position": [-53.82836286499088, 2, 12.630172502686735] }
        },
        "对象080": {
          "name": "货梯",
          "载重": "2500kg",
          "驱动方式": "液压驱动",
          "速度": "0.25m/s",
          "尺寸": "2000mm*1400mm*2200mm",
          "location": { "target": [72.07776562500001, 2.1099317056171527, 9.367634794676194], "position": [57.62880284844131, 2, 12.07931462484175] }
        },
        "对象081": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [72.07776562500001, 2.0476321499045205, 12.914864060330263], "position": [57.62880284844131, 2, 12.07931462484175] }
        },
        "对象082": {
          "name": "电梯",
          "载重": "1000kg",
          "驱动方式": "交流双速驱动",
          "速度": "1.00m/s",
          "尺寸": "1350mm*1400mm*2200mm",
          "location": { "target": [72.07776562500001, 1.993564819446715, 16.178025612726604], "position": [57.62880284844131, 2, 12.07931462484175] }
        }

      },
      dataList: [],
      details: {},
      count: 0,
      length: null,
      currentMesh: null,
      visible: false,
      top: 100,
      left: 20,
      currentIndex: null,
    }
  },
  beforeCreate () {
    window._bd_share_main = ""
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleWatchEnter);
  },
  mounted () {
    console.log(this.$route.params.detailsId !== '1309558086624763904')
    // if (this.$route.params.detailsId === '1309558086624763904') {
    //   for (const key in this.data) {
    //     if (Object.hasOwnProperty.call(this.data, key)) {
    //       this.dataList.push({
    //         ...this.data[key],
    //         key
    //       })
    //     }
    //   }
    // }

    document.body.style.overflow = 'hidden'
    this.detailsId = this.$route.params.detailsId
    let _this = this
    document.addEventListener('keydown', _this.handleWatchEnter);
    this.$nextTick(() => {
      fetch(window.modelDataIp + "/modelData.json").then(function (response) {
        return response.json();
      }).then(data => {
        _this.modelData = data
        var ip = _this.modelData.ip
        // 判断id
        _this.v_data = _this.modelData.data[this.detailsId] ? _this.modelData.data[_this.detailsId] : _this.modelData.data['1310993948029902848']
        _this.addModel(window.page + ip, _this.v_data)
      })
    })

  },
  methods: {
    toModel () {
      this.$router.push({
        path: '/model/1/' + this.detailsId
      })
    },
    getMap () {
      return new Promise(resolve => {
        if (window.BMapGL && window.BMapGL.Map) {
          let Map = window.BMapGL.Map;
          resolve(Map)
        } else {
          let timer = setInterval(() => {
            if (window.BMapGL && window.BMapGL.Map) {
              let Map = window.BMapGL.Map;
              resolve(Map)
              clearInterval(timer)
            }
          })
        }
      })
    },
    addModel (ip, data) {
      let _this = this
      //初始化 
      this.scene = new PersonScene("indoorModel");
      const s = this.scene.addModel(ip + data.url3, e => {
        //加载进度
        console.log('-------------', e)
        // console.log(e.loaded / e.total)
      });
      s.then(function () {
        _this.scene.addSkyBox(ip + "/back.jpg")
      })
      document.getElementById("indoorModel").addEventListener("click", function (e) {
        if (_this.scene.mode === 2) {
          //获取点击的元素
          var features = _this.scene.getFeatures(e.offsetX, e.offsetY);

          var mesh = features[0] ? features[0].object : undefined;
          //模型颜色
          // _this.handleMesh(mesh);
          //根据名称获取对应的信息
          // _this.visible = false
          // _this.dataList.forEach((item, index) => {
          //   if (item.key === mesh.name) {
          //     _this.details = item
          //     _this.currentIndex = index
          //     _this.visible = true
          //     _this.top = e.offsetY
          //     _this.left = e.offsetX
          //   }
          // })
        }
      })

      //设置前进后退速度，默认0.1
      //scene.speed=0.1
      //视角距离墙的最短距离,默认1
      //scene.minDistance=1
      //设置前进（0），后退（1），原地旋转（2）,默认0
      //scene.mode=0
      //根据x,y的偏移旋转，x>0，向右；y>0，向上
      //scene.rotate(1,1)
      //前进，后退方法，不带动画
      //scene.moveForward(1)
      //向左，向右平移，不带动画
      //scene.moveRight(1)
      //返回初始位置
      //scene.reset()
      //前进，后退多少单位的值
      //scene.pan(-10)
    },
    handleMesh (mesh) {
      if (mesh && this.data[mesh.name]) {
        if (!this.currentMesh) {
          this.currentMesh = mesh;
          this.currentMesh.changeColor(new THREE.Color("#0000ff"))
        } else if (mesh !== this.currentMesh) {
          //修改颜色
          mesh.changeColor(new THREE.Color("#0000ff"))
          //恢复原本的颜色
          this.currentMesh.resetColor();
          this.currentMesh = mesh;
        }

      } else {
        if (this.currentMesh) {
          this.currentMesh.resetColor();
          this.currentMesh = undefined;
        }
      }
    },
    changeSkyBox (item, index) {
      let _this = this
      this.scene.moveTo(item.location)
      var mesh = _this.scene.getMeshByName(item.key);
      _this.visible = false
      _this.details = item
      // _this.visible = true
      _this.currentIndex = index
      _this.handleMesh(mesh);
    },
    showTo () {
      this.show = !this.show
    },
    zoomIn () {
      this.pattern = 1
      this.scene.mode = 0;
    },
    zoomOut () {
      this.pattern = 2
      this.scene.mode = 1;
    },
    rotateLeft () {
      this.pattern = 3
      this.scene.mode = 2;
    },
    rotateRight () {
      if (this.operation === 1) {
        this.operation = null
      } else {
        this.operation = 1
        this.scene.reset();
      }
    },
    flipUp () {
      if (this.operation === 2) {
        this.operation = null
      } else {
        this.operation = 2
        this.scene.pan(-10);
      }
    },
    setOperation () {
      if (this.operation === 3) {
        this.operation = null
      } else {
        this.operation = 3
      }
    },

    toLeft () {
      if (this.dataList.length > 0) {
        this.count -= 1;
        if (this.count < 0) {
          this.count = this.dataList.length - 8;
          this.length = -this.count * 130
        } else {
          this.length = -this.count * 130
        }
      }
    },
    toRight () {
      if (this.dataList.length > 0) {
        if (this.count < this.dataList.length - 8) {
          this.count += 1;
          this.length = -this.count * 130
        } else {
          this.count = 0
          this.length = 0
        }
      }
    },

    // 键盘移动事件
    handleWatchEnter (e) {
      console.log(e.keyCode);
      if (this.pattern === 1) {
        // 上
        if (e.keyCode === 87 || e.keyCode === 38) {
          this.scene.moveForward(0.06)
        }
        // 下
        if (e.keyCode === 83 || e.keyCode === 40) {
          this.scene.moveForward(-0.06)
        }
        // 左
        if (e.keyCode === 65 || e.keyCode === 37) {
          this.scene.rotate(-1.5, 0)
          this.scene.moveForward(0.06)
        }
        // 右
        if (e.keyCode === 68 || e.keyCode === 39) {
          this.scene.rotate(1.5, 0)
          this.scene.moveForward(0.06)
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
#indoorModel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none; /*用户是否能够选中文本*/

  touch-action: none;

  touch-action: pan-y;
}

img {
  pointer-events: none;
}
.indoorModel {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: initial !important;
  overflow: hidden;
  .plus-icon-enter-active {
    transition: opacity 0.5s;
  }
  .plus-icon-enter {
    opacity: 0;
  }
  .plus-icon-leave-active {
    transition: opacity 0.5s;
  }
  .plus-icon-leave-to {
    opacity: 0;
  }
}
@media only screen and (min-device-width: 768px) {
  .operation {
    position: absolute;
    top: 100px;
    right: 20px;
    z-index: 100;
    .item {
      margin-top: 12px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.24);
      .indoorModel-show {
        background: transparent;
      }
    }
  }
  .panel {
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 100;
    border-radius: 6px;
    background: rgba(51, 26, 0, 0.8);
    border: 0.8px solid rgba(255, 143, 31, 1);
    box-shadow: 0px 0px 12px rgba(255, 152, 74, 0.6);
    overflow: hidden;
    .name {
      height: 44px;
      padding-left: 20px;
      line-height: 44px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      background: linear-gradient(
        180deg,
        rgba(92, 49, 0, 0) 0%,
        rgba(255, 151, 33, 1) 100%
      );
    }
    main {
      padding: 17px 20px;
      .p {
        display: flex;
        align-items: center;
        label {
          font-size: 18px;
          font-weight: 400;
          line-height: 26.06px;
          color: rgba(255, 152, 74, 1);
        }
        span {
          font-size: 18px;
          font-weight: 400;
          line-height: 26.06px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .cut {
    display: none;
  }
  .indoorModel-show {
    width: 88px;
    height: 82px;
    opacity: 1;
    cursor: pointer;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.27px;
      color: rgba(255, 255, 255, 1);
    }
    &:active {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
    }
    &.active {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 1) 100%
      ) !important;
    }
  }
  .operation-model {
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 100;
    width: 360px;
    height: 434px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    .title {
      height: 64px;
      opacity: 1;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      text-align: center;
      line-height: 64px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      i {
        display: none;
      }
    }
    .li {
      padding: 0px 24px;
      margin-bottom: 16px;
      .tit {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          opacity: 1;
          border-radius: 50%;
          background: rgba(255, 255, 255, 1);
          margin-right: 9px;
        }
      }
      .content {
        padding-left: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.27px;
        color: rgba(238, 238, 238, 1);
      }
    }
  }

  .list {
    position: absolute;
    bottom: 100px;
    left: 50%;
    z-index: 100;
    // width: 520px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%);
    .scrollSlider {
      display: none;
    }
    i {
      width: 42px;
      height: 42px;
      opacity: 1;
      border-radius: 8px;
      display: inline-block;
      text-align: center;
      line-height: 42px;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      background: linear-gradient(
        180deg,
        rgba(31, 31, 31, 0.8) 0%,
        rgba(117, 117, 117, 0.8) 99.31%
      );

      border: 0.6px solid rgba(230, 202, 181, 1);
      box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.5);
      &:last-child {
        margin-left: 10px;
      }
    }
    main {
      max-width: 1040px;
      // max-width: 170px;
      height: 42px;
      overflow: hidden;
    }

    .list-box {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      height: 42px;
    }
    .list-item {
      display: flex;
      align-items: center;
    }
    .item {
      display: inline-block;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      height: 42px;
      width: 120px;
      padding: 0 12px;
      margin-left: 10px;
      border-radius: 6px;
      background: linear-gradient(
        180deg,
        rgba(31, 31, 31, 0.8) 0%,
        rgba(117, 117, 117, 0.8) 99.31%
      );

      border: 0.6px solid rgba(230, 202, 181, 0.5);
      box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.5);
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.active {
        background: linear-gradient(
          132.84deg,
          rgba(237, 103, 0, 1) 0%,
          rgba(255, 209, 140, 1) 100%
        );

        border: 1px solid rgba(255, 238, 222, 0.5);
        color: #fff;
      }
    }
  }
}
@media only screen and (max-device-width: 768px) {
  .open {
    display: none !important;
  }
  .cut {
    user-select: none;
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 100;
    width: 91px;
    height: 30px;
    border-radius: 4px;
    background: rgba(51, 51, 51, 0.6);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
      margin-right: 10px;
    }
    i {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }
  .operation {
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      margin-top: 12px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-right: 2px;
      }
      .indoorModel-show {
        background: transparent;
      }
    }
  }
  .indoorModel-show {
    width: 60px;
    height: 60px;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20.27px;
      color: rgba(255, 255, 255, 1);
    }
    i {
      width: 22px;
      height: 22px;
    }
    &.active {
      opacity: 1;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 1) 100%
      ) !important;
    }
  }

  .operation-model {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 76px;
    z-index: 100;
    width: 360px;
    height: 434px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    .title {
      height: 64px;
      opacity: 1;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      text-align: center;
      line-height: 64px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      position: relative;

      i {
        position: absolute;
        top: calc(50% - 4px);
        right: 20px;
      }
    }
    .li {
      padding: 0px 24px;
      margin-bottom: 16px;
      .tit {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          opacity: 1;
          border-radius: 50%;
          background: rgba(255, 255, 255, 1);
          margin-right: 9px;
        }
      }
      .content {
        padding-left: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.27px;
        color: rgba(238, 238, 238, 1);
      }
    }
  }
}
</style>