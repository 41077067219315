<template>
  <div class="my-earnings">
    <div class="activity">
      <div class="activity-l">
        <img src="../../assets/user/activity.png" alt="" />
        <div class="text">
          <div class="t1">邀好友 赚现金</div>
          <div class="t2">邀请好友充值或购买套餐，可获得30%的收益返现</div>
        </div>
      </div>
      <div class="activity-r" @click="toDetails">邀请好友</div>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="label">总收益</div>
        <div class="num">200.00</div>
      </div>
      <div class="content-item">
        <div class="label">余额</div>
        <div class="num">40.00</div>
      </div>
      <div class="content-item">
        <div class="label">邀请人数</div>
        <div class="num">2</div>
      </div>
      <div class="content-item">
        <div class="details" @click="getDetails">查看明细</div>
        <div class="btn" @click="toWithdraw">提现</div>
      </div>
    </div>
    <div class="invitation-rules">
      <p class="title">邀请规则</p>
      <p class="text">1、邀请的好友没有上限。</p>
      <p class="text">
        2、邀请的好友充值龙币或购买套餐，所支付的实际金额的30%会作为您的余额，存入钱包账户。
      </p>
      <p class="text">
        3、充值龙币后无法退款，若因为不可抗力因素产生退款行为，亿楼有权追溯已发奖励。
      </p>
      <p class="text">
        4、邀请好友复制邀请链接，表示您已阅读并同意<span
          style="color: rgba(236, 102, 0, 1)"
          @click="toPush('/cashbackAgreement')"
          >返现协议</span
        >
        。
      </p>
    </div>
    <!-- 查看明细 -->
    <el-dialog title="查看明细" :visible.sync="dialogTableVisible">
      <div class="tab-date">
        <div class="tab">
          <div
            class="tab-item"
            :class="tabIndex === item.id ? 'active' : ''"
            v-for="item in tab"
            :key="item.id"
            @click="tabClick(item)"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="date">
          <el-date-picker v-model="value2" type="month" placeholder="选择月">
          </el-date-picker>
        </div>
      </div>
      <el-table
        border
        :data="gridData"
        :header-cell-style="{
          background: 'rgba(245, 245, 245, 1)'
        }"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="date"
          label="角色ID"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="name"
          label="注册号码"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="address"
          label="购买时间"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="address"
          label="购买类型"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="address"
          label="订单金额（元）"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 1"
          property="address"
          label="收益（元）"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 2"
          property="address"
          label="提现状态"
        >
          <template slot-scope="scope">
            <div
              :style="{
                color:
                  scope.row.name === 1
                    ? 'rgba(153, 153, 153, 1)'
                    : scope.row.name === 2
                    ? 'rgba(8, 107, 255, 1)'
                    : 'rgba(255, 18, 18, 1)'
              }"
            >
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tabIndex === 2"
          property="address"
          label="提现金额（元）"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 2"
          property="address"
          label="提现时间"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 2"
          property="name"
          label="支付宝账号"
        ></el-table-column>
        <el-table-column
          v-if="tabIndex === 2"
          property="date"
          label="提现单号"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 提现 -->
    <WithdrawDepositDialog
      ref="WithdrawDepositDialog"
      :balanceYuan="balanceYuan"
      @withdrawSuccess="withdrawSuccess"
    />
  </div>
</template>
<script>
const los = window.los
const uploadIp = window.uploadIp
import WithdrawDepositDialog from 'components/user/WithdrawDepositDialog.vue';
export default {
  name: 'MyEarnings',
  components: {
    WithdrawDepositDialog
  },
  data () {
    return {
      los,
      uploadIp,
      tabIndex: 1,
      tab: [
        { id: 1, text: "收益明细" },
        { id: 2, text: "提现明细" },
      ],
      value2: '',
      dialogTableVisible: false,
      gridData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      balanceYuan: 0
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    toWithdraw () {
      this.$refs.WithdrawDepositDialog.balanceVisible = true
    },
    withdrawSuccess () {
    },
    toDetails () {
      let routeUrl = this.$router.resolve({
        path: '/activityDetails',
      })
      window.open(routeUrl.href, '_blank');
    },
    getDetails () {
      this.dialogTableVisible = true
    },
    tabClick (item) {
      this.tabIndex = item.id
    },
    toPush (path) {
      let routeUrl = this.$router.resolve({
        path
      })
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style lang="less" scoped>
.my-earnings {
  height: 100%;
  padding: 40px 36px 24px 24px;
  // background: rgba(236, 102, 0, 1);
  .activity {
    padding: 0 35px;
    width: 100%;
    height: 160px;
    opacity: 1;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      rgba(255, 235, 214, 1) 0%,
      rgba(255, 247, 235, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-l {
      display: flex;
      align-items: center;
      img {
        width: 200px;
        height: 160px;
        margin-right: 28px;
      }
      .text {
        .t1 {
          font-size: 28px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 40.54px;
          color: rgba(194, 92, 14, 1);
        }
        .t2 {
          font-size: 17px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 24.62px;
          color: rgba(209, 151, 107, 1);
        }
      }
    }
    &-r {
      width: 120px;
      height: 46px;
      opacity: 1;
      border-radius: 60px;
      background: linear-gradient(
        180deg,
        rgba(236, 79, 0, 1) 0%,
        rgba(255, 179, 48, 1) 100%
      );
      box-shadow: 0px 2px 4px rgba(236, 102, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
  .content {
    margin-top: 30px;
    width: 100%;
    height: 160px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(250, 249, 247, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 62px;
    &-item {
      .label {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 31.86px;
        color: rgba(153, 153, 153, 1);
      }
      .num {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 43.44px;
        color: rgba(51, 51, 51, 1);
      }
      .details {
        width: 100px;
        height: 38px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        border: 0.5px solid rgba(236, 102, 0, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        text-align: center;
        color: rgba(236, 102, 0, 1);
        margin-bottom: 14px;
        cursor: pointer;
      }
      .btn {
        width: 100px;
        height: 38px;
        opacity: 1;
        border-radius: 4px;
        background: linear-gradient(
          90deg,
          rgba(236, 102, 0, 1) 0%,
          rgba(236, 102, 0, 1) 100%
        );
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .invitation-rules {
    margin-top: 30px;
    p.title {
      font-size: 18px;
      font-weight: 600;
      line-height: 26.06px;
      color: rgba(0, 0, 0, 1);
    }
    p.text {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      color: rgba(102, 102, 102, 1);
      span {
        cursor: pointer;
      }
    }
  }
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
  .tab-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(245, 245, 245, 1);
    margin-bottom: 12px;
    .tab {
      display: flex;
      align-items: center;
      &-item {
        margin-left: 40px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 36px;
        color: rgba(0, 0, 0, 1);
        cursor: pointer;
        &.active {
          color: rgba(236, 102, 0, 1);
          position: relative;
          &::after {
            content: '';
            position: absolute;
            z-index: 10;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
            width: 16px;
            height: 2px;
            opacity: 1;
            border-radius: 8px;
            background: rgba(236, 102, 0, 1);
          }
        }
      }
    }
    .date {
      .el-date-editor .el-input__inner {
        height: 34px;
        line-height: 34px;
      }
      .el-input__icon {
        display: flex;
        align-items: center;
      }
    }
  }
}
.body {
  /deep/ .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    background: rgba(247, 247, 247, 1);
    border: 0;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: rgb(51, 51, 51);
}
/deep/ .el-input__prefix {
  display: flex;
  align-items: center;
}

/deep/ .el-table .el-table__cell {
  padding: 8px 0px;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0;
}
</style>