<template>
  <div class="counselor-home">
    <!--详情头部-->
    <DetailsHeader />
    <!--详情搜索-->
    <DetailsSearch :logoBtn="false" :search="false" :content="false" />
    <!--详情主内容头部-->
    <div class="counselor-header-content">
      <main>
        <div class="tx flex-align-center">
          <img
            class="img"
            :src="los + user.headImg + '?' + Date.now()"
            alt=""
          />
          <!-- <el-rate disabled v-model="value1"></el-rate> -->
          <div class="rate">
            <!-- <i
              v-for="item in 5"
              class="icon icon-rate"
              :key="item"
              :class="value1 < item ? 'icon-rate-gray' : ''"
            ></i> -->
          </div>
        </div>
        <div class="content flex-align-center">
          <div class="content-p-1">
            <span class="name">{{ user.name }}</span>
            <span>{{ user.company }}</span>
          </div>
          <!-- <div class="content-p-2">
            <div class="label-item">
              <label for="">服务态度：</label>
              <span>好</span>
            </div>
            <div class="label-item">
              <label for="">房源信息：</label>
              <span>精准</span>
            </div>
            <div class="label-item">
              <label for="">专业能力：</label>
              <span>优秀</span>
            </div>
          </div>
          <div class="content-p-3">
            <label for="">服务情况：</label>
            <div class="p">
              具有较强的学习能力和协调沟通能力，并能承受巨大压力，善于应对各种挑战。具有认真负责的态度和吃苦耐劳的精神，愿从实事做起，尽心尽力，尽职尽责。
            </div>
          </div> -->
        </div>
        <!-- <div class="code">
          <img
            src="https://img.js.design/assets/img/650d31378123cfbcbc24c6e4.png#95fc4743e3ffa70bb6f586324bb7f486"
            alt=""
          />
          <p>微信扫码拨号</p>
        </div> -->
      </main>
    </div>
    <!-- 出租、出售列表 -->
    <div class="info-main">
      <!-- 列表tab -->
      <div class="info-main-tab">
        <div
          class="tab-item"
          v-for="item in tabList"
          :key="item.id"
          :class="tab === item.id ? 'active' : ''"
          @click="tab = item.id"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="info-main-box">
        <div class="info-main-left">
          <div class="info-main-list">
            <HouseListItem
              :plantList="plantList"
              :phoneShow="false"
              :sourceType="false"
            />
          </div>
          <div class="info-main-page">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="info-main-right">
          <div class="intermediaryAgent">
            <IntermediaryAgent
              v-if="JSON.stringify(details) !== '{}'"
              :details="details"
              :broker="user"
            />
          </div>
          <!-- <UserEvaluation /> -->
        </div>
      </div>
      <!-- 列表 -->
      <!-- 分页 -->
    </div>
    <!-- footer -->
    <Footer />
    <!-- 侧边悬浮 -->
    <Sidebar />
  </div>
</template>
<script>
const uploadIp = window.uploadIp
const los = window.los
import { getOpenUser } from 'services/broker'
import { leaseBrokerPage, leaseDetail } from 'services/office'
import DetailsHeader from 'components/office/DetailsHeader.vue';
import Sidebar from 'components/home/sidebar.vue';
import IntermediaryAgent from 'components/other/IntermediaryAgent.vue'
import DetailsSearch from 'components/office/DetailsSearch.vue';
import HouseListItem from 'components/other/HouseListItem.vue';
import Footer from 'components/home/footer.vue';
// import UserEvaluation from 'components/office/UserEvaluation.vue';
export default {
  name: 'CounselorHome',
  components: {
    DetailsHeader,
    DetailsSearch,
    IntermediaryAgent,
    // UserEvaluation,
    HouseListItem,
    Sidebar,
    Footer
  },
  data () {
    return {
      uploadIp,
      los,
      user: {},
      value1: 4,
      tabList: [
        // { id: 1, text: '厂房出租' },
        // { id: 2, text: '厂房出售' },
        { id: 3, text: '写字楼出租' },
        // { id: 4, text: '写字楼出售' },
        // { id: 5, text: '仓库出租' },
        // { id: 6, text: '仓库出售' },
        // { id: 7, text: '商铺出租' },
        // { id: 8, text: '土地出租' },
        // { id: 9, text: '土地出售' },
      ],
      tab: 1,
      page: 1,
      total: 0,
      pageSize: 0,
      detailsID: null,
      details: {},
      plantList: [],
    }
  },
  async mounted () {
    // 判断token是否失效
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
            clearTimeout(timer)
          }, 500)
      }
    })
    if (this.$route.params?.id) {
      this.detailsID = this.$route.params.id
      await this.getDetails(this.detailsID)
      console.log('details', this.details);
    }
    this.tab = this.tabList[0].id;
    console.log(this.$route);


    let nav = document.querySelector('.intermediaryAgent')
    let details = document.querySelector('.info-main-tab')

    window.addEventListener('scroll', () => {
      // 客服悬浮
      if (window.pageYOffset > details.offsetTop) {
        nav.style.position = 'fixed'
        nav.style.top = '100px'
        // nav.style.right = '100px'
        nav.style['z-index'] = '100'
      } else {
        nav.style.position = 'static'
      }
    })
  },
  methods: {
    getDetails (id) {
      leaseDetail({ id }).then(res => {
        if (res.data.status === 200) {
          this.details = res.data.data
          this.getUser()
          this.getBrokerHouse()
        }
      })
    },
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
        }
      })
    },
    getBrokerHouse () {
      let params = {
        page: this.page,
        brokerId: this.details.brokerId,
        // cityId: JSON.parse(localStorage.city).cityId
      };
      leaseBrokerPage(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data.map(item => {
            return {
              ...item,
              coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
            }
          })
          this.total = res.data.total
          this.pageSize = res.data.pageSize
        }
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.getBrokerHouse()
    }
  }
};
</script>
<style lang="less" scoped>
.counselor-home {
  .counselor-header-content {
    padding: 0px calc(50% - 600px) 24px;
    main {
      display: flex;
      width: 100%;
      height: 240px;
      border-radius: 16px;
      padding-top: 30px;
      padding-left: 80px;
      background: url(../../assets/office/counselor-home-banner.png);
      background-size: 100% 100%;
      position: relative;
      .tx {
        margin-right: 44px;
        text-align: center;
        .img {
          width: 140px;
          height: 140px;
          opacity: 1;
          border-radius: 200px;
          box-shadow: 0px 6px 16px rgba(236, 102, 0, 0.3);
          margin-bottom: 16px;
        }
        .rate {
          display: flex;
          justify-content: center;
          i {
            margin-right: 10px;
          }
        }

        /deep/ .el-icon-star-on {
          color: #ec6600 !important;
        }
        /deep/ .el-icon-star-off {
          color: #999999 !important;
        }
      }
      .content {
        width: 60%;
        &-p-1 {
          display: flex;
          align-items: end;
          margin-bottom: 18px;
          .name {
            font-size: 36px;
            font-weight: 600;
            line-height: 52px;
            color: rgba(236, 102, 0, 1);
            margin-right: 20px;
          }
          span {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            color: rgba(0, 0, 0, 1);
          }
        }
        &-p-2 {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .label-item {
            margin-right: 30px;
            label {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(153, 153, 153, 1);
            }
            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(51, 51, 51, 1);
            }
          }
        }
        &-p-3 {
          display: flex;
          align-items: start;
          label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(153, 153, 153, 1);
            width: 80px;
          }
          .p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      .code {
        position: absolute;
        top: 130px;
        right: 30px;
        img {
          width: 80px;
          height: 80px;
          border: 1px solid rgba(236, 102, 0, 1);
        }
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(236, 102, 0, 1);
        }
      }
    }
  }
  .info-main {
    padding: 0px calc(50% - 600px) 0px;
    background-color: #fff;
    margin-bottom: 77px;
    &-tab {
      height: 44px;
      background: rgba(246, 245, 244, 1);
      border: 1px solid rgba(246, 245, 244, 1);
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .tab-item {
        padding: 0 12px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          background: rgba(255, 255, 255, 1);
          color: rgba(236, 102, 0, 1);
          border-top: 1px solid rgba(236, 102, 0, 1);
        }
      }
    }
    &-box {
      display: flex;
    }
    &-left {
      width: calc(100% - 432px);
    }
    &-list {
      .list-item {
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        margin-bottom: 20px;
        cursor: pointer;
        .img {
          width: 160px;
          height: 120px;
          border-radius: 4px;
          border: 1px solid rgba(238, 238, 238, 1);
          margin-right: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-content {
          width: calc(100% - 184px);
          height: 35%;
          box-sizing: border-box;
          &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .p {
              display: flex;
              width: calc(100% - 158px);
              p {
                width: calc(100% - 64px);
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: rgba(51, 51, 51, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              span {
                display: inline-block;
                width: 64px;
                text-align: center;
                border-radius: 4px;
                background: rgba(0, 184, 12, 1);
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
              }
            }
            .count {
              width: 120px;
              margin-left: 38px;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: end;
              color: rgba(236, 102, 0, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 8px 0;
            .label {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &-line {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
                &:first-child {
                  margin-bottom: 4px;
                }
                .line {
                  width: 1px;
                  height: 18px;
                  background-color: rgba(221, 221, 221, 1);
                  margin: 0 7px;
                }
              }
            }
            .phone {
              width: 110px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: rgba(232, 242, 252, 1);
              font-size: 14px;
              font-weight: 400;
              color: rgba(16, 118, 235, 1);
              span {
                margin-left: 8px;
              }
            }
          }
          &-label {
            display: flex;
            align-items: center;
            &-item {
              padding: 3px 10px;
              border-radius: 2px;
              background: rgba(252, 244, 230, 1);
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(247, 162, 25, 1);
              margin-right: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: rgba(236, 102, 0, 1);
      }
    }
    &-right {
      width: 340px;
      margin-left: 92px;
      .IntermediaryAgent {
        width: 340px;
        height: 230px;
        padding: 0 20px;
      }
      /deep/ .el-rate__icon {
        font-size: 14px;
        margin-right: 4px;
      }
      /deep/ .el-icon-star-on {
        color: #ec6600 !important;
      }
      /deep/ .el-icon-star-off {
        color: #999999 !important;
      }
    }
  }
}
/deep/ .details-search {
  background: #fff;
  padding-top: 32px;
  padding-bottom: 24px;
  height: auto;
  .logo-search {
    margin-bottom: 0px;
  }
}
</style>
  