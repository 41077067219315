<template>
  <div style="position: relative" id="location">
    <div
      class="location-city"
      id="location_city"
      :class="styleType === 'white' ? '' : 'location-black'"
      @click="openModel"
    >
      <i
        class="icon"
        :class="styleType === 'white' ? 'icon-location' : 'icon-location'"
      ></i>
      <span>{{ cityItem.name }}</span>
    </div>
    <div class="model" id="model" v-if="show">
      <h4>城市搜索</h4>
      <div style="margin-top: 15px">
        <el-input
          placeholder="请输入城市名称"
          v-model="keywords"
          class="input-with-select"
        >
          <i slot="append" class="el-icon-search btn" @click="searchFilter"></i>
        </el-input>
      </div>

      <!-- <div class="city-sel-main" style="margin-top: 10px">
        <div class="city-sel-main-box">
          <p
            class="province"
            style="width: 84px; color: #ec6600; font-weight: 400"
          >
            热门城市
          </p>
          <main style="width: calc(100% - 84px)">
            <div
              class="city-item"
              :class="cityItem.cityId === item.cityId ? 'active' : ''"
              v-for="item in citys"
              :key="item.cityId"
              @click.stop="selCity(item)"
            >
              {{ item.name }}
            </div>
          </main>
        </div>
      </div> -->
      <h4
        style="margin-bottom: 15px; margin-top: 10px"
        v-show="cityList.length"
      >
        城市选择
      </h4>
      <div class="city-sel-main">
        <div class="city-sel-main-box" v-for="item in cityList" :key="item.id">
          <p
            class="province"
            style="width: 36px; color: #ec6600; font-size: 16px"
          >
            {{ item.pinyin }}
          </p>
          <p class="province">{{ item.province }}</p>
          <main>
            <div
              class="city-item"
              :class="cityItem.cityId === i.cityId ? 'active' : ''"
              v-for="i in item.city"
              :key="i.cityId"
              @click.stop="selCity(i)"
            >
              {{ i.name }}
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { openCityAll, searchByName, openCityTree } from "services/common"
import { pinyin } from 'pinyin-pro';
export default {
  name: 'Location',
  props: {
    styleType: {
      type: String,
      default: 'white' // white 白底黑字 black 黑底白字
    },
  },
  data () {
    return {
      citys: [],
      citysCopy: [],
      cityItem: {},
      show: false,
      keywords: '',
      cityList: [
      ],
      cityListCopy: [],
    }
  },
  computed: {
    city: {
      get () {
        return this.$store.state.city
      },
      set (val) {
        this.$store.commit('setCity', val)
      }
    }
  },
  watch: {
    city: {
      handler (val) {
        this.getLocation(val)
      }
    }
  },
  mounted () {
    // let vm = this
    this.getCity()
    this.getOpenCityTree()
  },
  methods: {
    getLocation (val) {
      searchByName({
        name: val || '深圳',
        type: 1,
      }).then(res => {
        if (res.data.status === 200) {
          console.log('------', res.data.data);
          this.cityItem = res.data.data
          localStorage.city = JSON.stringify(res.data.data)
          // 判断是否开通城市
          const arr = this.citys.filter(item => {
            return item.cityId === this.cityItem.cityId
          })
          if (arr.length === 0 || val === '') {
            this.cityItem === this.citys[0]
            localStorage.city = JSON.stringify(this.citys[0])
            return this.$message.warning('当前城市未开通房源')
          }
          // this.$store.commit('setLocationCity', this.cityItem)
          this.$bus.$emit('changeCity', this.cityItem)
        }
      })
    },
    openModel () {
      this.show = !this.show
    },
    getCity () {
      let params = {
        types: 5
      }
      openCityAll(params).then(res => {
        if (res.data.status === 200) {
          this.citys = res.data.data
          this.citysCopy = res.data.data
          if (localStorage.city) {
            this.cityItem = JSON.parse(localStorage.city)
            // 判断是否开通城市
            const arr = this.citys.filter(item => {
              return item.cityId === this.cityItem.cityId
            })
            if (arr.length === 0) {
              this.cityItem === this.citys[0]
              localStorage.city = JSON.stringify(this.citys[0])
              return this.$message.warning('当前城市未开通房源')
            } else {
              this.cityItem = arr[0]
            }
            // this.$store.commit('setLocationCity', this.cityItem)
            this.$bus.$emit('changeCity', this.cityItem)
          } else {
            this.getLocation()
          }
        }
      })
    },
    getOpenCityTree () {
      openCityTree({ types: 5 }).then(res => {
        if (res.data.status === 200) {
          const object = res.data.data
          let arr = []
          for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
              arr.push({
                province: key,
                city: object[key],
                pinyin: pinyin(key, { pattern: "first", type: 'array' })[0].toUpperCase()
              })
            }
          }
          this.cityList = arr.map((item, index) => {
            return {
              ...item,
              id: index
            }
          })
          this.cityList = this.cityList.sort((a, b) => a.province.localeCompare(b.province, 'zh')); //a~z 排序
          let str = 'A';
          for (let index = 0; index < this.cityList.length; index++) {
            if (str === this.cityList[index].pinyin) {
              this.cityList[index].pinyin = null
            } else {
              str = this.cityList[index].pinyin
            }
          }
          console.log(this.cityList);
          this.cityListCopy = this.cityList
        }
      })
    },
    selCity (item) {
      this.show = false
      this.cityItem = item
      localStorage.city = JSON.stringify(item)
      this.$bus.$emit('changeCity', this.cityItem)
    },
    searchFilter () {
      this.citys = this.citysCopy.filter(item => {
        return item.name.indexOf(this.keywords) !== -1
      })
      console.log(this.citys);
      let cityList = [].concat(this.cityListCopy)
      console.log(this.cityListCopy);
      this.cityList = cityList.map(item => {
        let city = item.city.filter(i => {
          return i.name.indexOf(this.keywords) !== -1
        })
        return {
          ...item,
          city
        }
      }).filter(item => item.city.length > 0)
    }
  }
};
</script>

<style lang="less" scoped>
.location-city {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  padding: 0 9px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.24);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  position: relative;
  i {
    margin-right: 5px;
  }
}

.model {
  position: absolute;
  left: 0;
  top: 32px;
  z-index: 99;
  width: 540px;
  padding: 12px 20px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: #ff8629;
    margin-bottom: 5px;
  }
  .input-with-select {
    width: 100%;
    margin-bottom: 10px;
    /deep/ .el-input__inner {
      height: 36px;
      border: 1px solid #ddd;
      border-radius: 4px 0 0 4px;
    }
    /deep/ .el-input-group__append {
      height: 36px;
      border: 1px solid #ff8629;
      border-radius: 0 4px 4px 0;
      background: #ff8629;
      padding: 0;
      .btn {
        // height: 36px;
        border-radius: 0;
        border: 0;
        color: #fff;
        padding: 0 20px;
      }
    }
  }
  main {
    display: flex;
    flex-wrap: wrap;
    .city-item {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(51, 51, 51, 1);
      margin-right: 16px;
      margin-bottom: 10px;
      &:hover {
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .city-sel-main {
    max-height: 200px;
    overflow: auto;
    &-box {
      display: flex;
    }

    .province {
      width: 65px;
      padding-right: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      line-height: 20px;
    }
  }
}
.location-black {
  border: 1px solid rgba(204, 204, 204, 1);
  color: rgba(102, 102, 102, 1);
  background-color: #fff;
  border-radius: 8px;
}
</style>
