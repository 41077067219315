<template>
  <div class="details-info">
    <div class="info">
      <div class="info-left">
        <!-- 基本信息 -->
        <div class="basic-info" id="tab1">
          <InfoTitle />
          <div class="info-detail">
            <div class="info-detail-item">
              <label for="">是否可分割</label>
              <div class="p">
                {{ details.partition ? '是' : '否' }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">房源编号</label>
              <div class="p">{{ details.propertyNo || '-' }}</div>
            </div>
            <div class="info-detail-item">
              <label for="">租房押金</label>
              <div class="p">{{ details.cashPledge || '-' }}</div>
            </div>
            <div class="info-detail-item">
              <label for="">起租时间</label>
              <div class="p">
                <span v-if="details.minLeaseMonth">{{
                  details.minLeaseMonth | minLeaseMonthFilter
                }}</span>
                <span v-else>面议</span>
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">可租时间</label>
              <div class="p">
                {{ details.canLeaseTime | canLeaseTimeFilter }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">层高</label>
              <div class="p">
                {{ details.floorHeight ? `${details.floorHeight}米` : '-' }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">看房时间</label>
              <div class="p">
                {{ details.lookTimeName }}
              </div>
            </div>
          </div>
        </div>
        <!-- 价格详情 -->
        <div class="build-detail" id="tab2">
          <InfoTitle title="价格详情" />
          <div class="info-detail">
            <div class="info-detail-item">
              <label for="">物业费</label>
              <div class="p">
                {{
                  details.propertyCost
                    ? `${details.propertyCost}元/m²/月`
                    : '面议'
                }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">水费</label>
              <div class="p" v-if="details.waterFee == '0'">已包含</div>
              <div class="p" v-else>
                {{ details.waterFee ? `${details.waterFee}元/m³` : '面议' }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">空调费</label>
              <div class="p" v-if="details.airConditioningFee == '0'">
                已包含
              </div>
              <div class="p" v-else>
                {{
                  details.airConditioningFee
                    ? `${details.airConditioningFee}元/月`
                    : '面议'
                }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">电费</label>
              <div class="p" v-if="details.electricFee == '0'">已包含</div>
              <div class="p" v-else>
                {{
                  details.electricFee ? `${details.electricFee}元/kwh` : '面议'
                }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">车位费</label>
              <div class="p">
                {{ details.parkingFee ? `${details.parkingFee}元/月` : '面议' }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">宽带费</label>
              <div class="p" v-if="details.netFee == '0'">已包含</div>
              <div class="p" v-else>
                {{ details.netFee ? `${details.netFee}元/月` : '面议' }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">税率</label>
              <div class="p">
                {{
                  details.taxRate
                    ? (details.taxRate * 100).toFixed(2) + '%'
                    : '含税'
                }}
              </div>
            </div>
            <div class="info-detail-item">
              <label for="">其他</label>
              <div class="p">
                {{ details.otherFee ? details.otherFee : '-' }}
              </div>
            </div>
          </div>
        </div>
        <!-- 详细介绍 -->
        <div class="detail-introduce" id="tab3">
          <InfoTitle title="详细介绍" />
          <ul>
            <li>
              <div
                v-if="details.details"
                class="content"
                v-html="
                  details.details
                    .replace(/\n|\r\n/g, '<br>')
                    .replace(/ /g, '  ')
                "
              ></div>
              <div v-show="!details.details">暂无数据</div>
            </li>
          </ul>
        </div>
        <!-- 配套设施 -->
        <div class="ancillary-facility" id="tab4">
          <InfoTitle title="配套设施" />
          <ul v-if="details.facilitiesList">
            <li
              class="facility-item"
              v-for="item in details.facilitiesList"
              :key="item.id"
            >
              <img
                :src="los + item.icon + '?' + Date.now()"
                alt=""
                style="width: 22px; height: 22px"
                class="icon"
                :class="item.enable ? `` : `active`"
              />
              <div class="text" :class="item.enable ? 'state' : ''">
                {{ item.name }}
              </div>
            </li>
          </ul>
          <ul v-else>
            暂无数据
          </ul>
        </div>
        <!-- 价格详情 -->
        <!-- <div class="price-detail" id="tab5">
          <InfoTitle title="价格详情" />
          <div class="info-detail">
            <div class="info-detail-item">
              <label for="">水费</label>
              <div class="p" v-if="details.waterRate">
                {{ details.waterRate }}元/吨
              </div>
              <div class="p" v-else>面议</div>
            </div>
            <div class="info-detail-item">
              <label for="">电费</label>
              <div class="p" v-if="details.electricRate">
                {{ details.electricRate }}元/度
              </div>
              <div class="p" v-else>面议</div>
            </div>
            <div class="info-detail-item">
              <label for="">空调费</label>
              <div class="p" v-if="details.airConditioningFee">
                {{ details.airConditioningFee }}元/月
              </div>
              <div class="p" v-else>面议</div>
            </div>
            <div class="info-detail-item">
              <label for="">物业费</label>
              <div class="p" v-if="details.propertyCost">
                {{ details.propertyCost }}元/月
              </div>
              <div class="p" v-else>面议</div>
            </div>
            <div class="info-detail-item">
              <label for="">停车费</label>
              <div class="p" v-if="details.parkingFee">
                {{ details.parkingFee }}元/月
              </div>
              <div class="p" v-else>面议</div>
            </div>
            <div class="info-detail-item">
              <label for="">其它费用</label>
              <div class="p">{{ details.otherFee || '面议' }}</div>
            </div>
          </div>
        </div> -->
        <!-- 房源特色 -->
        <div class="house-feature" id="tab5">
          <InfoTitle title="房源特色" />
          <div class="feature">
            <div
              class="feature-item"
              v-for="(item, index) in details.labels"
              :key="index"
            >
              {{ item }}
            </div>
            <span v-show="!details.labels">暂无数据</span>
          </div>
        </div>
        <!-- 图片 -->
        <div class="house-img" id="tab6">
          <InfoTitle title="图片" />
          <div class="house-img-main" v-if="showHouseImg.length > 0">
            <div
              class="house-img-item"
              v-for="(item, index) in showHouseImg"
              :key="index"
            >
              <el-image
                class="img"
                :src="los + item.url"
                lazy
                @click="selImg(item)"
              ></el-image>
              <!-- <img :src="los + item" alt="" /> -->
              <div
                class="shelter"
                v-if="
                  details.images.length > 9 &&
                  index + 1 === 9 &&
                  showHouseImg.length !== details.images.length
                "
                @click="showImg"
              >
                <p>查看全部图片({{ details.images.length }}张)</p>
              </div>
            </div>
          </div>
          <div class="house-img-main" v-else>暂无图片</div>
        </div>
      </div>

      <div class="info-right" id="model">
        <div class="intermediaryAgent">
          <IntermediaryAgent
            :details="details"
            :broker="broker"
            :titleShow="true"
            :priceShow="true"
          />
        </div>
        <img v-show="modelExist" :src="img" alt="" @click="toModel" />
      </div>
      <!-- <div class="infohouse-right">
        <p class="title">必看好房</p>
        <div class="look-house">
          <div class="look-house-item" v-for="item in 12" :key="item">
            <div class="img">
              <img
                src="https://img.js.design/assets/img/650c1197cf2c0cc9a51cfb8f.png#beb10f92e6dad053eb4e3411e36a9292"
                alt=""
              />
            </div>
            <div class="house-info">
              <div class="house-name">整租·富基置地大厚...</div>
              <div class="house-label">
                龙岗双龙/东南/39.28m距3号线(龙岗线)-双龙站621312313
              </div>
              <p class="house-count">2388元/月</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 位置 -->
    <div class="house-place" id="tab7">
      <header>
        <InfoTitle title="位置" :accurateLocation="!details.accurateLocation" />
        <div class="look-open-3d" @click="toModel" v-show="modelExist">
          <i class="icon icon-3d-look"></i>
          <span>三维看房</span>
        </div>
      </header>
      <div class="map">
        <b-map
          :adress="adress"
          :points="points"
          ref="map"
          @getMapSearchList="getMapSearchList"
        />
        <div class="map-search">
          <div class="search-tab-1">
            <div
              class="search-tab-1-item"
              :class="mapSearchId === item.id ? 'active' : ''"
              v-for="item in mapSearch"
              :key="item.id"
              @click="getMap(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="search-tab-2">
            <div
              class="search-tab-2-item"
              :class="mapSearchTabId === item.id ? 'active' : ''"
              v-for="item in mapSearchTab"
              :key="item.id"
              @click="getMapTab(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="map-search-content"
            v-loading="loading"
            :class="searchMapList.length > 0 ? '' : 'empty'"
            id="r-result"
          >
            <div id="r-result">
              <div
                class="map-search-content-item"
                :class="searchMapID === item.uid ? 'active' : ''"
                v-for="item in searchMapList"
                :key="item.uid"
                :id="`id${item.uid}`"
                @click="addLabel(item)"
              >
                <div class="left">
                  <div
                    class="title"
                    :class="searchMapID === item.uid ? 'active' : ''"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="adress"
                    :class="searchMapID === item.uid ? 'active' : ''"
                    :title="item.address"
                  >
                    {{ item.address }}
                  </div>
                </div>
                <div
                  class="right"
                  :class="searchMapID === item.uid ? 'active' : ''"
                >
                  距离{{ item.distance }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐房源 -->
    <div class="recommend-house">
      <div class="recommend-house-title">为你推荐房源</div>
      <div class="recommend-house-main">
        <div
          class="recommend-house-main-item"
          v-for="item in plantList"
          :key="item.id"
          @click="toGardenDetails(item)"
        >
          <div class="img">
            <SpecialOffer
              v-show="item.preferential"
              :label="item.preferential"
            />
            <img v-if="item.coverImg" :src="los + item.coverImg" alt="" />
            <el-image
              v-else
              :src="require('../../assets/no-img-home.png')"
              lazy
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 100%"
                  :src="'../../assets/no-img-home.png' + '?' + Date.now()"
                  alt=""
                />
              </div>
            </el-image>
            <!-- <div class="label vr" v-if="item.type === 'vr'">
              <i class="icon icon-VR"></i>
              <span>VR</span>
            </div>
            <div class="label" v-else>
              <i class="icon icon-video"></i>
            </div> -->
          </div>
          <div class="item-content">
            <p class="item-content-title" :title="item.title">
              {{ item.title }}
            </p>
            <div class="item-content-main">
              <div class="label">
                <div class="label-line">
                  <span style="color: rgba(51, 51, 51, 1)"
                    >{{ item.totalArea }}㎡</span
                  >
                  <div class="line"></div>
                  <span>{{ item.decorationName }}</span>
                  <div class="line"></div>
                  <span>{{ item.orientationName }}</span>
                </div>
                <div class="label-line">
                  <!-- <i class="icon icon-plant-details-location"></i> -->
                  {{ item.district }}-{{ item.street }} {{ item.buildingName }}
                </div>
              </div>
            </div>

            <div class="count" v-if="!item.price && !item.totalPrice">
              暂无数据
            </div>

            <div class="count" v-else>
              <span class="num f-s-16" :title="`${item.totalPrice}元/月`">
                {{
                  item.totalPrice > 1000
                    ? (item.totalPrice / 10000).toFixed(2)
                    : item.totalPrice
                }}<span class="price-unit"
                  >{{ item.totalPrice > 1000 ? '万' : '' }}元/月</span
                >
              </span>
              <span
                class="num f-s-12"
                style="color: rgba(153, 153, 153, 1)"
                :title="`${item.price}元/㎡/月`"
              >
                {{ item.price
                }}<span
                  class="price-unit f-s-12"
                  style="color: rgba(153, 153, 153, 1)"
                  >元/㎡/月</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogImg ref="dialogImg" :details="details" />
  </div>
</template>
  <script>
import img from '../../assets/office/details/3d-look-house.png'
import { leaseRecommend } from 'services/office'
import InfoTitle from './InfoTitle.vue';
import DialogImg from './DialogImg.vue';
import IntermediaryAgent from 'components/other/IntermediaryAgent.vue'
import SpecialOffer from 'components/other/SpecialOffer.vue';
import BMap from 'components/map/BMap.vue';
const los = window.los
export default {
  name: 'DetailsInfo',
  components: {
    InfoTitle,
    IntermediaryAgent,
    SpecialOffer,
    BMap,
    DialogImg
  },
  props: {
    details: Object,
    broker: Object
  },
  data () {
    return {
      los,
      img,
      adress: {},
      points: [],
      infoActive: '基本信息',
      value1: null,
      input2: null,
      facilityList: [
        { id: 1, icon: 'office', name: '办公室', state: true },
        { id: 2, icon: 'dorm', name: '宿舍', state: false },
        { id: 3, icon: 'room', name: '餐厅', state: true },
        { id: 4, icon: 'platform', name: '月台', state: true },
        { id: 5, icon: 'canopy', name: '雨棚', state: false },
        { id: 6, icon: 'park', name: '停车场', state: true },
        { id: 7, icon: 'passengerlift', name: '客梯', state: true },
        { id: 8, icon: 'freightelevator', name: '货梯', state: false },
        { id: 9, icon: 'wifi', name: '宽带', state: true },
        { id: 10, icon: 'water', name: '水', state: true },
        { id: 11, icon: 'electricity', name: '电', state: true },
        { id: 12, icon: 'air', name: '中央空调', state: true },
      ],
      featureList: ['近高速', '有宿舍', '有仓库', '花园厂房'],
      showHouseImg: [],
      mapSearch: [
        {
          id: 1,
          name: '交通',
          tab: [
            {
              id: 1,
              name: '公交',
              keywords: ['公交站'],
              dataType: null
            },
            {
              id: 2,
              name: '地铁',
              keywords: ['地铁站'],
              dataType: 230105
            },
            {
              id: 3,
              name: '高速',
              keywords: ['高速路口'],
              dataType: null
            },
            {
              id: 4,
              name: '飞机',
              keywords: ['飞机场'],
              dataType: 230109
            },
            {
              id: 5,
              name: '火车',
              keywords: ['火车站'],
              dataType: 230101
            },
          ]
        },
        {
          id: 2,
          name: '服务',
          tab: [
            {
              id: 1,
              name: '物流',
              keywords: ['物流', '快递站', '菜鸟驿站'],
              dataType: 230111
            },
            {
              id: 2,
              name: '餐饮',
              keywords: ['餐饮'],
              dataType: 110100
            },
            {
              id: 3,
              name: '娱乐',
              keywords: ['娱乐'],
              dataType: 180200
            },
            {
              id: 4,
              name: '酒店',
              keywords: ['酒店', '宾馆', '民宿', '旅馆', '住宿'],
              dataType: null
            },
            {
              id: 5,
              name: '银行',
              keywords: ['银行', '信用社'],
              dataType: null
            },
            {
              id: 6,
              name: '政务服务',
              keywords: ['税务局', '街道办', '公共服务'],
              dataType: null
            },
          ]
        },
      ],
      mapSearchId: 1,
      mapSearchTab: [
        {
          id: 1,
          name: '公交',
          keywords: ['公交站'],
          dataType: null
        },
        {
          id: 2,
          name: '地铁',
          keywords: ['地铁站'],
          dataType: 230105
        },
        {
          id: 3,
          name: '高速',
          keywords: ['高速路口'],
          dataType: null
        },
        {
          id: 4,
          name: '飞机',
          keywords: ['飞机场'],
          dataType: 230109
        },
        {
          id: 5,
          name: '火车',
          keywords: ['火车站'],
          dataType: 230101
        },
      ],
      mapSearchTabId: 1,
      plantList: [],
      loading: false,
      searchMapList: [],
      searchMapID: null,
      imgVisible: false,
      modelExist: true
    }
  },
  mounted () {
    const city = localStorage.city
    this.showHouseImg = this.details.images ? this.details.images.map((item, index) => {
      return {
        url: (item.url || item) + '?' + Date.now(),
        id: index + 1,
        type: item.type || 4
      }
    }).slice(0, 9) : []
    this.getLetRecommend(JSON.parse(city).cityId)
    this.timer = setTimeout(() => {
      this.adress = {
        name: JSON.parse(city).name + this.details.district,
        lng: this.details.lon,
        lat: this.details.lat,
      }
      this.points = [this.details.lon, this.details.lat]
      this.$nextTick(() => {
        this.$refs.map.addMark(...this.points, this.details.title)
        // this.$refs.map.getMark(...this.points, this.details.title)
        this.getMapTab({
          id: 1,
          name: '公交',
          keywords: ['公交站']
        })
        clearTimeout(this.timer)
        // 

        if (this.$store.state.modelData.data[this.details.id]) {
          let { url1 } = this.$store.state.modelData.data[this.details.id]
          if (!url1) this.modelExist = false
        } else {
          this.modelExist = false
        }
      })
    }, 500)


    // tab联动
    let nav = document.querySelector('.info-right')
    let details = document.querySelector('.details-info')
    // 基本信息
    let tab1 = document.querySelector('.basic-info')
    // 价格详情
    let tab2 = document.querySelector('.build-detail')
    // 详细介绍
    let tab3 = document.querySelector('.detail-introduce')
    // 配套设施
    let tab4 = document.querySelector('.ancillary-facility')
    // 房源特色
    let tab5 = document.querySelector('.house-feature')
    // 图片
    let tab6 = document.querySelector('.house-img')
    // 位置
    let tab7 = document.querySelector('.house-place')

    window.addEventListener('scroll', () => {
      const maxTop = tab6.offsetTop + tab6.clientHeight - nav.clientHeight
      // 客服悬浮
      if (window.pageYOffset > details.offsetTop && window.pageYOffset < maxTop) {
        nav.style.position = 'fixed'
        nav.style.top = '100px'
        nav.style.right = 'calc(50% - 600px)'
        nav.style['z-index'] = '1000'
      } else {
        nav.style.position = 'static'
      }
      // 锚点联动
      if (window.pageYOffset > tab1.offsetTop - 120) {
        this.$emit('scroll', '基本信息')
      }
      if (window.pageYOffset > tab2.offsetTop - 120) {
        this.$emit('scroll', '价格详情')
      }
      if (window.pageYOffset > tab3.offsetTop - 120) {
        this.$emit('scroll', '详细介绍')
      }
      if (window.pageYOffset > tab4.offsetTop - 120) {
        this.$emit('scroll', '配套设施')
      }
      if (window.pageYOffset > tab5.offsetTop - 120) {
        this.$emit('scroll', '房源特色')
      }
      if (window.pageYOffset > tab6.offsetTop - 120) {
        this.$emit('scroll', '图片')
      }
      if (window.pageYOffset > tab7.offsetTop - 120) {
        this.$emit('scroll', '位置')
      }
    })
  },
  methods: {
    toModel () {
      // 1316174096974897152
      window.open(window.model + 'model/1/' + this.details.id, '_blank');
    },
    getStep (id) {
      const element = document.getElementById(id)
      window.scrollTo({
        top: element.offsetTop - 80,
        behavior: 'smooth'
      })
    },
    getMapSearchList (s) {
      this.loading = false
      this.searchMapList = s.map((item, index) => {
        return {
          ...item,
          uid: index
        }
      })
    },
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    showImg () {
      this.showHouseImg = this.details.images ? this.details.images.map((item, index) => {
        return {
          url: (item.url || item) + '?' + Date.now(),
          id: index + 1,
          type: item.type || 4
        }
      }) : []
    },
    selImg (item) {
      this.$refs.dialogImg.index = item.id - 1
      this.$refs.dialogImg.imgVisible = true
      this.$nextTick(() => {
        this.$refs.dialogImg.selImg(item)
      })
    },
    getMap (item) {
      this.searchMapID = null
      this.mapSearchId = item.id
      this.mapSearchTabId = item.tab[0].id
      this.$nextTick(() => {
        this.getMapTab(item.tab[0])
      })
      this.mapSearchTab = item.tab
    },
    getMapTab (item) {
      console.log('item', item);
      this.searchMapID = null
      this.mapSearchTabId = item.id
      this.loading = true
      this.$refs.map.getSearchs(item)
    },
    addLabel (item) {
      this.searchMapID = item.uid
      var element = document.getElementById(`id${item.uid}`);
      const main = document.getElementById('r-result')
      console.log(element.offsetTop, main.scrollTop);
      main.scrollTo({
        top: element.offsetTop - 73,
        behavior: 'smooth'
      })
      this.$refs.map.addLabel(item)
    },
    // 获取推荐房源
    getLetRecommend (cityId) {
      leaseRecommend({ cityId, id: this.details.id }).then(res => {
        if (res.data.status === 200) {
          this.plantList = !res.data.data ? [] : res.data.data.map(item => {
            return {
              ...item,
              coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
            }
          })
        }
      })
    },
    toGardenDetails (item) {
      let routeUrl = this.$router.resolve({
        path: '/plantDetails',
        data: {
          item: JSON.stringify(item)
        }
      })
      window.open(routeUrl.href + "/" + item.id, '_blank');
    },
  }
};
  </script>
  
  <style lang="less" scoped>
.details-info {
  width: 100%;
  background: #fff;
  // position: relative;
  .info {
    width: 100%;
    display: flex;
    // align-items: center;
    margin-bottom: 36px;
    &-left {
      width: calc(100% - 432px);
      // height: 420px;
      .basic-info {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        margin-bottom: 30px;
      }
      .build-detail {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        margin-bottom: 36px;
      }

      .detail-introduce {
        padding-bottom: 36px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        li {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: rgba(51, 51, 51, 1);
          display: flex;
        }
      }
      .ancillary-facility {
        padding-bottom: 30px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        p.title {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          &:last-child {
            margin: 16px 0 12px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
        }
        .facility-item {
          width: calc(100% / 6);
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          img.active {
            filter: grayscale(90%);
          }
          .text {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(51, 51, 51, 1);
            .state {
              color: rgba(187, 187, 187, 1);
            }
          }
        }
        .dorm {
          height: 114px;
          opacity: 1;
          border-radius: 4px;
          background: #f6f5f4;
          padding: 12px 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          .img {
            width: 120px;
            border-radius: 4px;
            border: 1px solid #eeeeee;
            margin-right: 20px;
          }
          &-info {
            &-title {
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: rgba(51, 51, 51, 1);
              .gr-label {
                border-radius: 4px;
                background: #00b80c;
                padding: 2px 8px;
                margin-left: 8px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: rgba(255, 255, 255, 1);
              }
            }
            .count {
              font-size: 16px;
              font-weight: 400;
              font-family: DIN;
              line-height: 24px;
              color: rgba(236, 102, 0, 1);
            }
            .location {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(153, 153, 153, 1);
              // margin-left: 4px;
            }
          }
        }
      }
      .price-detail {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
      }
      .house-feature {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        .feature {
          display: flex;
          flex-wrap: wrap;
          &-item {
            padding: 5px 14px;
            border-radius: 4px;
            background: #fcf4e6;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(247, 162, 25, 1);
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
      .house-img {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        &-main {
          display: flex;
          flex-wrap: wrap;
        }
        &-item {
          width: calc((100% - 16px) / 3);
          height: 180px;
          margin-bottom: 8px;
          position: relative;
          &:nth-child(3n-1) {
            margin: 0 8px;
          }
          .img {
            width: 100%;
            // width: calc((100% - 16px) / 3);
            height: 100%;
            border-radius: 4px;
          }
          .shelter {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            cursor: pointer;
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
    &-right {
      width: 340px;
      height: 519px;
      margin-left: 92px;
      .intermediaryAgent {
        width: 340px;
        height: 350px;
      }
      img {
        margin-top: 32px;
        width: 340px;
        height: 120px;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
      }
    }
    &-detail {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &-item {
        width: 50%;
        line-height: 30px;
        display: flex;
        label {
          width: 100px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 33px;
          color: rgba(153, 153, 153, 1);
          margin-right: 24px;
        }
        .p {
          width: calc(100% - 100px);
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 30px;
          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .infohouse {
    width: 100%;
    display: flex;
    margin-bottom: 36px;
    &-left {
      width: calc(100% - 432px);
      .detail-introduce {
        padding-bottom: 36px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        li {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: rgba(51, 51, 51, 1);
          display: flex;
        }
      }
      .ancillary-facility {
        padding-bottom: 30px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        p.title {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          &:last-child {
            margin: 16px 0 12px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
        }
        .facility-item {
          width: calc(100% / 6);
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          img.active {
            filter: grayscale(90%);
          }
          .text {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(51, 51, 51, 1);
            .state {
              color: rgba(187, 187, 187, 1);
            }
          }
        }
        .dorm {
          height: 114px;
          opacity: 1;
          border-radius: 4px;
          background: #f6f5f4;
          padding: 12px 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          .img {
            width: 120px;
            border-radius: 4px;
            border: 1px solid #eeeeee;
            margin-right: 20px;
          }
          &-info {
            &-title {
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: rgba(51, 51, 51, 1);
              .gr-label {
                border-radius: 4px;
                background: #00b80c;
                padding: 2px 8px;
                margin-left: 8px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: rgba(255, 255, 255, 1);
              }
            }
            .count {
              font-size: 16px;
              font-weight: 400;
              font-family: DIN;
              line-height: 24px;
              color: rgba(236, 102, 0, 1);
            }
            .location {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(153, 153, 153, 1);
              // margin-left: 4px;
            }
          }
        }
      }
      .price-detail {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
      }
      .house-feature {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 36px;
        .feature {
          display: flex;
          flex-wrap: wrap;
          &-item {
            padding: 5px 14px;
            border-radius: 4px;
            background: #fcf4e6;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(247, 162, 25, 1);
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
      .house-img {
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        &-main {
          display: flex;
          flex-wrap: wrap;
        }
        &-item {
          width: calc((100% - 16px) / 3);
          height: 180px;
          margin-bottom: 8px;
          position: relative;
          &:nth-child(3n-1) {
            margin: 0 8px;
          }
          .img {
            width: 100%;
            // width: calc((100% - 16px) / 3);
            height: 100%;
            border-radius: 4px;
          }
          .shelter {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            cursor: pointer;
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
    &-right {
      width: 340px;
      height: 348px;
      box-sizing: border-box;
      margin-left: 92px;
      p.title {
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        margin-bottom: 20px;
        color: rgba(51, 51, 51, 1);
      }
      .look-house {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: calc(50% - 6px);
          height: 236px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(238, 238, 238, 1);
          cursor: pointer;
          &:nth-child(2n) {
            margin-left: 12px;
          }
          .img {
            width: 100%;
            height: 50%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .house-info {
            padding: 8px 12px;
            .house-name {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(51, 51, 51, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .house-label {
              height: 36px;
              margin-top: 4px;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(153, 153, 153, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .house-count {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(236, 102, 0, 1);
              margin-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .house-place {
    width: 100%;
    margin-bottom: 40px;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .look-open-3d {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          width: 16px;
          height: 16px;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(236, 102, 0, 1);
          margin-left: 4px;
        }
      }
    }
    .map {
      width: 100%;
      height: 320px;
      position: relative;
      .map-search {
        width: 300px;
        height: 280px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 598;
        background-color: #fff;
        border: 1px solid rgba(238, 238, 238, 1);
        .search-tab-1 {
          display: flex;
          align-items: center;
          &-item {
            width: 50%;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: rgba(245, 245, 245, 1);
            font-size: 16px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            cursor: pointer;
            &.active {
              background: rgba(255, 255, 255, 1);
              font-size: 16px;
              font-weight: 500;
              color: rgba(236, 102, 0, 1);
            }
          }
        }
        .search-tab-2 {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-bottom: 1px solid rgba(245, 245, 245, 1);
          &-item {
            height: 36px;
            text-align: center;
            line-height: 36px;
            font-size: 13px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            cursor: pointer;
            &.active {
              font-size: 13px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              border-bottom: 2px solid rgba(51, 51, 51, 1);
            }
          }
        }
        &-content {
          height: 200px;
          overflow-y: scroll;
          &#r-result {
            & > div {
              border: 0 !important;
            }
          }
          &-item {
            display: flex;
            align-items: center;
            height: 62px;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
            padding: 0 20px;
            cursor: pointer;
            &.active {
              background-color: rgba(255, 249, 242, 1);
            }
            .left {
              width: 60%;
              .title {
                font-size: 13px;
                font-weight: 400;
                line-height: 18.82px;
                color: rgba(51, 51, 51, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.active {
                  color: #ec6600;
                }
              }
              .adress {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 17.38px;
                color: rgba(153, 153, 153, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.active {
                  color: #ec6600;
                }
              }
            }
            .right {
              text-align: right;
              width: 40%;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 17.38px;
              color: rgba(16, 118, 235, 1);
            }
          }
        }
      }
    }
  }
  .recommend-house {
    width: 100%;
    margin-bottom: 36px;
    &-title {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 36px;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 20px;
    }
    &-main {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: calc(25% - 15px);
        height: 331px;
        border-radius: 4px;
        border: 1px solid rgba(238, 238, 238, 1);
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .img {
          width: 100%;
          height: calc(100% - 125px);
          position: relative;
          border-radius: 4px 4px 0px 0px;
          overflow: hidden;
          box-sizing: border-box;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px 4px 0px 0px;
          }
          .label {
            position: absolute;
            top: 12px;
            left: 12px;
            width: 32px;
            height: 32px;
            opacity: 1;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .vr {
            width: 64px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(224, 224, 224, 1);
          }
        }
        .item-content {
          width: 100%;
          height: 125px;
          padding: 12px 16px 8px;
          box-sizing: border-box;
          &-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(51, 51, 51, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 4px;
            margin-bottom: 4px;
            .label {
              &-line {
                display: flex;
                align-items: center;
                // justify-content: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
                margin-top: 4px;
                &:first-child {
                  margin-top: 0;
                }
                .line {
                  width: 1px;
                  height: 12px;
                  background-color: rgba(221, 221, 221, 1);
                  margin: 0 10px;
                }
              }
            }
          }

          .count {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            font-family: DIN;
            color: rgba(236, 102, 0, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .price-unit {
              font-size: 15px;
            }
          }
          &-label {
            display: flex;
            align-items: center;
            &-item {
              padding: 3px 10px;
              border-radius: 2px;
              background: rgba(252, 244, 230, 1);
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(247, 162, 25, 1);
              margin-right: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .big-img-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    .el-icon-close {
      position: absolute;
      top: 100px;
      right: 100px;
      cursor: pointer;
      font-size: 50px;
      color: #fff;
    }
    .main {
      width: 1000px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .img-big {
        height: 750px;
      }
      .carousel {
        main {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

/deep/ .el-rate__icon {
  font-size: 14px;
  margin-right: 4px;
}
/deep/ .el-icon-star-on {
  color: #ec6600 !important;
}
/deep/ .el-icon-star-off {
  color: #999999 !important;
}
</style>
  