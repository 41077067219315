import axios from '../plugins/broker';

import qs from 'qs';

// form 表单传参
// eslint-disable-next-line no-unused-vars
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};
// 获取认证状态
const certificationStatus = () => axios.get('/certification/status');
// 获取认证状态
const brokerOfficeDetails = params =>
  axios.get('/office/lease/detail', { params });

// 查询经纪人/业主信息
const getOpenUser = async params => axios.get('/open/user/get', { params });

export { certificationStatus, getOpenUser, brokerOfficeDetails };
