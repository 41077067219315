<template>
  <div class="my">
    <!--详情头部-->
    <DetailsHeader />
    <main></main>
    <Footer />
  </div>
</template>
  
  <script>
import DetailsHeader from 'components/office/DetailsHeader.vue';
import Footer from 'components/home/footer.vue';
export default {
  name: 'My',
  components: {
    DetailsHeader,
    Footer
  },
  data () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }
};
  </script>
  <style lang="less" scoped>
.my {
  main {
    height: 1203px;
    background: url(../assets/my.png);
    position: relative;
    margin-bottom: 40px;
  }
}
</style>
  