<template>
  <div class="office-details">
    <!-- <div id="details"> -->
    <!--详情头部-->
    <DetailsHeader />
    <!--详情搜索-->
    <DetailsSearch v-if="detailsVisible" :details="details" />
    <!--详情主内容头部-->
    <div class="header-content">
      <!-- <p class="title3 m-b-10">
        亿楼厂房 > 深圳厂房出租 > 宝安厂房出租 > 福永厂房出租
      </p> -->
      <main>
        <div class="header-content-left" v-if="detailsVisible">
          <DetailsCarousel :details="details" />
        </div>
        <div class="header-content-right" v-if="detailsVisible">
          <DetailsHeaderInfo :details="details" :broker="broker" />
        </div>
      </main>
    </div>
    <!-- 基本信息 -->
    <div class="info-main" v-if="detailsVisible">
      <DetailsInfoTab ref="info-tab" @tabItem="tabItem" />
      <DetailsInfo
        ref="DetailsInfo"
        @scroll="scrollTab"
        :details="details"
        :broker="broker"
      />
    </div>
    <!-- footer -->
    <Footer />
    <!-- 侧边悬浮 -->
    <Sidebar />
    <!-- </div> -->
  </div>
</template>
<script>
import { getOpenUser, brokerOfficeDetails } from 'services/broker'
import DetailsHeader from 'components/office/DetailsHeader.vue';
import Sidebar from 'components/home/sidebar.vue';
import DetailsSearch from 'components/office/DetailsSearch.vue';
import DetailsCarousel from 'components/office/DetailsCarousel.vue';
import DetailsHeaderInfo from 'components/office/DetailsHeaderInfo.vue';
import DetailsInfoTab from 'components/office/DetailsInfoTab.vue';
import DetailsInfo from 'components/office/DetailsInfo.vue';
import Footer from 'components/home/footer.vue';
export default {
  name: 'PlantDetails',
  components: {
    DetailsHeader,
    DetailsSearch,
    DetailsCarousel,
    DetailsHeaderInfo,
    DetailsInfoTab,
    DetailsInfo,
    Sidebar,
    Footer
  },
  data () {
    return {
      infoActive: '基本信息',
      details: {},
      broker: {},
      detailsVisible: false
    }
  },
  mounted () {
    this.$forceUpdate()
    let detailId = this.$route.params.detailId
    if (this.$route.query.broker) {
      localStorage.brokerToken = this.$route.query.broker
    }

    // 判断厂房是否存在模型、全景
    let vm = this
    fetch(window.modelDataIp + "/modelData.json").then(function (response) {
      return response.json();
    }).then(data => {
      vm.$store.commit('setModelData', data)
      vm.getDetails(detailId)

    })
  },
  methods: {
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.broker = res.data.data
          // this.details = {
          //   ...this.details,
          //   broker: this.broker
          // }
        }
      })
    },
    setInfoTab (str) {
      this.infoActive = str
    },
    scrollTab (val) {
      this.$refs['info-tab'].infoActive = val
    },
    getDetails (id) {
      console.log('params', this.$route.query.broker);
      brokerOfficeDetails({ id }).then(res => {
        if (res.data.status === 200) {
          this.details = res.data.data
          this.detailsVisible = true
          this.getUser()
          localStorage.HOUSE_DETAILS = JSON.stringify(res.data.data)
        }
      })

    },
    tabItem (id) {
      this.$refs.DetailsInfo.getStep(id)
    },
    scrollTo (top) {
      const main = document.getElementById('details')
      console.log(main);
      main.scrollTo({
        top: top - 120,
        behavior: 'smooth'
      })
    }
  }
};
</script>
<style lang="less" scoped>
.office-details {
  // overflow: auto;
  .header-content {
    padding: 20px calc(50% - 600px) 50px;
    background-color: #fff;
    main {
      display: flex;
      // align-items: end;
      justify-content: space-between;
      width: 100%;
    }
    &-left {
      width: 60%;
      margin-right: 60px;
    }
    &-right {
      width: calc(40% - 60px);
    }
  }
  .info-main {
    padding: 20px calc(50% - 600px) 0px;
    background-color: #fff;
  }
}
</style>
  