<template>
  <div class="IntermediaryAgent" id="model">
    <p v-if="titleShow" class="title">
      {{ details.title }} {{ details.sourceType === 1 ? '经纪人' : '业主直租' }}
    </p>
    <p v-if="priceShow" class="count">
      {{
        details.totalPrice > 1000
          ? (details.totalPrice / 10000).toFixed(2)
          : details.totalPrice
      }}<span class="price-unit"
        >{{ details.totalPrice > 1000 ? '万' : '' }}元/月</span
      >
    </p>
    <div class="user">
      <img
        class="tx"
        :src="los + user.headImg + '?' + Date.now()"
        alt=""
        @click="toPush('/counselorHome')"
      />
      <div class="user-info" @click="toPush('/counselorHome')">
        <div class="user-info-name">
          <span>{{ user.name }}</span>
          <!-- <el-rate v-model="value1"></el-rate> -->
        </div>
        <div class="user-info-company" :title="user.company">
          {{ user.company }}
        </div>
      </div>
      <div class="wx-code">
        <div class="qrCodeDiv" ref="qrCodeDiv"></div>
        <p>微信扫码拨号</p>
      </div>
    </div>
    <div class="phone">
      <i
        style="width: 18px; height: 18px"
        class="icon icon-plant-details-phone"
      ></i>
      <span>{{ user.secretPhoneText || '暂无号码' }}</span>
    </div>
    <!-- <div class="phone" v-if="details.sourceType !== 1">
      <i
        style="width: 18px; height: 18px"
        class="icon icon-plant-details-phone"
      ></i>
      <span>{{ user.secretPhoneText || '暂无号码' }}</span>
    </div>
    <div v-else>
      <div class="inp" @click="lookHouseVisible = true">委托找房</div>
      <div class="inp-phone">
        <i class="icon icon-plant-details-phone-active"></i>
        <span>{{ user.secretPhoneText || '暂无号码' }}</span>
      </div>
    </div> -->

    <!-- 委托找房 -->
    <el-dialog
      title="委托找房"
      :visible.sync="lookHouseVisible"
      width="560px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="lookHouseClose"
    >
      <div class="body">
        <div class="row">
          <label for="">称呼:</label>
          <el-input
            class="content"
            v-model="ruleForm.name"
            placeholder="请输入你的称呼"
          ></el-input>
        </div>
        <div class="row">
          <label for="" class="required" style="justify-content: end"
            >手机号:</label
          >
          <el-input
            class="content"
            v-model="ruleForm.phone"
            placeholder="请输入你的手机号"
          ></el-input>
        </div>
        <div class="row">
          <label for="">面积:</label>
          <el-select class="content" v-model="areaLabelId" placeholder="请选择">
            <el-option
              v-for="item in areaOption"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <label for="">价格:</label>
          <el-select
            class="content"
            v-model="priceLabelId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in priceOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <label for="">房源地址:</label>
          <div class="content" style="display: flex; align-items: center">
            <el-select
              style="width: 200px; margin-right: 10px"
              v-model="provinceId"
              placeholder="选择省"
              @change="id => getAddress(id, 1)"
            >
              <el-option
                v-for="item in provinceOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 200px; margin-right: 10px"
              v-model="cityId"
              placeholder="选择市"
              @change="id => getAddress(id, 2)"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 200px; margin-right: 10px"
              v-model="districtId"
              placeholder="选择区县"
              @change="id => getAddress(id, 3)"
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              style="width: 200px"
              v-model="streetId"
              placeholder="选择街道/乡镇"
              @change="id => getAddress(id, 4)"
            >
              <el-option
                v-for="item in streetOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
        <div class="row" style="align-items: baseline">
          <label for="">委托需求:</label>
          <el-input
            class="content"
            type="textarea"
            style="height: 100px"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.detail"
            maxlength="300"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" type="primary" @click="submitFind"
          >提交委托</el-button
        >
      </span>
    </el-dialog>

    <!-- 回电弹窗 -->
    <!-- <div
      v-if="visible"
      class="code-box"
      :style="{ top: top + 30 + 'px', left: left - 55 + 'px' }"
    >
      <p>18874766241</p>
      <div class="c" ref="qrCodeDiv"></div>
      <p>扫码回电更快捷</p>
    </div> -->
  </div>
</template>
<script>
const uploadIp = window.uploadIp
const los = window.los
import QRCode from 'qrcodejs2';//引入生成二维码插件
import { getAreaByParent, getPriceAndArea } from 'services/common';
import { getOpenUser } from 'services/broker'
import { submitFindBuilding } from 'services/factory'
import logo1 from '../../assets/logo1.png'
export default {
  name: 'IntermediaryAgent',
  components: {},
  props: {
    details: Object,
    broker: Object,
    titleShow: {
      type: Boolean,
      default: false
    },
    priceShow: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      uploadIp,
      los,
      user: {},
      top: 0,
      left: 0,
      visible: false,
      value1: 0,
      input2: null,
      ruleForm: {
        phone: '',
        name: '',
        detail: '',
        type: 1,
      },
      value: '',
      lookHouseVisible: false,
      areaLabelId: '',
      priceLabelId: '',
      areaOption: [],
      priceOptions: [],
      provinceId: null,
      provinceOptions: [],
      cityId: null,
      cityOptions: [],
      districtId: null,
      areaOptions: [],
      streetId: null,
      streetOptions: [],
      buildType: 1,
      priceType: 1
    }
  },
  watch: {
    broker (val) {
      this.user = val
      console.log('----user', !this.user.secretPhoneExpiration);
      this.openCode(`${window.phoneCall}${this.user.secretPhone}${!this.user.secretPhoneExpiration ? '' : ',,' + this.user.secretPhoneExpiration}`)
    }
  },
  mounted () {
    // this.getAddress()
    // if (this.details.broker) {
    //   this.user = this.details.broker
    //   this.openCode(`${window.phoneCall}${this.user.secretPhone}/${this.user.secretPhoneExpiration}`)
    // } else {
    //   this.getUser()
    // }
    // this.getLabel()
  },
  methods: {
    getLabel () {
      let params = {
        buildType: this.buildType,
        priceType: this.priceType
      }
      getPriceAndArea(params).then(res => {
        if (res.data.status === 200) {
          this.areaOption = res.data.data.areaLabel
          this.priceOptions = res.data.data.priceLabel
        }
      })
    },
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      console.log('---this.details', params);
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
          let text = ''
          if (res.data.data.secretPhoneExpiration) {
            if (res.data.data.secretPhoneExpiration === res.data.data.secretPhone) {
              text = res.data.data.secretPhone
            } else {
              text = `${res.data.data.secretPhone},,${res.data.data.secretPhoneExpiration}`
            }
          } else {
            text = res.data.data.secretPhone
          }
          this.openCode(`${window.phoneCall}${text}`)
        }
      })
    },
    // 获取省市区街道
    getAddress (id, treeNode) {
      getAreaByParent({ parentId: id }).then(res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions = res.data.data;
          }
          if (treeNode === 1) {
            this.cityOptions = res.data.data
            console.log(this.provinceOptions.filter(item => item.id === id));
            this.province = this.provinceOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 2) {
            this.areaOptions = res.data.data
            this.city = this.cityOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 3) {
            this.streetOptions = res.data.data
            this.area = this.areaOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 4) {
            this.street = this.streetOptions.filter(item => item.id === id)[0].name
          }
        }
      })
    },
    toPush (path) {
      this.$router.push({
        path: path + '/' + this.details.id,
      })
    },
    openCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        let qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 88,//二维码宽度
          height: 88,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        });
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = logo1
        let con = this.$refs.qrCodeDiv;
        logo.onload = function () {
          let container = con;
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 88 * 0.5 - 11, 88 * 0.5 - 11, 22, 22);
          qrImg.src = canvas.toDataURL();
          container.appendChild(qrImg);
        }
      })
    },
    lookHouseClose () {
      this.lookHouseVisible = false
      this.ruleForm = {
        name: '',
        phone: '',
        type: 1,
        detail: ''
      }
      this.areaLabelId = null
      this.priceLabelId = null
      this.provinceId = null
      this.cityId = null
      this.districtId = null
    },
    submitFind () {
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.ruleForm.phone) {
        return this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
      } else if (!reg.test(this.ruleForm.phone)) {
        this.$message.warning('请正确输入手机号')
      } else {
        let params = {
          brokerId: this.details.brokerId,
          phone: this.ruleForm.phone,
          type: this.ruleForm.type,
          areaLabelId: this.areaLabelId,
          priceLabelId: this.priceLabelId,
          name: this.ruleForm.name,
          detail: this.ruleForm.detail,
          provinceId: this.provinceId,
          cityId: this.cityId,
          districtId: this.districtId,
        }
        submitFindBuilding(params).then(res => {
          if (res.data.status === 200) {
            this.lookHouseClose()
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
          }
        })
      }

    }
  }
};
</script>
    
<style lang="less" scoped>
.IntermediaryAgent {
  width: 100%;
  height: 100%;
  padding: 27px 20px;
  box-sizing: border-box;
  background: rgba(245, 245, 245, 1);
  border: 1px solid rgba(238, 238, 238, 1);
  p.title {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: rgba(51, 51, 51, 1);
  }
  p.count {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(236, 102, 0, 1);
  }
  .user {
    padding: 30px 0;
    display: flex;
    align-items: center;
    .tx {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      // background: url(https://img.js.design/assets/img/650d39e688010358b8f9551a.png#d2ac0510c4a5344e40913e358773a99a);
      cursor: pointer;
      border: 1px solid #eeeeee;
      margin-right: 15px;
    }
    .user-info {
      width: calc(100% - 200px);
      // margin-right: 24px;
      margin-right: 15px;
      cursor: pointer;
      &-name {
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        span {
          margin-right: 8px;
        }
      }
      &-company {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        color: rgba(136, 136, 136, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .wx-code {
      .qrCodeDiv {
        width: 88px;
        height: 88px;
        margin-bottom: 4px;
      }
      img {
        width: 88px;
        height: 88px;
      }
      p {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 12.06px;
        color: rgba(136, 136, 136, 1);
      }
    }
  }
  .phone {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ec6600;
    cursor: pointer;
    i {
      margin-right: 18px;
    }
    span {
      font-size: 20px;
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
    }
  }
  .inp {
    width: 300px;
    height: 40px;
    background: rgba(236, 102, 0, 1);
    border: 0.5px solid rgba(236, 102, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(255, 255, 255, 1);
  }
  .inp-phone {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    color: rgba(236, 102, 0, 1);
    i {
      margin-right: 18px;
    }
  }
}

.code-box {
  position: absolute;
  top: 30px;
  left: 40px;
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  .c {
    width: 110px;
    height: 114px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    &:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: rgba(51, 51, 51, 1);
    }
    &:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .sel-item {
        width: 30%;
      }
      label {
        width: 84px;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(153, 153, 153, 1);
        margin-right: 32px;
      }
      .content {
        width: calc(100% - 120px);
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
/deep/ .el-input__inner {
  opacity: 1;
  border-radius: 4px;
  // background: #f5f5f5;
  // border: 0;
}
/deep/ .el-input__icon {
  line-height: 34px;
}
/deep/ .el-textarea__inner {
  height: 100%;
}
</style>
    