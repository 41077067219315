<template>
  <div class="info-title">
    <i class="icon icon-plant-details-label"></i>
    <span>{{ title }}</span>
    <span class="z" v-show="accurateLocation"
      >非精确位置

      <el-tooltip
        class="item flex-align-center"
        effect="dark"
        placement="bottom-start"
      >
        <div slot="content" style="width: 400px">
          为保障经纪人线下房源隐私，该房源为非精确位置，如果需要线下看房，请先联系经纪人/业主获取准确房源定位
        </div>
        <div style="margin-left: 8px">
          <i class="icon icon-explain"></i>
        </div>
      </el-tooltip>
    </span>
  </div>
</template>
    <script>
export default {
  name: 'InfoTitle',
  props: {
    title: {
      type: String,
      default: '基本信息'
    },
    accurateLocation: {
      type: Boolean,
      default: false
    }
  }
};
    </script>
    
    <style lang="less" scoped>
.info-title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 32px;
  color: rgba(51, 51, 51, 1);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  i {
    margin-right: 11px;
  }
  .z {
    font-size: 14px;
    margin-left: 16px;
    color: rgba(153, 153, 153, 1);
    display: flex;
  }
}
</style>
    