<template>
  <div style="position: relative">
    <div
      class="list-item"
      v-for="item in plantList"
      :key="item.id"
      @click="toPlantDetails(item)"
    >
      <div class="img">
        <div class="SpecialOffer" v-show="item.preferential">
          {{ item.preferential }}
        </div>

        <el-image
          v-if="item.coverImg"
          class="img"
          :src="los + item.coverImg"
          lazy
        ></el-image>
        <el-image
          class="img"
          v-else
          :src="require('../../assets/no-img-list.png')"
          lazy
        >
          <div slot="error" class="image-slot">
            <img
              style="width: 100%"
              :src="'../../assets/no-img-list.png' + '?' + Date.now()"
              alt=""
            />
          </div>
        </el-image>
      </div>
      <div class="item-content">
        <div class="item-content-title">
          <div class="p">
            <p :title="item.title">{{ item.title }}</p>
            <!-- <span v-if="item.sourceType === 2 && sourceType">业主直租</span> -->
          </div>
          <div class="count" :title="`${item.totalPrice}元/月`">
            {{
              item.totalPrice > 1000
                ? (item.totalPrice / 10000).toFixed(2)
                : item.totalPrice
            }}<span class="price-unit f-s-22"
              >{{ item.totalPrice > 1000 ? '万' : '' }}元/月</span
            >
          </div>
        </div>
        <div class="item-content-main">
          <div class="label">
            <div class="label-line">
              <span style="color: rgba(51, 51, 51, 1)"
                >{{ item.totalArea }}㎡</span
              >
              <div class="line"></div>
              <span>{{ item.decorationName }}</span>
              <div class="line"></div>
              <span>{{ item.orientationName }}</span>
            </div>
            <div class="label-line">
              <!-- <i class="icon icon-plant-details-location"></i> -->
              {{ item.district }}-{{ item.street }} {{ item.buildingName }}
            </div>
            <div class="item-content-label">
              <div
                class="item-content-label-item"
                v-for="(i, index) in labelFilter(item.labels)"
                :key="index"
                :title="i"
              >
                {{ i }}
              </div>
            </div>
          </div>
          <div>
            <div
              class="count f-s-16"
              style="color: rgba(153, 153, 153, 1)"
              :title="`${item.price}元/㎡/月`"
            >
              {{ item.price
              }}<span
                class="price-unit f-s-16"
                style="color: rgba(153, 153, 153, 1)"
                >元/㎡/月</span
              >
            </div>
            <div
              v-show="phoneShow"
              class="phone"
              @click.stop="openCode($event, item)"
            >
              <i class="icon icon-user-phone"></i>
              <span>电话联系</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 回电弹窗 -->
    <div
      v-if="visible"
      class="code-box"
      :style="{ top: top + 30 + 'px', left: left - 55 + 'px' }"
    >
      <!-- <p>18874766241</p> -->
      <div class="c" ref="qrCodeDiv"></div>
      <p>扫码回电更快捷</p>
    </div>
  </div>
</template>
  <script>
import QRCode from 'qrcodejs2';//引入生成二维码插件
import { getOpenUser } from 'services/broker'
import logo1 from '../../assets/logo1.png'
const los = window.los
export default {
  name: 'HouseListItem',
  props: {
    plantList: {
      type: Array,
      default: () => []
    },
    phoneShow: {
      type: Boolean,
      default: true
    },
    sourceType: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data () {
    return {
      los,
      top: 0,
      left: 0,
      visible: false,
      select: {},
      selectID: {},
    }
  },
  mounted () { },
  methods: {
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    toPlantDetails (item) {
      let routeUrl = this.$router.resolve({
        path: '/plantDetails',
        data: {
          item: JSON.stringify(item)
        }
      })
      window.open(routeUrl.href + "/" + item.id, '_blank');
    },
    openCode ($event, item) {
      if (this.selectID !== item.id) {
        this.visible = true
        this.top = $event.layerY
        this.left = $event.layerX
        console.log($event);
        this.select = item
        this.selectID = item.id


        let params = {
          brokerId: item.brokerId
        };
        getOpenUser(params).then(res => {
          if (res.data.status === 200) {
            this.$nextTick(() => {
              this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
              let text = ''
              if (res.data.data.secretPhoneExpiration) {
                if (res.data.data.secretPhoneExpiration === res.data.data.secretPhone) {
                  text = res.data.data.secretPhone
                } else {
                  text = `${res.data.data.secretPhone},,${res.data.data.secretPhoneExpiration}`
                }
              } else {
                text = res.data.data.secretPhone
              }
              console.log(`${window.phoneCall}${text}`);
              let qrcode = new QRCode(this.$refs.qrCodeDiv, {
                text: `${window.phoneCall}${text}`,//二维码链接，参数是否添加看需求
                width: 88,//二维码宽度
                height: 88,//二维码高度
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
              });
              let logo = new Image();
              logo.crossOrigin = 'Anonymous';
              logo.src = logo1
              let con = this.$refs.qrCodeDiv;
              logo.onload = function () {
                let container = con;
                let qrImg = qrcode._el.getElementsByTagName('img')[0];
                let canvas = qrcode._el.getElementsByTagName('canvas')[0];
                var ctx = canvas.getContext("2d");
                ctx.drawImage(logo, 88 * 0.5 - 11, 88 * 0.5 - 11, 22, 22);
                qrImg.src = canvas.toDataURL();
                container.appendChild(qrcode._el);
              }
            })
          }
        })

      } else {
        this.visible = false
        this.select = {}
        this.selectID = null
      }
    }
  }
};
  </script>
  <style lang="less" scoped>
.list-item {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    .item-content-title {
      .p p {
        text-decoration: underline;
      }
    }
  }
  .img {
    width: 160px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-right: 24px;
    position: relative;
    overflow: hidden;
    .SpecialOffer {
      position: absolute;
      text-align: center;
      width: 62px;
      height: 22px;
      opacity: 1;
      border-radius: 4px 0px 4px 0px;
      background: rgba(247, 162, 25, 1);
      border: 1px solid rgba(247, 162, 25, 1);
      box-shadow: 0px 2px 2px rgba(38, 24, 0, 0.16);
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      color: rgba(255, 255, 255, 1);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item-content {
    width: calc(100% - 184px);
    height: 35%;
    box-sizing: border-box;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .p {
        display: flex;
        width: calc(100% - 238px);
        p {
          max-width: calc(100% - 74px);
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;
        }
        span {
          display: inline-block;
          width: 64px;
          text-align: center;
          border-radius: 4px;
          background: rgba(0, 184, 12, 1);
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(255, 255, 255, 1);
        }
      }
      .count {
        width: 180px;
        margin-left: 38px;
        text-align: end;
        font-family: DIN;
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        color: rgba(236, 102, 0, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-main {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin: 8px 0;
      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &-line {
          display: flex;
          align-items: center;
          // justify-content: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(153, 153, 153, 1);
          &:first-child {
            margin-bottom: 4px;
          }
          .line {
            width: 1px;
            height: 14px;
            background-color: rgba(221, 221, 221, 1);
            margin: 0 7px;
          }
        }
      }
      .count {
        color: rgba(236, 102, 0, 1);
        text-align: end;
      }
      .phone {
        margin-top: 12px;
        width: 110px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: rgba(232, 242, 252, 1);
        font-size: 14px;
        font-weight: 400;
        color: rgba(16, 118, 235, 1);
        cursor: pointer;
        span {
          margin-left: 8px;
        }
      }
    }
    &-label {
      margin-top: 12px;
      display: flex;
      align-items: center;
      &-item {
        padding: 3px 10px;
        border-radius: 2px;
        background: rgba(252, 244, 230, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(247, 162, 25, 1);
        margin-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.code-box {
  position: absolute;
  top: 30px;
  left: 40px;
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  .c {
    width: 88px;
    height: 88px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    &:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: rgba(51, 51, 51, 1);
    }
    &:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
    