<template>
  <div class="b-map" id="container">
    <slot></slot>
  </div>
</template>
    
<script>
/*eslint-disable*/
import {
  mapRangeSearch,
  mapRegionSearch
} from 'services/common.js'
import { bd09togcj02, gcj02towgs84, wgs84togcj02, gcj02tobd09 } from 'plugins/Coordtransform'
const cy = require('../../assets/map/cy.png')
const dt = require('../../assets/map/dt.png')
const fj = require('../../assets/map/fj.png')
const gs = require('../../assets/map/gs.png')
const gj = require('../../assets/map/gj.png')
const hc = require('../../assets/map/hc.png')
const jd = require('../../assets/map/jd.png')
const wl = require('../../assets/map/wl.png')
const yh = require('../../assets/map/yh.png')
const yl = require('../../assets/map/yl.png')
const zwfw = require('../../assets/map/zwfw.png')
export default {
  name: 'BMap',
  provide () {
    getMap: this.getMap
  },
  props: {
    adress: {
      type: Object,
      default: { name: '北京', lng: 16.40969, lat: 39.89945 }
    },
    points: Array
  },
  data () {
    return {
      ak: 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw',
      point: [],
    };
  },
  watch: {
    adress (val) {
      console.log(val);
    }
  },
  beforeCreate () {
    window._bd_share_main = ""
    window.BMap_loadScriptTime = (new Date()).getTime()
  },
  mounted () {
    this.$nextTick(() => {
      this.getMap().then(Map => {
        let map = new Map("container");
        map.centerAndZoom(this.adress.name, 12)
        //开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true)
        map.setHeading(0)
        this.map = map
        // this.getMark()
        if (this.points) {
          console.log('----', this.map);
          this.addMark(...this.points)
        }
        map.addEventListener('tilesloaded', () => {
          console.log('----', this.map);
          // 这里边写加载完成之后的执行操作
        })
      })
    })


  },
  methods: {
    getMap () {
      return new Promise(resolve => {
        let Map = window.BMapGL.Map;
        if (Map) {
          resolve(Map)
        } else {
          let timer = setInterval(() => {
            let Map = window.BMapGL.Map;
            if (Map) {
              resolve(Map)
              clearInterval(timer)
            }
          })
        }
      })
    },
    // 地图添加点坐标
    addMark (lng, lat, title) {
      // console.log('-------', lng, lat, BMapGL, this.map.addOverlay);
      this.marker = new BMapGL.Marker(new BMapGL.Point(lng, lat));
      this.map.addOverlay(this.marker);
      if (title)
        this.getMark(lng, lat, title)
      // this.$emit('setPoint', [lng, lat])
      // this.point = [lng, lat]
    },
    // 地图检索
    getSearchs (item) {
      // 百度地图API功能
      let vm = this
      let map = this.map
      this.map.clearOverlays(); //清除标注
      if (this.points.length > 0) this.addMark(...this.points)
      let mPoint = new BMapGL.Point(...this.points)
      map.enableScrollWheelZoom();
      map.centerAndZoom(mPoint, 15);
      let req, params
      if (item.name === '飞机' || item.name === '火车') {
        params = {
          keywords: item.name === '飞机' ? '机' : '火车站',
          region: JSON.parse(localStorage.city).name,
          dataType: item.dataType
        }
        req = mapRegionSearch
      } else {
        let po = bd09togcj02(this.points[0], this.points[1])
        let gcj02towgs84Arr = gcj02towgs84(po[0], po[1])
        params = {
          keywords: item.name,
          lon: gcj02towgs84Arr[0],
          lat: gcj02towgs84Arr[1],
          radius: 10000,
          dataType: item.dataType
        }
        req = mapRangeSearch
      }
      req(params).then(res => {
        if (res.data.status === 200) {
          console.log(res.data.data);
          let results = res.data.data
          if (results instanceof Array)
            results.forEach(item => {
              item.lon = gcj02tobd09(wgs84togcj02(item.lon, item.lat)[0], wgs84togcj02(item.lon, item.lat)[1])[0]
              item.lat = gcj02tobd09(wgs84togcj02(item.lon, item.lat)[0], wgs84togcj02(item.lon, item.lat)[1])[1]
              item.point = {
                lng: item.lon,
                lat: item.lat,
              }
              item.title = item.name
              if (!item.distance)
                item.distance = vm.getDistance(vm.points[1], vm.points[0], item.lat, item.lon, vm);
            })
          vm.addSearchPoint(results)
          // vm.$parent.loading = false
          vm.$emit('getMapSearchList', results)
        }
      })
    },
    // 经纬度转换成三角函数中度分表形式。
    rad (d) {
      return d * Math.PI / 180.0;
    },
    // 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
    getDistance (lat1, lng1, lat2, lng2, _this) {

      var radLat1 = _this.rad(lat1);
      var radLat2 = _this.rad(lat2);
      var a = radLat1 - radLat2;
      var b = _this.rad(lng1) - _this.rad(lng2);
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000; //输出为公里

      var distance = s;
      var distance_str = "";

      if (parseInt(distance) >= 1) {
        distance_str = distance.toFixed(2) + "km";
      } else {
        distance_str = (distance * 1000).toFixed(2) + "m";
      }
      //s=s.toFixed(4);
      return distance_str;
    },
    // 检索信息添加图标
    addSearchPoint (s) {
      console.log(s);
      let vm = this
      let icon;
      if (this.$parent.mapSearchId === 1) {
        if (this.$parent.mapSearchTabId === 1) icon = gj
        if (this.$parent.mapSearchTabId === 2) icon = dt
        if (this.$parent.mapSearchTabId === 3) icon = gs
        if (this.$parent.mapSearchTabId === 4) icon = fj
        if (this.$parent.mapSearchTabId === 5) icon = hc
      } else {
        if (this.$parent.mapSearchTabId === 1) icon = wl
        if (this.$parent.mapSearchTabId === 2) icon = cy
        if (this.$parent.mapSearchTabId === 3) icon = yl
        if (this.$parent.mapSearchTabId === 4) icon = jd
        if (this.$parent.mapSearchTabId === 5) icon = yh
        if (this.$parent.mapSearchTabId === 6) icon = zwfw
      }

      var myIcon = new BMapGL.Icon(icon, new BMapGL.Size(32, 32));
      s.forEach((item, index) => {
        if (item.point) {
          const point = new BMapGL.Point(item.point.lng, item.point.lat)
          item.marker = new BMapGL.Marker(point, { icon: myIcon });
          item.marker.params = { ...item, uid: index }
          this.map.addOverlay(item.marker);
          item.marker.addEventListener('click', function (e) {
            console.log(e);
            vm.$parent.addLabel(e.target.params)
          })
        }
      })
    },
    addLabel (params) {
      // 创建信息窗口
      var opts = {
        width: 200,
        height: 10,
        title: params.title
      };
      var point = new BMapGL.Point(params.point.lng, params.point.lat);
      var infoWindow = new BMapGL.InfoWindow(`地址：${params.address}`, opts);
      this.map.openInfoWindow(infoWindow, point);
    },
    getMark (lng, lat, title) {
      console.log('-------1', lng, lat, title);
      let map = this.map
      map.enableKeyboard();
      map.enableScrollWheelZoom();

      // 自定义展示内容
      function createDOM () {
        var div = document.createElement('div');
        div.style.zIndex = BMapGL.Overlay.getZIndex(this.point.lat);
        div.style.backgroundColor = '#fff';
        div.style.color = '#333';
        div.style.padding = ' 20px 10px';
        div.style.lineHeight = '40px';
        div.style.whiteSpace = 'nowrap';
        div.style.MozUserSelect = 'none';
        div.style.boxShadow = ' 0 4px 6px 1px rgba(13,4,9,0.08)';
        div.style.fontSize = '12px';
        div.style.borderRadius = '4px';
        div.style.display = 'flex';
        div.style.justifyContent = 'center';
        div.style.alignItems = 'center';

        var title = document.createElement('div');
        title.style.display = 'block';
        title.style.lineHeight = '10px';
        title.style.fontSize = '16px';
        title.style.fontWeight = '700';
        div.appendChild(title);
        title.appendChild(document.createTextNode(this.properties.title));

        var arrow = document.createElement('div');
        arrow.style.position = 'absolute';
        arrow.style.top = '50px';
        arrow.style.left = '50%-8px';
        arrow.style.width = '0';
        arrow.style.height = '0';
        arrow.style.borderColor = 'white transparent transparent transparent';
        arrow.style.borderStyle = 'solid';
        arrow.style.borderWidth = '10px';
        arrow.style.overflow = 'hidden';
        div.appendChild(arrow);
        return div;
      }
      // 创建自定义覆盖物
      console.log(lng, lat);
      var customOverlay = new BMapGL.CustomOverlay(createDOM, {
        point: new BMapGL.Point(lng, lat),
        opacity: 0.5,
        offsetY: -60,
        properties: {
          title,
        }
      });
      map.addOverlay(customOverlay);
    }
  }
};
</script>
<style lang="less" scoped>
.b-map {
  width: 100%;
  height: 100%;
}

/deep/ .anchorBL {
  // display: none;
}
/deep/ .BMap_cpyCtrl {
  // display: none;
}

/deep/ .BMap_bubble_title {
  width: 90% !important;
  font-size: 12px;
  font-weight: bold;
  overflow-x: scroll !important;
  &::-webkit-scrollbar {
    height: 5px !important;
  }
}
</style>
    