<template>
  <div class="list" style="position: relative">
    <div
      class="list-item"
      v-for="item in plantList"
      :key="item.id"
      @click="toPlantDetails(item)"
    >
      <div class="img">
        <div v-show="item.status !== 2" class="model">
          {{ item.statusName }}
        </div>
        <el-image
          v-if="item.coverImg"
          :src="los + item.coverImg + '?' + Date.now()"
          lazy
        ></el-image>
        <el-image v-else :src="require('../../assets/no-img-list.png')" lazy>
          <div slot="error" class="image-slot">
            <img
              style="width: 100%"
              :src="'../../assets/no-img-list.png' + '?' + Date.now()"
              alt=""
            />
          </div>
        </el-image>
      </div>
      <div class="item-content">
        <div class="item-content-title">
          <div class="p">
            <p :title="item.title">{{ item.title }}</p>
            <!-- <span>{{ item.sourceType === 2 ? '业主直租' : '经纪人' }}</span> -->
          </div>
        </div>
        <div class="item-content-main">
          <div class="label">
            <div class="label-line">
              <span>{{ item.buildingTypeName }}</span>
              <div class="line"></div>
              <span>{{ item.structureTypeName }}</span>
              <div class="line"></div>
              <span>{{ item.floorTypeName }}</span>
            </div>
            <div class="label-line">
              <span style="color: rgba(51, 51, 51, 1)"
                >{{
                  item.totalArea !== item.minArea
                    ? `${item.minArea}-${item.totalArea}`
                    : item.totalArea
                }}㎡</span
              >
              <div class="line"></div>
              <!-- <i class="icon icon-plant-details-location"></i> -->
              {{ item.district }}-{{ item.street }}
            </div>
          </div>
          <div class="count" :title="item.price">
            {{ item.price
            }}<span class="price-unit"
              >元/㎡/月{{ item.price !== item.maxPrice ? '起' : '' }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import QRCode from 'qrcodejs2';//引入生成二维码插件
const los = window.los
export default {
  name: 'HouseListItem',
  props: {
    plantList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data () {
    return {
      los,
      top: 0,
      left: 0,
      visible: false,
      select: {},
      selectID: {},
    }
  },
  mounted () { },
  methods: {
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    toPlantDetails (item) {
      if (item.status === 2) {
        let routeUrl = this.$router.resolve({
          path: '/plantDetails',
          data: {
            item: JSON.stringify(item)
          }
        })
        window.open(routeUrl.href + "/" + item.id, '_blank');
      }
    },
    openCode ($event, item) {
      if (this.selectID !== item.id) {
        this.visible = true
        this.top = $event.layerY
        this.left = $event.layerX
        console.log($event);
        this.select = item
        this.selectID = item.id
        this.$nextTick(() => {
          this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
          new QRCode(this.$refs.qrCodeDiv, {
            text: '15084932233',//二维码链接，参数是否添加看需求
            width: 110,//二维码宽度
            height: 110,//二维码高度
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
          });
        })
      } else {
        this.visible = false
        this.select = {}
        this.selectID = null
      }
    }
  }
};
  </script>
  <style lang="less" scoped>
.list {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 12px;
  box-sizing: border-box;
  background-color: #fff;
  &-item {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      .img .model {
        display: flex;
      }
    }
    .img {
      width: 94px;
      height: 70px;
      border-radius: 4px;
      border: 1px solid rgba(238, 238, 238, 1);
      margin-right: 8px;
      position: relative;
      .model {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-content {
      width: calc(100% - 102px);
      height: 35%;
      box-sizing: border-box;
      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .p {
          display: flex;
          width: 100%;
          p {
            width: calc(100% - 64px);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(51, 51, 51, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span {
            display: inline-block;
            width: 64px;
            text-align: center;
            border-radius: 4px;
            background: rgba(0, 184, 12, 1);
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      &-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0;
        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &-line {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: rgba(153, 153, 153, 1);
            &:first-child {
              margin-bottom: 4px;
            }
            .line {
              width: 1px;
              height: 14px;
              background-color: rgba(221, 221, 221, 1);
              margin: 0 7px;
            }
          }
        }
        .phone {
          width: 110px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: rgba(232, 242, 252, 1);
          font-size: 14px;
          font-weight: 400;
          color: rgba(16, 118, 235, 1);
          span {
            margin-left: 8px;
          }
        }
        .count {
          width: 120px;
          margin-left: 38px;
          font-size: 24px;
          font-weight: 400;
          font-family: DIN;
          line-height: 24px;
          text-align: end;
          color: rgba(236, 102, 0, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-label {
        display: flex;
        align-items: center;
        &-item {
          padding: 3px 10px;
          border-radius: 2px;
          background: rgba(252, 244, 230, 1);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(247, 162, 25, 1);
          margin-right: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
</style>
    