import axios from '../plugins/member';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 收藏房源
const collect = async data => axios.post('/collect/house/collect', data, form);

// 取消收藏房源
const cancelCollect = async data =>
  axios.post('/collect/house/cancel', data, form);

// 分页查询收藏房源
const collectHousePage = params => axios.get('/collect/house/page', { params });

// 提交反馈
const submitFeedBack = async data => axios.post('/feedback/submit', data, form);

// 提交合作需求
const cooperation = async data => axios.post('/cooperation/submit', data, form);

// 获取邀请链接的参数
const invitationLinkParam = params =>
  axios.get('/invitation/link/param', { params });

export {
  collect,
  cancelCollect,
  collectHousePage,
  submitFeedBack,
  cooperation,
  invitationLinkParam
};
