<template>
  <div class="user-info">
    <div class="title">个人信息</div>
    <main>
      <div class="info">
        <div class="label">头像</div>
        <div class="content">
          <img
            class="img"
            v-if="!user.headImg"
            src="../../assets/user/tx.png"
            alt=""
          />
          <img v-else :src="los + user.headImg + '?' + Date.now()" alt="" />
        </div>
        <div class="edit" @click="editPicture">修改</div>
      </div>
      <div class="info">
        <div class="label">昵称</div>
        <div class="content">{{ user.nickname || '暂未设置' }}</div>
        <div class="edit" @click="editNickName">修改</div>
      </div>
      <div class="info">
        <div class="label">性别</div>
        <div class="content" v-if="user.gender">
          {{ user.gender === 1 ? '男' : '女' }}
        </div>
        <div class="content" v-else>暂未设置</div>
        <div class="edit" @click="editSex">修改</div>
      </div>
      <div class="info">
        <div class="label">绑定手机号</div>
        <div class="content">{{ user.phone }}</div>
        <div class="edit" @click="editPhone">修改</div>
      </div>
      <div class="info">
        <div class="label">登录密码</div>
        <div class="content">{{ !user.initPwd ? '已设置' : '未设置' }}</div>
        <div class="edit" @click="editPassWord">
          {{ !user.initPwd ? '修改' : '设置密码' }}
        </div>
      </div>
    </main>

    <!-- 修改昵称 -->
    <el-dialog
      title="修改昵称"
      :visible.sync="nickNameVisible"
      width="480px"
      :before-close="handleClose"
    >
      <div class="body">
        <el-input v-model="nickname" placeholder="请输入..."></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nickNameVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="changeNickName"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 性别弹窗 -->
    <el-dialog title="修改性别" :visible.sync="sexVisible" width="480px">
      <div class="body">
        <el-radio v-model="gender" :label="1">男</el-radio>
        <el-radio v-model="gender" :label="2">女</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sexVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="changeSex"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改头像 -->
    <el-dialog
      title="修改头像"
      :visible.sync="pictureVisible"
      width="480px"
      :before-close="pictureClose"
    >
      <div class="body">
        <div class="img">
          <img :src="los + user.headImg + '?' + Date.now()" alt="" />
        </div>
        <div class="text">
          *上传文件大于20k,小于600k,支持jpg、 png格式的图片
        </div>
        <el-upload
          class="upload-demo"
          :action="uploadIp + '/file/common/upload/img/1'"
          :before-upload="beforeUpload"
          :on-change="handleCertifiedPreview"
          :on-remove="handleCertifiedRemove"
          multiple
        >
          <div class="el-upload__text">选择头像</div>
        </el-upload>
      </div>
    </el-dialog>

    <!-- 修改绑定手机号 -->
    <el-dialog
      title="修改绑定手机号"
      :visible.sync="phoneVisible"
      width="480px"
      :before-close="phoneClose"
    >
      <div class="body">
        <el-input
          style="margin-bottom: 20px"
          v-model="phone"
          placeholder="请输入手机号码"
        >
          <i slot="prefix" class="icon icon-user-mobile"></i>
          <span slot="suffix" class="code" @click="setCode">{{
            count > 59 ? '获取验证码' : count
          }}</span>
        </el-input>
        <el-input v-model="code" placeholder="请输入验证码">
          <i slot="prefix" class="icon icon-user-code"></i>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="phoneVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="changePhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改登录密码 -->
    <el-dialog
      title="修改登录密码"
      :visible.sync="passwordVisible"
      width="480px"
      :before-close="passwordClose"
    >
      <div class="body">
        <el-input
          type="password"
          style="margin-bottom: 20px"
          v-model="oldPassword"
          placeholder="输入原密码"
          v-if="!user.initPwd"
        >
        </el-input>
        <el-input
          type="password"
          style="margin-bottom: 20px"
          v-model="password"
          placeholder="输入新密码"
        >
        </el-input>
        <el-input
          type="password"
          v-model="newPassword"
          placeholder="再次输入新密码"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="okPassWorld"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
const los = window.los
const uploadIp = window.uploadIp
import {
  userInfo,
  initPassWord,
  editPassWord,
  userInfoModify,
  userPhoneCode,
  changeUserPhone
} from 'services/login';
export default {
  name: 'UserInfo',
  components: {},
  data () {
    return {
      los,
      uploadIp,
      fileList: [],
      nickname: '',
      nickNameVisible: false,
      // picture: 'https://img.js.design/assets/img/65116bf3d55e5b8b472369de.png#c7ea4c02a55ed89df2cd1459276ecb03',
      pictureVisible: false,
      phone: '',
      code: '',
      phoneVisible: false,
      count: 60,
      oldPassword: '', // 原密码
      password: '', // 新密码
      newPassword: '', // 再次输入新密码
      passwordVisible: false,
      sexVisible: false,
      gender: 1,
      user: {}
    }
  },
  watch: {
    passwordVisible (val) {
      if (!val) {
        this.oldPassword = ''
        this.password = ''
        this.newPassword = ''
      }
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo () {
      userInfo().then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
        }
      })
    },

    beforeUpload (file) {
      let imgSize = Number(file.size / 1024 / 1024);
      console.log(imgSize);
      if (imgSize > 5) {
        this.$message({
          message: '文件不超过5MB',
          type: 'warning'
        });
        return false
      } else {
        return true
      }
    },
    handleCertifiedRemove () {
      this.user.headImg = '';
    },
    handleCertifiedPreview (file) {
      if (file.status === "success") {
        if (file.response.status === 200) {
          userInfoModify({ headImg: file.response.data.fileUrl }).then(res => {
            if (res.data.status === 200) {
              this.getUserInfo()
              this.$parent.getUserInfo()
              return this.$message.success(res.data.msg)
            }
          })
        }
      }
      console.log(this.user.headImg);
    },
    async setCode () {
      if (!this.timer) {
        var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
        if (!this.phone) {
          this.$message.warning('请输入手机号')
        } else if (!reg.test(this.phone)) {
          this.$message.warning('请正确输入手机号')
        } else {
          let res = await userPhoneCode({ phone: this.phone });
          if (res.data?.status === 200) {
            this.$message.success(res.data.msg)
          }
          this.timer = setInterval(() => {
            this.count--
            if (this.count < 1) {
              clearInterval(this.timer)
              this.timer = null
              this.count = 60
            }
          }, 1000)
        }
      }
    },
    changePhone () {
      // 区分是密码登录还是验证码登录
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.phone) {
        return this.$message.warning('请输入手机号')
      } else if (!reg.test(this.phone)) {
        return this.$message.warning('请正确输入手机号')
      } else if (!this.code) {
        // 验证码登录
        return this.$message.warning('请输入验证码')
      } else {
        const data = {
          phone: this.phone,
          code: this.code,
        }
        changeUserPhone(data).then(async res => {
          console.log(res);
          if (res.data.status === 200) {
            this.getUserInfo()
            this.$parent.getUserInfo()
            this.phoneVisible = false
            return this.$message.success(res.data.msg)
          }
        })
      }
    },
    handleClose () {
      this.nickNameVisible = false
    },
    pictureClose () {
      this.pictureVisible = false
    },
    phoneClose () {
      this.phoneVisible = false
    },
    passwordClose () {
      this.passwordVisible = false
    },
    editNickName () {
      this.nickname = this.user?.nickname
      this.nickNameVisible = true
    },
    changeNickName () {
      if (!this.nickname) {
        return this.$message.warning('请正确昵称')
      } else {
        userInfoModify({ nickname: this.nickname }).then(res => {
          if (res.data.status === 200) {
            this.getUserInfo()
            this.nickNameVisible = false
            return this.$message.success(res.data.msg)
          }
        })
      }
    },
    editSex () {
      this.gender = this.user?.gender
      this.sexVisible = true
    },
    changeSex () {
      userInfoModify({ gender: this.gender }).then(res => {
        if (res.data.status === 200) {
          this.getUserInfo()
          this.sexVisible = false
          return this.$message.success(res.data.msg)
        }
      })
    },
    editPicture () {
      console.log(this.user.headImg);
      // if (this.user.headImg)
      //   this.fileList = [{ url: window.los + this.user.headImg }]
      this.pictureVisible = true
    },
    editPhone () {
      this.phone = this.user?.phone
      this.phoneVisible = true
    },
    editPassWord () {
      this.passwordVisible = true
    },
    okPassWorld () {
      // 是否是初始密码（未设置密码） 1.是 0.否
      if (!this.user.initPwd && !this.oldPassword) {
        this.$message({
          type: 'warning',
          message: '请输入原密码！'
        })
      } else if (!this.password) {
        this.$message({
          type: 'warning',
          message: '请输入新密码！'
        })
      } else if (this.password !== this.newPassword) {
        this.$message({
          type: 'warning',
          message: '请确认两次输入新密码是否相同！'
        })
      } else {
        let params = {
          newPassword: this.$md5(this.newPassword)
        }
        if (!this.user.initPwd) {
          params.password = this.$md5(this.oldPassword)
          editPassWord(params).then(res => {
            if (res.data.status === 200) {
              this.passwordVisible = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
            }
          })

        } else {
          initPassWord({ password: this.$md5(this.password) }).then(res => {
            if (res.data.status === 200) {
              this.passwordVisible = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
            }
          })

        }
      }

    }
  }
};
</script>

<style lang="less" scoped>
.user-info {
  height: 100%;
  padding: 40px 36px 24px 24px;
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(51, 51, 51, 1);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(245, 245, 245, 1);
  }
  main {
    padding: 32px 40px;
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      .label {
        width: 130px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(153, 153, 153, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        width: 260px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(51, 51, 51, 1);
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }
      .edit {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 4px;
        color: rgba(236, 102, 0, 1);
        background: rgba(255, 243, 235, 1);
        cursor: pointer;
      }
    }
  }
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.body {
  /deep/ .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    background: rgba(247, 247, 247, 1);
    border: 0;
  }
  .img {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 16px;
    img {
      width: 120px;
      height: 100%;
      border: 1px solid rgba(238, 238, 238, 1);
    }
  }
  .text {
    padding: 0 60px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 20px;
  }
  .code {
    font-size: 14px;
    font-weight: 400;
    line-height: 50px;
    color: rgba(236, 102, 0, 1);
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
.upload-demo {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  /deep/ .el-upload {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: rgba(255, 243, 235, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: rgba(236, 102, 0, 1);
  }
  /deep/ .el-upload-list {
    display: none;
  }
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: rgb(51, 51, 51);
}
/deep/ .el-input__prefix {
  display: flex;
  align-items: center;
}
</style>