<template>
  <div class="map-search">
    <MapSearchHeader />
    <div class="map-search-sel">
      <div
        class="more"
        :class="districtVisible ? 'active' : ''"
        @click.stop="openCode($event, 1)"
      >
        区域
        <i
          :class="
            districtVisible ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
          "
        ></i>
      </div>

      <!-- <div
        class="more"
        :class="priceVisible ? 'active' : ''"
        @click.stop="openCode($event, 2)"
      >
        价格
        <i
          :class="priceVisible ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
        ></i>
      </div>

      <div
        class="more"
        :class="areaVisible ? 'active' : ''"
        @click.stop="openCode($event, 3)"
      >
        面积
        <i
          :class="areaVisible ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
        ></i>
      </div> -->
      <!-- <div
        class="more"
        :class="visible ? 'active' : ''"
        @click.stop="openCode($event, 'all')"
      >
        更多
        <i :class="visible ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
      </div> -->
    </div>
    <div class="map">
      <BMapSearch
        :adress="adress"
        :citys="district"
        :searchParams="searchParams"
        @getHousePage="getHousePage"
        @getItem="getItem"
        ref="map"
      />
      <PlantMapList
        v-show="show"
        :plantList="plantList"
        ref="PlantMapList"
        :page="page"
        :total="total"
        :totalPage="totalPage"
        :activeId="activeId"
        @getList="getList"
        @getItem="getItem"
      />
      <PlantMapDetails
        ref="PlantMapDetails"
        v-show="show && JSON.stringify(plantDetails) !== '{}'"
        :plantDetails="plantDetails"
      />
    </div>

    <!-- 区域弹窗 -->
    <div
      v-if="districtVisible"
      class="code-box"
      :style="{
        top: top + 30 + 'px',
        left: 'calc(50% - 600px)',
        width: '300px'
      }"
    >
      <main>
        <p class="title">位置</p>
        <div class="checkbox">
          <el-radio-group v-model="districtId" @change="getStreet">
            <el-radio-button label="">不限</el-radio-button>
            <el-radio-button
              v-for="item in place"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <p class="title" v-if="districtId">街道</p>
        <div class="checkbox" v-if="districtId">
          <el-radio-group v-model="streetId" @change="getHouse">
            <el-radio-button
              v-for="item in street"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </main>
    </div>

    <!-- 价格弹窗 -->
    <div
      v-if="priceVisible"
      class="code-box"
      :style="{
        top: top + 30 + 'px',
        left: 'calc(50% - 600px)',
        width: '380px'
      }"
    >
      <main>
        <p class="title">价格</p>
        <div class="price-tab">
          <div
            class="price-tab-item"
            :class="priceTabIndex == 1 ? 'active' : ''"
            @click="setPriceTab(1)"
          >
            按单价（元/m²/月）
          </div>
          <div
            class="price-tab-item"
            :class="priceTabIndex == 2 ? 'active' : ''"
            @click="setPriceTab(2)"
          >
            按总价（万元/月）
          </div>
        </div>
        <div class="checkbox">
          <el-checkbox-group v-model="priceLabelIds" @change="changePrice">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox
              v-for="item in price"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
            <div class="min-max-inp">
              <el-input
                class="min"
                type="number"
                v-model="ruleForm.minPrice"
                placeholder=""
                @input="e => NumberCheck(e, 'minPrice')"
              ></el-input>
              <span class="line">-</span>
              <el-input
                class="max"
                type="number"
                v-model="ruleForm.maxPrice"
                placeholder=""
                @input="e => NumberCheck(e, 'maxPrice')"
              ></el-input>
              <span class="dw">{{
                priceTabIndex == 1 ? '元/m²/月' : '万元/月'
              }}</span>
            </div>
          </el-checkbox-group>
        </div>
      </main>
      <div class="dialog-footer">
        <el-button type="text" class="btn-close" @click="priceVisible = false"
          >取消</el-button
        >
        <el-button type="text" class="btn" @click="setPrice">确认</el-button>
      </div>
    </div>

    <!-- 面积弹窗 -->
    <div
      v-if="areaVisible"
      class="code-box"
      :style="{
        top: top + 30 + 'px',
        left: 'calc(50% - 600px)',
        width: '420px'
      }"
    >
      <main>
        <p class="title">区域</p>
        <div class="checkbox">
          <el-checkbox-group v-model="areaLabelIds" @change="changeArea">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox v-for="item in area" :key="item.id" :label="item.id">{{
              item.name
            }}</el-checkbox>
            <div class="min-max-inp">
              <el-input
                class="min"
                type="number"
                v-model="ruleForm.minArea"
                placeholder=""
                @input="e => NumberCheck(e, 'minArea')"
              ></el-input>
              <span class="line">-</span>
              <el-input
                class="max"
                type="number"
                v-model="ruleForm.maxArea"
                placeholder=""
                @input="e => NumberCheck(e, 'maxArea')"
              ></el-input>
              <span class="dw">m²</span>
            </div>
          </el-checkbox-group>
        </div>
      </main>
      <div class="dialog-footer">
        <el-button type="text" class="btn-close" @click="areaVisible = false"
          >取消</el-button
        >
        <el-button type="text" class="btn" @click="setArea">确认</el-button>
      </div>
    </div>

    <!-- 更多弹窗 -->
    <div
      v-if="visible"
      class="code-box"
      :style="{
        top: top + 30 + 'px',
        left: 'calc(50% - 600px)',
        width: '400px'
      }"
    >
      <main>
        <p class="title">楼层</p>
        <div class="checkbox">
          <el-checkbox-group v-model="floorIds" @change="changeFloor">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox
              v-for="item in floor"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <p class="title">装修</p>
        <div class="checkbox">
          <el-checkbox-group v-model="buildTypeIds" @change="changeBuildType">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox v-for="item in type" :key="item.id" :label="item.id">{{
              item.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <p class="title">设施</p>
        <div class="checkbox">
          <el-checkbox-group v-model="facilitiesIds" @change="changeFacility">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox
              v-for="item in facility"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <p class="title">特色</p>
        <div class="checkbox">
          <el-checkbox-group v-model="buildLabelIds" @change="changeBuildLabel">
            <el-checkbox label="">不限</el-checkbox>
            <el-checkbox
              v-for="item in feature"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </main>
      <div class="dialog-footer">
        <el-button class="btn-close" type="text" @click="visible = false"
          >取消</el-button
        >
        <el-button class="btn" type="text" @click="submitFind">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { searchBuildsParam, leaseDetail } from 'services/office'
import { getAreaByParent } from 'services/common';
import MapSearchHeader from 'components/other/MapSearchHeader.vue'
import PlantMapList from 'components/other/PlantMapList.vue';
import PlantMapDetails from 'components/other/PlantMapDetails.vue';
import BMapSearch from 'components/map/BMapSearch.vue';
export default {
  name: 'MapSearch',
  components: {
    MapSearchHeader,
    PlantMapList,
    PlantMapDetails,
    BMapSearch
  },
  data () {
    return {
      priceTabIndex: 1,
      show: false,
      adress: {
        name: '深圳'
      },
      district: [],
      activeId: null,
      searchParams: {},
      ruleForm: {
        minArea: '',
        maxArea: '',
        minPrice: '',
        maxPrice: '',
      },
      // 筛选选项-start
      place: [],
      districtId: null,
      street: [],
      streetId: null,
      area: [],
      areaLabelIds: [''],
      unitPrice: [],
      totalPrice: [],
      price: [],
      priceLabelIds: [''],
      ageLimit: [],
      yearIds: [''],
      floor: [],
      floorIds: [''],
      type: [],
      buildTypeIds: [''],
      structure: [],
      structureIds: [''],
      specialIndustryArr: [],
      facility: [],
      facilitiesIds: [''],
      feature: [],
      buildLabelIds: [''],
      letMethod: '',
      sourceType: '',
      totalFloor: '',
      leaseOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '整租'
      }, {
        value: 2,
        label: '分租'
      }],
      housingSourceOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '经纪人'
      }, {
        value: 2,
        label: '业主'
      }],
      numberOfPliesOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '单层'
      }, {
        value: 2,
        label: '多层'
      }],
      drop: false,
      plantList: [],
      plantDetails: {},
      total: 0,
      totalPage: 1,
      page: 1,
      top: 0,
      left: 0,
      visible: false,
      districtVisible: false,
      priceVisible: false,
      areaVisible: false,
      openDom: ''
    };
  },
  mounted () {
    // 判断token是否失效
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
            clearTimeout(timer)
          }, 500)
      }
    })
    this.$forceUpdate()
    const city = localStorage.city
    this.timer = setTimeout(() => {
      this.$nextTick(() => {
        this.adress = {
          ...JSON.parse(city)
        }
        this.getSearch()
        clearTimeout(this.timer)
      })
    }, 1500)

  },
  methods: {
    setPriceTab (index) {
      if (index != this.priceTabIndex) this.priceLabelIds = ['']
      this.priceTabIndex = index
      if (index === 1) this.price = this.unitPrice
      if (index === 2) this.price = this.totalPrice
    },
    // 获取搜索参数
    getSearch () {
      searchBuildsParam().then(res => {
        if (res.data.status === 200) {
          // 处理数据
          //标签类型 1.面积 2.价格 3.总价 4.楼层 5.建筑类型 6.结构 7.房源特色 8.配套设施
          this.area = res.data.data.filter(item => item.type === 1)
          this.unitPrice = res.data.data.filter(item => item.type === 2)
          this.totalPrice = res.data.data.filter(item => item.type === 3)
          // this.ageLimit = res.data.data.filter(item => item.type === 3)
          // this.floor = res.data.data.filter(item => item.type === 4)
          // this.type = res.data.data.filter(item => item.type === 5)
          // this.structure = res.data.data.filter(item => item.type === 6)
          // this.feature = res.data.data.filter(item => item.type === 7)
          // this.facility = res.data.data.filter(item => item.type === 8)
          this.price = this.unitPrice
        }
      })
      let cityId = JSON.parse(localStorage.city).cityId
      getAreaByParent({ parentId: cityId }).then(res => {
        if (res.data.status === 200) {
          this.place = res.data.data
          this.district = this.place
        }
      })
    },
    // 限制输入框
    NumberCheck (num, val) {
      let str = val === 'totalArea' ? Number(Number(num).toFixed(0)) : Number(Number(num).toFixed(2));
      if (str < 0) str = str * -1;
      if (str.toString().length > 10) str = str.toString().substring(0, 10);
      this.ruleForm[val] = str
      if (val === 'minPrice' || val === 'maxPrice') this.priceLabelIds = []
      if (val === 'minArea' || val === 'maxArea') this.areaLabelIds = []
    },
    changeArea () {
      console.log(this.areaLabelIds);
      if (!this.areaLabelIds[this.areaLabelIds.length - 1]) {
        this.areaLabelIds = ['']
      } else {
        this.areaLabelIds = this.areaLabelIds.filter(item => item)
      }
      this.ruleForm.minArea = null
      this.ruleForm.maxArea = null
    },
    setArea () {
      if (this.ruleForm.maxArea < this.ruleForm.minArea) {
        const maxArea = this.ruleForm.minArea
        this.ruleForm.minArea = this.ruleForm.maxArea
        this.ruleForm.maxArea = maxArea
      } else if (this.ruleForm.maxArea > this.ruleForm.minArea) {
        this.areaLabelIds = []
      }
      this.areaVisible = false
      this.submitFind()
    },
    changePrice () {
      console.log(this.priceLabelIds);
      if (!this.priceLabelIds[this.priceLabelIds.length - 1]) {
        this.priceLabelIds = ['']
      } else {
        this.priceLabelIds = this.priceLabelIds.filter(item => item)
      }
      this.ruleForm.minPrice = null
      this.ruleForm.maxPrice = null
    },
    changeYear () {
      if (!this.yearIds[this.yearIds.length - 1]) {
        this.yearIds = ['']
      } else {
        this.yearIds = this.yearIds.filter(item => item)
      }
    },
    changeFloor () {
      if (!this.floorIds[this.floorIds.length - 1]) {
        this.floorIds = ['']
      } else {
        this.floorIds = this.floorIds.filter(item => item)
      }
    },
    changeBuildType () {
      if (!this.buildTypeIds[this.buildTypeIds.length - 1]) {
        this.buildTypeIds = ['']
      } else {
        this.buildTypeIds = this.buildTypeIds.filter(item => item)
      }
    },
    changeStructure () {
      if (!this.structureIds[this.structureIds.length - 1]) {
        this.structureIds = ['']
      } else {
        this.structureIds = this.structureIds.filter(item => item)
      }
    },
    changeFacility () {
      if (!this.facilitiesIds[this.facilitiesIds.length - 1]) {
        this.facilitiesIds = ['']
      } else {
        this.facilitiesIds = this.facilitiesIds.filter(item => item)
      }
    },
    changeBuildLabel () {
      if (!this.buildLabelIds[this.buildLabelIds.length - 1]) {
        this.buildLabelIds = ['']
      } else {
        this.buildLabelIds = this.buildLabelIds.filter(item => item)
      }
    },
    setPrice () {
      if (this.ruleForm.maxPrice < this.ruleForm.minPrice) {
        const maxPrice = this.ruleForm.minPrice
        this.ruleForm.minPrice = this.ruleForm.maxPrice
        this.ruleForm.maxPrice = maxPrice
      } else if (this.ruleForm.maxPrice > this.ruleForm.minPrice) {
        this.priceLabelIds = []
      }
      this.priceVisible = false
      this.submitFind()
    },
    dropDown () {
      this.drop = !this.drop
    },
    // 获取街道
    getStreet (parentId) {
      this.streetId = null
      getAreaByParent({ parentId }).then(res => {
        if (res.data.status === 200) {
          this.street = res.data.data
          this.$refs.map.searchStreet(parentId)
        }
      })
    },
    getHouse (parentId) {
      this.districtVisible = false
      this.$refs.map.searchHouse(parentId)
    },
    openCode ($event, val) {
      if (val === 'all') {
        this.visible = !this.visible
        this.districtVisible = false
        this.priceVisible = false
        this.areaVisible = false
      }
      if (val === 1) {
        this.districtVisible = !this.districtVisible
        this.visible = false
        this.priceVisible = false
        this.areaVisible = false
      }
      if (val === 2) {
        this.priceVisible = !this.priceVisible
        this.visible = false
        this.districtVisible = false
        this.areaVisible = false
      }
      if (val === 3) {
        this.areaVisible = !this.areaVisible
        this.visible = false
        this.districtVisible = false
        this.priceVisible = false
      }

      this.top = $event.layerY
      this.left = $event.layerX
      console.log($event);
    },
    closeFind () { },
    submitFind () {
      this.searchParams = {
        streetId: this.streetId,
        districtId: this.districtId,
        areaLabelIds: this.areaLabelIds,
        minArea: this.ruleForm.minArea,
        maxArea: this.ruleForm.maxArea,
      }
      if (this.priceTabIndex === 1) {
        this.searchParams.minPrice = this.ruleForm.minPrice
        this.searchParams.maxPrice = this.ruleForm.maxPrice
        this.searchParams.priceLabelIds = this.priceLabelIds
      }
      if (this.priceTabIndex === 2) {
        this.searchParams.minTotalPrice = this.ruleForm.minPrice
        this.searchParams.maxTotalPrice = this.ruleForm.maxPrice
        this.searchParams.totalPriceLabelIds = this.priceLabelIds
      }
      this.$nextTick(() => {
        this.$refs.map.getMapZoom()
        if (this.show) this.$refs.map.getHousePage(1, this.build)
        this.districtVisible = false
        this.visible = false
      })
    },
    // 获取分页房源
    getHousePage (result, build) {
      if (result.page === 1) this.plantList = []
      this.plantList = this.plantList.concat(result.data).map(item => {
        return {
          ...item,
          coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
        }
      })
      this.total = result.total
      this.totalPage = result.totalPage
      this.build = build
      this.$refs.PlantMapList.title = build.name
      this.page = result.page
    },
    getList (page) {
      this.page = page
      this.$refs.map.getHousePage(page, this.build)
    },
    // 点击地图定位
    getItem (item) {
      // this.activeId = item.id
      // this.$refs.map.houseLocation(item)
      leaseDetail({ id: item.id }).then(res => {
        if (res.data.status === 200) {
          this.plantDetails = res.data.data
          this.$refs.PlantMapDetails.show = true
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.map-search {
  &-sel {
    padding: 0 calc(50% - 600px);
    height: 40px;
    line-height: 40px;
    background: rgba(236, 102, 0, 1);
    box-shadow: 0px 2px 8px rgba(236, 102, 0, 0.4);
    display: flex;
    align-items: center;
    .more {
      display: flex;
      align-items: center;
      color: #fff;
      padding: 4px 12px;
      height: 30px;
      border-radius: 4px;
      background: rgba(236, 102, 0, 1);
      font-size: 14px;
      font-weight: 400;
      margin-right: 50px;
      cursor: pointer;
      i {
        margin-left: 10px;
        font-size: 14px;
      }
      &.active {
        background: #fff;
        color: rgba(236, 102, 0, 1);
      }
    }
    /deep/ .el-select {
      margin-right: 48px;
    }
    /deep/ .el-input__inner {
      width: 60px;
      padding: 0;
      height: 40px;
      border: 0;
      color: #fff;
      background: rgba(236, 102, 0, 1);
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      &::placeholder {
        color: #fff;
      }
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #fff;
    }
  }
  .map {
    height: calc(100% - 80px);
    position: relative;
    overflow: hidden;
  }
}
.code-box {
  position: absolute;
  top: 30px;
  left: 40px;
  max-height: 80%;
  overflow: auto;
  z-index: 9999;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  main {
    width: 100%;
    padding: 16px 30px;
    padding-bottom: 0;
    // height: 240px;

    .price-tab {
      display: flex;
      align-items: center;
      &-item {
        display: inline-block;
        line-height: 19px;
        font-size: 14px;
        margin-top: 8px;
        margin-right: 20px;
        cursor: pointer;
        color: #606266;
        &.active {
          color: #ec6600;
        }
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      margin: 10px 0 16px;
      // width: calc(100% - 158px);

      /deep/ .el-select {
        margin-right: 16px;
        .el-input__inner {
          width: 110px;
          height: 26px;
          line-height: 26px;
          border-radius: 2px;
          background: rgba(255, 255, 255, 1);
          border: 0.6px solid rgba(221, 221, 221, 1);
        }
        .el-input .el-select__caret {
          line-height: 26px;
        }
      }
      /deep/ .el-checkbox-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .el-checkbox {
          margin: 8px 0;
          margin-right: 20px;
        }
      }
      .min-max-inp {
        margin: 8px 0;
        display: flex;
        align-items: center;
        height: 26px;
        // margin-left: 36px;
        .min {
          width: 60px;
          height: 26px;
          /deep/ .el-input__inner {
            height: 26px;
            border: 0.6px solid rgba(221, 221, 221, 1);
            border-radius: 2px;
          }
        }
        .max {
          width: 60px;
          height: 26px;
          /deep/ .el-input__inner {
            height: 26px;
            border: 0.6px solid rgba(221, 221, 221, 1);
            border-radius: 2px;
          }
        }
        .line {
          padding: 0 4px;
          font-size: 14px;
          line-height: 26px;
        }
        span.dw {
          margin-left: 4px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(102, 102, 102, 1);
        }
        .btn {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(236, 102, 0, 1);
          margin-left: 12px;
        }
      }
    }
  }

  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 16px 20px;
    padding-top: 0;
    position: sticky;
    z-index: 999;
    bottom: 0;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    .btn-close {
      display: flex;
      align-items: center;
      color: rgba(51, 51, 51, 1);
      border: 0;
      margin-right: 20px;
    }
    .btn {
      display: flex;
      align-items: center;
      color: rgba(236, 102, 0, 1);
      border: 0;
    }
    /deep/ .el-button {
      height: 36px;
    }
  }
}

/deep/ .el-radio-button {
  margin-right: 30px;
  color: rgba(102, 102, 102, 1);
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
}
/deep/ .el-radio-button__inner {
  padding: 0;
  border: 0 !important;
  &:hover {
    color: rgba(236, 102, 0, 1);
  }
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border: 0;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border: 0;
  box-shadow: 0 0 0 0;
  background-color: #fff;
  color: rgba(236, 102, 0, 1);
}

/deep/ .el-checkbox-button {
  margin-right: 30px;
  color: rgba(102, 102, 102, 1);
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
}
/deep/ .el-checkbox-button__inner {
  padding: 0;
  border: 0;
}
/deep/ .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 0;
}
/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  border: 0;
  box-shadow: 0 0 0 0;
  background-color: #fff;
  color: rgba(236, 102, 0, 1);
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: rgba(236, 102, 0, 1);
  border-color: rgba(236, 102, 0, 1);
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(236, 102, 0, 1);
}

/deep/ .el-select-dropdown.el-popper {
  z-index: 9999 !important;
}
</style>