<template>
  <div class="plant-details">
    <!-- <div id="details"> -->
    <!--详情头部-->
    <DetailsHeader />
    <!--详情搜索-->
    <DetailsSearch
      v-if="Object.keys(details).length !== 0"
      :details="details"
    />
    <!--详情主内容头部-->
    <div class="header-content">
      <!-- <p class="title3 m-b-10">
        亿楼厂房 > 深圳厂房出租 > 宝安厂房出租 > 福永厂房出租
      </p> -->
      <main>
        <div
          class="header-content-left"
          v-if="Object.keys(details).length !== 0"
        >
          <DetailsCarousel :details="details" />
        </div>
        <div
          class="header-content-right"
          v-if="Object.keys(details).length !== 0"
        >
          <DetailsHeaderInfo
            :details="details"
            @toPlantRentalInPark="toPlantRentalInPark"
          />
        </div>
      </main>
    </div>
    <!-- 房源列表 -->
    <div class="info-main" v-show="plantList.length > 0">
      <div class="info-main-left">
        <div class="title">
          <span>在租房源</span>
          <span class="more" @click="toPlantRentalInPark">查看全部</span>
        </div>
        <div class="info-main-list" v-loading="loading">
          <HouseListItem :plantList="plantList" />
        </div>
      </div>
      <div class="info-main-right">
        <img src="../../assets/xcx-code.png" alt="" />
        <p>扫码打开小程序随时关注最新房源</p>
      </div>
    </div>
    <!-- footer -->
    <Footer />
    <!-- 侧边悬浮 -->
    <Sidebar />
    <!-- </div> -->
  </div>
</template>
<script>
import { buildsDetail, searchPage } from 'services/office'
import Footer from 'components/home/footer.vue';
import Sidebar from 'components/home/sidebar.vue';
import DetailsHeader from 'components/office/DetailsHeader.vue';
import DetailsSearch from 'components/gardenArea/DetailsSearch.vue';
import DetailsCarousel from 'components/office/DetailsCarousel.vue';
import DetailsHeaderInfo from 'components/gardenArea/DetailsHeaderInfo.vue';
import HouseListItem from 'components/gardenArea/HouseListItem.vue';
export default {
  name: 'PlantDetails',
  components: {
    DetailsHeader,
    DetailsSearch,
    DetailsCarousel,
    DetailsHeaderInfo,
    HouseListItem,
    Sidebar,
    Footer
  },
  data () {
    return {
      infoActive: '基本信息',
      details: {},
      plantList: [],
      loading: false
    }
  },
  mounted () {
    // 判断token是否失效
    console.log('ticket--->', sessionStorage.ticket)
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
            clearTimeout(timer)
          }, 500)
      }
    })


    // 判断厂房是否存在模型、全景
    let vm = this
    let detailId = this.$route.params.detailId
    fetch(window.modelDataIp + "/modelData.json").then(function (response) {
      return response.json();
    }).then(data => {
      vm.$store.commit('setModelData', data)
      vm.getDetails(detailId)
    })


    let nav = document.querySelector('.info-main-right')
    let details = document.querySelector('.info-main')

    window.addEventListener('scroll', () => {
      // 客服悬浮
      if (window.pageYOffset > details.offsetTop) {
        nav.style.position = 'fixed'
        nav.style.top = '100px'
        nav.style.right = 'calc(50% - 600px)'
        nav.style['z-index'] = '100'
      } else {
        nav.style.position = 'static'
      }
    })

  },
  methods: {
    getDetails (id) {
      buildsDetail({ id }).then(res => {
        if (res.data.status === 200) {
          this.details = res.data.data
          localStorage.HOUSE_DETAILS = JSON.stringify(res.data.data)
          this.getList()
        }
      })
    },

    getList () {
      let cityId = JSON.parse(localStorage.city).cityId
      let params = {
        cityId,
        buildingId: this.details.id,
        page: 1,
      }
      console.log(params);
      this.loading = true
      searchPage(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data.map(item => {
            return {
              ...item,
              coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
            }
          })
        }
        this.loading = false
      })
    },
    toPlantRentalInPark () {
      this.$router.push({
        path: `/plantRentalInPark/${this.details.name}/p${this.details.id}`,
      })
    }
  }
};
</script>
<style lang="less" scoped>
.plant-details {
  // overflow: auto;
  .header-content {
    padding: 20px calc(50% - 600px) 50px;
    background-color: #fff;
    main {
      display: flex;
      // align-items: end;
      justify-content: space-between;
      width: 100%;
    }
    &-left {
      width: 60%;
      margin-right: 60px;
    }
    &-right {
      width: calc(40% - 60px);
    }
  }
  .info-main {
    padding: 20px calc(50% - 600px) 0px;
    background-color: #fff;
    display: flex;

    &-left {
      width: calc(100% - 348px);
      .title {
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 14px;
        span {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 31.86px;
          color: rgba(51, 51, 51, 1);
        }
        .more {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(153, 153, 153, 1);
          cursor: pointer;
        }
      }
    }
    &-right {
      width: 168px;
      margin-left: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 12px;
        width: 100%;
      }
      p {
        width: 112px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
}
</style>
  