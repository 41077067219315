import axios from '../plugins/office';

import qs from 'qs';

// form 表单传参
// eslint-disable-next-line no-unused-vars
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data, { arrayFormat: 'repeat' });
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};
// 获取搜索参数
const searchLeaseLabel = () => axios.get('/search/lease/param');

// 分页搜索楼盘
const searchBuildsPage = params =>
  axios.get('/search/building/page', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 联想搜索楼盘
const searchBuildsQuick = params =>
  axios.get('/search/building/quick', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 楼盘搜索参数
const searchBuildsParam = () => axios.get('/search/building/param');

// 楼盘详情
const buildsDetail = params =>
  axios.get('/building/detail', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 楼盘推荐
const buildsRecommend = params =>
  axios.get('/building/recommend', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 首页搜索标签
const officeSearchLabelHome = params =>
  axios.get('/search/label/home', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 查询房源详情
const leaseDetail = params =>
  axios.get('/lease/detail', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 分页搜索房源
const searchPage = params =>
  axios.get('/search/lease/page', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 查询经纪人房源
const leaseBrokerPage = params =>
  axios.get('/lease/broker/page', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 按矩形范围搜索厂房（上图）
const searchRect = params =>
  axios.get('/search/building/rect', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 获取推荐房源
const leaseRecommend = params =>
  axios.get('/lease/recommend', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 举报房源
const reportBuilding = async data =>
  axios.post('/property/report/submit', data, form);

export {
  searchLeaseLabel,
  searchBuildsPage,
  searchBuildsQuick,
  searchBuildsParam,
  buildsDetail,
  buildsRecommend,
  officeSearchLabelHome,
  leaseDetail,
  searchPage,
  leaseBrokerPage,
  searchRect,
  reportBuilding,
  leaseRecommend
};
