<template>
  <div class="nav">
    <div
      class="nav-item"
      :class="navIndex === item.id ? 'active' : ''"
      v-for="item in nav"
      :key="item.id"
      @click="toPushNav(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
    <script>
export default {
  name: 'Nav',
  data () {
    return {
      navIndex: 1,
      nav: [
        { id: 1, url: '/office', name: '写字楼' },
        { id: 2, url: '', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' },
        // { id: 6, url: '/my', name: '我的' },
      ]
    }
  },
  methods: {
    toPushNav (item) {
      if (item.id === 1) {
        this.$router.push({
          path: item.url
        })
      }

      if (item.id === 2) {
        window.open(`${window.plantUrl}plant`, '_blank');
      }
    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    }
  }
};
    </script>
    
    <style lang="less" scoped>
.nav {
  display: flex;
  align-items: center;
  &-item {
    margin-right: 20px;
    font-size: 14px;
    line-height: 40px;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    &.active {
      color: #000;
    }
  }
}
</style>
    