<template>
  <div class="collection-housing">
    <div class="title">
      共<span>{{ total || 0 }}</span
      >个 收藏的房源
    </div>
    <div class="tab">
      <div
        class="tab-item"
        v-for="item in tabList"
        :key="item.id"
        :class="type === item.id ? 'active' : ''"
        @click="type = item.id"
      >
        {{ item.text }}
      </div>
    </div>
    <main class="main">
      <div class="main-list">
        <HouseListItem :plantList="plantList" />
      </div>
      <div class="main-page" v-show="total > 5">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
  </div>
</template>
<script>
import HouseListItem from 'components/user/HouseListItem.vue';
import { collectHousePage } from 'services/member'
export default {
  name: 'CollectionHousing',
  components: {
    HouseListItem,
  },
  data () {
    return {
      tabList: [
        { id: 1, text: '厂房出租' },
        // { id: 2, text: '厂房出售' },
        // { id: 3, text: '写字楼出租' },
        // { id: 4, text: '写字楼出售' },
        // { id: 5, text: '仓库出租' },
        // { id: 6, text: '仓库出售' },
        // { id: 7, text: '商铺出租' },
        // { id: 8, text: '商铺出售' },
        // { id: 9, text: '土地出租' },
        // { id: 10, text: '土地出售' },
      ],
      type: 1,
      navIndex: 1,
      page: 1,
      total: 0,
      plantList: [],
    }
  },
  mounted () {
    this.getBrokerHouse()
  },
  methods: {
    getBrokerHouse () {
      let params = {
        page: this.page,
        type: this.type
      };
      collectHousePage(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
  }
};
</script>

<style lang="less" scoped>
.collection-housing {
  height: 100%;
  padding: 40px 36px 24px 24px;
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(51, 51, 51, 1);
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      color: rgba(236, 102, 0, 1);
    }
  }
  .tab {
    height: 40px;
    background: rgba(246, 245, 244, 1);
    border: 1px solid rgba(246, 245, 244, 1);
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 16px;
    .tab-item {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 1);
        color: rgba(236, 102, 0, 1);
        border-top: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
  .main {
    height: calc(100% - 100px);
    &-list {
      overflow: auto;
      height: calc(100% - 60px);
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: rgba(236, 102, 0, 1);
      }
    }
  }
}
</style>