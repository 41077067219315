<template>
  <div class="details-search">
    <div class="logo-search">
      <div class="logo" @click="$router.push('/office')">
        <img src="../../assets/office/details/logo.png" alt="亿楼" />
        <div class="tab">
          <div
            class="tab-item"
            v-for="item in headerTabList"
            :key="item.id"
            :class="headTabIndex === item.id ? 'active' : ''"
            @click.stop="tabItem(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="search" v-if="search">
        <el-input
          class="inp"
          placeholder="请输入关键字进行搜索楼盘"
          v-model="keyword"
          @keyup.enter.native="toRental"
        >
          <i
            class="icon icon-plant-details-search"
            style="cursor: pointer"
            slot="suffix"
            @click="toRental"
          >
          </i>
        </el-input>
      </div>
    </div>
    <div class="content" v-if="content">
      <div class="content-title">
        <p class="title1">{{ details.name }}</p>
        <p class="title2">发布于{{ details.time | timeFilter }}</p>
      </div>
      <div class="content-btn">
        <!-- <button class="btn" @click="appointmentTime">预约看房</button> -->
        <DatetimePicker v-if="pickerVisible" @close="pickerVisible = false" />
        <!-- <button
          class="btn"
          :class="collected ? 'active' : ''"
          @click="collectHouse"
        >
          {{ !collected ? '收藏楼盘' : '已收藏' }}
        </button> -->
      </div>
    </div>
  </div>
</template>
  <script>
import DatetimePicker from 'components/other/DatetimePicker.vue'
import { collect, cancelCollect } from 'services/member'
// import Location from '../home/location.vue';
export default {
  name: 'DetailsSearch',
  components: {
    // Location,
    DatetimePicker
  },
  props: {
    logoBtn: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    content: {
      type: Boolean,
      default: true
    },
    details: Object
  },
  data () {
    return {
      styleType: 'black',
      keyword: '',
      collected: false,
      pickerVisible: false,
      type: 1,
      headerTabList: [
        { id: 1, text: '租写字楼' },
        // { id: 2, text: '买写字楼' },
        { id: 2, text: '找楼盘' },
        { id: 3, text: '地图找房' },
      ],
      headTabIndex: null,
    }
  },
  filters: {
    timeFilter (val) {
      if (val) {
        let now = new Date(val)
        let y = now.getFullYear()
        let m = now.getMonth() + 1
        let d = now.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
        //  + " " + now.toTimeString().substr(0, 8)
      } else {
        return '无数据'
      }
    }
  },
  mounted () {
    if (this.details)
      this.collected = this.details.collected
    window.addEventListener('scroll', () => {
      this.pickerVisible = false
    })
  },
  methods: {
    collectHouse () {
      let params = {
        type: this.type,
        houseId: this.details.id
      }
      if (this.collected) {
        cancelCollect(params).then(res => {
          if (res.data.status === 200) {
            this.collected = false
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      } else {
        collect(params).then(res => {
          if (res.data.status === 200) {
            this.collected = true
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }
      this.$parent.getDetails(params.houseId)
    },
    toRental () {
      this.$router.push({
        path: `/searchGardenArea/kw${this.keyword}/dr`,
        // params: {
        //   keyword: this.keyword
        // }
      })
    },
    tabItem (val) {
      this.headTabIndex = val.id
      if (val.id === 1) {
        this.$router.push({
          path: `/plantRental/cs/kw/dr/pr/s`,
        })
      }
      if (val.id === 2) {
        this.$router.push({
          path: `/searchGardenArea/kw/dr`,
        })
      }
      if (val.id === 3) {
        // this.$router.push({
        //   name: 'mapSearch'
        // })
        let routeUrl = this.$router.resolve({
          path: '/mapSearch',
        })
        window.open(routeUrl.href, '_blank');
      }
    },
    appointmentTime () {
      this.pickerVisible = true
    },
    toPublishHousing () {
      window.open(window.guwen + '/#/login?client=plant', '_blank');
    }
  }
};
  </script>
  
  <style lang="less" scoped>
.details-search {
  height: 180px;
  background: rgba(246, 245, 244, 1);
  padding: 0 calc(50% - 600px);
  padding-top: 30px;
  .logo-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .logo {
      display: flex;
      align-items: center;
      img {
        width: 114px;
        height: 34px;
        margin-right: 16px;
        cursor: pointer;
      }
      &-btn {
        width: 96px;
        height: 32px;
        border-radius: 4px;
        margin-left: 30px;
        font-size: 16px;
        line-height: 32px;
        color: rgba(236, 102, 0, 1);
        border: 1px solid rgba(236, 102, 0, 1);
      }

      .tab {
        display: flex;
        align-items: center;
        &-item {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          margin-left: 40px;
          cursor: pointer;
          &.active {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
    .search {
      .inp {
        width: 320px;
        height: 40px;
        /deep/ .el-input__inner {
          background: rgba(237, 235, 232, 1);
          border: 0;
        }
        /deep/ .el-input__icon {
          color: #ec6600;
        }
        /deep/ .el-input__suffix {
          line-height: 40px;
          right: 15px;
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    &-title {
      p.title1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 6px;
      }
      p.title2 {
        line-height: 20px;
        color: rgba(153, 153, 153, 1);
      }
    }
    &-btn {
      display: flex;
      // align-items: center;
      position: relative;
      .btn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(84, 34, 0, 0.06);
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
        &.active {
          background: #ec6600;
          border-color: #ec6600;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
  