<template>
  <div class="tab-model">
    <div class="tab" v-if="index != 0">
      <div
        class="tab-item"
        :class="index == item.id ? 'active' : ''"
        v-for="item in tab"
        :key="item.id"
        @click="setTab(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <router-view id="tab-model" />
  </div>
</template>
  
  <script>
export default {
  name: 'Tab',

  data () {
    return {
      tab: [
        { id: 1, name: '园区三维', path: '/model' },
        { id: 2, name: '室内三维', path: '/indoorModel' },
        { id: 3, name: '室内全景', path: '/vr' },
      ],
      index: 1,
      detailsId: null,
      modelData: {},
      url3: null,
    }
  },
  mounted () {
    let _this = this
    console.log('index', this.$route.params);
    this.index = this.$route.params.index
    this.detailsId = this.$route.params.detailsId

    fetch(window.modelDataIp + "/modelData.json").then(function (response) {
      return response.json();
    }).then(data => {
      console.log('-------------', data)
      _this.modelData = data
      _this.$store.commit('setModelData', data)
      // 判断id
      _this.v_data = _this.modelData.data[this.detailsId]
      if (_this.v_data) {
        let { url1, panoramaUrl, url3 } = _this.v_data
        if (!url1) _this.tab = _this.tab.filter(item => item.id !== 1)
        if (!panoramaUrl) _this.tab = _this.tab.filter(item => item.id !== 3)
        if (!url3) _this.tab = _this.tab.filter(item => item.id !== 2)
      } else {
        _this.tab = []
      }
    })
  },
  computed: {},
  methods: {
    setTab (item) {
      if (item.id !== this.index) {
        this.index = item.id
        // if (item.id == 1) this.toModel()
        // if (item.id == 2) this.toInroodModel()
        // if (item.id == 3) this.toVR()
        this.$router.push({
          path: window.model + item.path + '/' + item.id + "/" + this.detailsId
        })
      }
    },

    toVR () {
      let routeUrl = this.$router.resolve({
        path: '/vr',
      })
      window.open(routeUrl.href + '/3', '_blank');
    },
    toModel () {
      let routeUrl = this.$router.resolve({
        path: '/model',
      })
      window.open(routeUrl.href + '/1', '_blank');
    },
    toInroodModel () {
      let routeUrl = this.$router.resolve({
        path: '/indoorModel',
      })
      window.open(routeUrl.href + '/2', '_blank');
    },
  }
};
  </script>
  
  <style lang="less" scoped>
.tab-model {
  width: 100vw;
  height: 100vh;
  min-width: initial !important;
  overflow: hidden;
  position: relative;
  #tab-model {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-device-width: 768px) {
  .tab {
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    &-item {
      width: 120px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      border-radius: 6px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      background: rgba(51, 51, 51, 0.5);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: linear-gradient(
          180deg,
          rgba(255, 176, 41, 0.9) 0.69%,
          rgba(217, 94, 0, 1) 100%
        );
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
@media only screen and (max-device-width: 768px) {
  .tab {
    display: none;
    // display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    &-item {
      width: 80px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      background: rgba(51, 51, 51, 0.5);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: linear-gradient(
          180deg,
          rgba(255, 176, 41, 0.9) 0.69%,
          rgba(217, 94, 0, 1) 100%
        );
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
  