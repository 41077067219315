'use strict';

import Vue from 'vue';
import axios from 'axios';

let config = {
  baseURL: window.pay,
  timeout: 60 * 1000 // Timeout
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    if (config.params) {
      for (let key in config.params) {
        console.log(key);
        let value = config.params[key];
        if (!value && (typeof value == 'object' || typeof value == 'string'))
          delete config.params[key];
      }
    }
    config.headers.Authorization = 'Bearer ' + localStorage.token;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    console.log(response);
    if (response.status === 200) {
      const status = response.data.status;
      if (status !== 200) {
        let message = response.data.msg;
        // 重新登录
        if (status === 406) {
          message = '登录过期，需要重新登陆，2秒后跳转到登录界面！';
          // 清空token
          localStorage.token = '';
          window.location.href =
            window.loginIp + '/#/login?from=' + window.location.hash + '&jl';
        }
        if (status === 407) {
          message = '临时token失效';
        }
        // 请求状态吗不是200，展示错误信息
        Vue.prototype.$message({
          type: 'warning',
          message
        });
      }
    }
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
