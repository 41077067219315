<template>
  <div class="details-header-info">
    <div class="count-label">
      <div class="code">
        <div class="">
          <div class="count">
            <span class="num" v-if="!details.minPrice && !details.maxPrice">
              暂无数据
            </span>
            <span class="num" v-else>
              <span
                class="price-unit f-s-28 f-w-600"
                v-html="
                  details.minPrice !== details.maxPrice
                    ? `${details.minPrice}-${details.maxPrice}`
                    : details.minPrice
                "
              >
              </span>

              <span class="price-unit f-s-26">元/㎡/月</span>
              <span
                style="
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(153, 153, 153, 1);
                "
                >参考价</span
              >
            </span>
            <!-- <span class="report" @click="openReport">
              <i class="icon icon-plant-details-report"></i>
              信息纠错
            </span> -->
          </div>
        </div>
      </div>

      <!-- <div class="label-info">
        <div class="">
          <div
            class="text1"
            v-if="!details.minLeaseArea && !details.maxLeaseArea"
          >
            暂无数据
          </div>
          <div
            class="text1"
            v-else-if="details.minLeaseArea === details.maxLeaseArea"
          >
            {{ details.minLeaseArea || '暂无数据' }}m²
          </div>
          <div class="text1" v-else>
            {{ details.minLeaseArea || '暂无数据' }}-{{
              details.maxLeaseArea || '暂无数据'
            }}m²
          </div>
          <div class="text2">可租面积</div>
        </div>
        <div class="" @click="$emit('toPlantRentalInPark')">
          <div class="text1">
            {{
              !details.leaseOnlineNumber && details.leaseOnlineNumber != 0
                ? '暂无数据'
                : details.leaseOnlineNumber
            }}
          </div>
          <div class="text2">可租房源</div>
        </div>
      </div> -->

      <div class="info">
        <p>
          <label for="">可租房源：</label>
          <span class="underline" @click="$emit('toPlantRentalInPark')">{{
            !details.leaseOnlineNumber && details.leaseOnlineNumber != 0
              ? '暂无数据'
              : details.leaseOnlineNumber
          }}</span>
        </p>
        <p>
          <label for="">可租面积：</label>
          <span
            class="text1"
            v-if="!details.minLeaseArea && !details.maxLeaseArea"
          >
            暂无数据
          </span>
          <span
            class="text1"
            v-else-if="details.minLeaseArea === details.maxLeaseArea"
          >
            {{ details.minLeaseArea || '暂无数据' }}m²
          </span>
          <span class="text1" v-else>
            {{ details.minLeaseArea || '暂无数据' }}-{{
              details.maxLeaseArea || '暂无数据'
            }}m²
          </span>
        </p>
      </div>
    </div>
    <div class="info">
      <p>
        <label for="">楼盘编号：</label>
        <span>{{ details.id || '暂无数据' }}</span>
      </p>
      <p>
        <label for="">楼盘区域：</label>
        <span>{{ details.district }}-{{ details.street }}</span>
      </p>
      <p>
        <label for="">物业公司：</label>
        <span>{{ details.propertyCompany || '暂无数据' }}</span>
      </p>
      <p>
        <label for="">楼盘地址：</label>
        <span>{{ details.address || '暂无数据' }}</span>
      </p>
    </div>
    <div class="img">
      <img src="../../assets/xcx-code.png" alt="" />
      <p>扫码打开小程序</p>
    </div>
    <!-- 楼盘纠错 -->
    <el-dialog
      title="楼盘纠错"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <div class="body">
        <p>一. 请选择举报原因 <span class="requir-field">*</span></p>
        <el-radio-group class="radio-group" v-model="reason">
          <el-radio class="radio-item" label="虚假楼盘">虚假楼盘</el-radio>
          <el-radio class="radio-item" label="楼盘信息有误"
            >楼盘信息有误</el-radio
          >
          <el-radio class="radio-item" label="楼盘位置有误"
            >楼盘位置有误</el-radio
          >
        </el-radio-group>
        <p>二. 详细说明</p>
        <el-input
          class="textarea"
          type="textarea"
          :rows="2"
          placeholder="您可提供更详细的说明！"
          v-model="detail"
        >
        </el-input>
        <p>三. 图片证据</p>
        <el-upload
          class="upload-demo"
          drag
          :action="uploadIp + '/file/common/upload/img/1'"
          :before-upload="beforeUpload"
          :on-change="handleCertifiedPreview"
          :on-remove="handleCertifiedRemove"
          multiple
        >
          <div class="plus">
            <i class="el-icon-plus"></i>
          </div>
          <div class="el-upload__text">支持JPG，PNG</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
const uploadIp = window.uploadIp
const los = window.los
import { reportBuilding } from 'services/factory'
import { getOpenUser } from 'services/broker'
import { isLogin } from 'services/login';
export default {
  name: 'DetailsHeaderInfo',
  data () {
    return {
      uploadIp,
      los,
      value1: null,
      dialogVisible: false,
      detail: '',
      reason: '虚假房源',
      type: 1,
      image: [],
      user: {}
    }
  },
  props: {
    details: Object
  },
  mounted () {
    // this.getUser()
  },
  methods: {
    openReport () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          if (res.data.data) {
            this.dialogVisible = true
          } else {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
          }
        }
      })
    },
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    toPush (path) {
      this.$router.push({
        path: path + '/' + this.details.id,
      })
    },
    beforeUpload (file) {
      let imgSize = Number(file.size / 1024 / 1024);
      console.log(imgSize);
      if (imgSize > 5) {
        this.$message({
          message: '文件不超过5MB',
          type: 'warning'
        });
        return false
      } else {
        return true
      }
    },
    handleCertifiedRemove (file, fileList) {
      this.image = fileList.filter(item => item?.response.status === 200).map(item => item.response.data.fileUrl);
    },
    handleCertifiedPreview (file, fileList) {
      this.image = fileList.filter(item => item.status === 'success').filter(item => item?.response.status === 200).map(item => item.response.data.fileUrl)
      console.log(this.image)
    },
    submit () {
      let params = {
        buildingId: this.details.id,
        reason: this.reason,
        detail: this.detail,
        type: this.type,
        image: this.image
      }
      reportBuilding(params).then(res => {
        if (res.data.status === 200) {
          this.dialogVisible = false
          this.reason = '虚假房源'
          this.detail = ''
          this.image = []
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
        }
      })
    },
  }
};
  </script>
  
  <style lang="less" scoped>
.details-header-info {
  width: 100%;
  height: 100%;
  .count-label {
    border-bottom: 1px solid #f2f2f2;
    .code {
    }
    .count {
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-size: 32px;
        font-weight: 600;
        line-height: 85px;
        color: rgba(236, 102, 0, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 30px;
      }
      .report {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        i {
          margin-right: 4px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .label {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 37px;
      &-item {
        padding: 3px 10px;
        border-radius: 2px;
        background: #f6f5f4;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin-right: 12px;
      }
    }
    .label-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .text1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
      .text2 {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(136, 136, 136, 1);
        text-align: center;
      }
    }
  }
  .info {
    padding: 0px 0 18px;
    p {
      padding: 7px 0;
      label {
        font-size: 16px;
        line-height: 22px;
        color: rgba(136, 136, 136, 1);
        width: 80px;
        display: inline-block;
      }
      span {
        font-size: 16px;
        line-height: 22px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .img {
    width: 164px;
    height: 164px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 14.07px;
      color: rgba(136, 136, 136, 1);
    }
  }
}

// 弹窗
.body {
  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 8px;
    .requir-field {
      margin-left: 4px;
      color: rgba(212, 48, 48, 1);
    }
  }
  .radio-group {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    .radio-item {
      width: 33%;
      margin-bottom: 4px;
      margin-right: 0;
      /deep/.el-radio__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
      /deep/ .el-radio__inner {
        border: 1px solid rgba(236, 102, 0, 1);
        background: rgba(255, 255, 255, 1);
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner::after {
        background-color: rgba(236, 102, 0, 1);
        width: 5px;
        height: 5px;
      }
    }
  }
  .textarea {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    /deep/ .el-textarea__inner {
      height: 88px;
      padding: 12px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }
  .upload-demo {
    padding-left: 20px;
    box-sizing: border-box;
    .plus {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
      border-radius: 13px;
      margin: 10px auto;
      i {
        font-size: 16px;
        margin: 0;
      }
    }
    .el-upload__text {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: rgba(153, 153, 153, 1);
    }
    /deep/ .el-upload-dragger {
      width: 124px;
      height: 76px;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);

      border: 1px solid rgba(247, 247, 247, 1);
    }
  }
}
.dialog-footer {
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}

/deep/ .el-icon-star-on {
  color: #ec6600 !important;
}
/deep/ .el-icon-star-off {
  color: #999999 !important;
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  /deep/ .el-button {
    display: flex;
    align-items: center;
  }
}
</style>
  