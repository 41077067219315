<template>
  <div class="vr">
    <div id="vr"></div>
    <div class="list" v-show="description.children.length > 1">
      <div
        class="scrollSlider"
        v-show="description.children.length > 6"
        ref="scrollBox"
      >
        <div class="contentSlider" ref="scrollBar"></div>
      </div>
      <i
        class="el-icon-arrow-left"
        v-show="description.children.length > 8"
        @click="left"
      ></i>
      <main ref="container">
        <div
          class="list-box"
          :style="{ transform: `translateX(${length}px)` }"
          @scroll="scrollCon"
        >
          <div
            class="list-item"
            v-for="(item, index) in description.children"
            :key="index"
            @click="changeSkyBox(index)"
          >
            <div class="item" :class="currentIndex === index ? 'active' : ''">
              {{ description.children[index].name }}
            </div>
          </div>
        </div>
      </main>
      <i
        class="el-icon-arrow-right"
        v-show="description.children.length > 8"
        @click="right"
      ></i>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from 'axios';
// const modelData = window.modelData
export default {
  name: 'vr',
  data () {
    return {
      modelData: {},
      v_data: {},
      description: {
        children: []
      },
      skyBox: '',
      currentIndex: 0,
      count: 0,
      length: null,
      url: '',
      detailsId: '',
      vrIndex: null,
    }
  },
  mounted () {
    // 移动端左右滑动
    const container = this.$refs.container; // 获取容器元素
    let isDown = false, // 是否按下鼠标
      startX, // 鼠标起始位置
      scrollLeft; // 滚动条位置

    container.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
      scrollWidth = container.scrollWidth
      clientWidth = container.clientWidth
    });


    container.addEventListener('touchstart', (e) => {
      isDown = true;
      startX = e.changedTouches[0].pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener('mouseup', () => {
      isDown = false;
    });
    container.addEventListener('touchend', () => {
      isDown = false;
    });

    container.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2;
      container.scrollLeft = scrollLeft - walk;
    });
    container.addEventListener('touchmove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.changedTouches[0].pageX - container.offsetLeft;
      const walk = (x - startX) * 2;
      container.scrollLeft = scrollLeft - walk;
      // 计算滑动的比例
      var scale = (container.scrollWidth - container.clientWidth) / (this.$refs.scrollBox.clientWidth - this.$refs.scrollBar.clientWidth)
      //滚动条滑动的距离
      this.$refs.scrollBar.style.marginLeft = (container.scrollLeft / scale) + 'px'
    });

    let _this = this
    console.log(this.$route.params);
    this.detailsId = this.$route.params.detailsId
    // 室外点击进入的全景下标
    this.vrIndex = this.$route.params.vrIndex
    fetch(window.modelDataIp + "/modelData.json").then(function (response) {
      return response.json();
    }).then(data => {
      console.log(data)
      _this.modelData = data
      var ip = window.page + _this.modelData.ip;
      // 判断id - 1314658588555370496
      _this.v_data = _this.modelData.data[this.detailsId] ? _this.modelData.data[_this.detailsId] : _this.modelData.data['1314658588555370496']
      _this.scene = new PScene("vr");
      //视角的角度
      _this.scene.maxVerticalAngle = 15;
      document.body.addEventListener('touchmove', function (e) {
        e.preventDefault();
      }, { passive: false });
      SkyBox.prototype.width = 2048;
      SkyBox.prototype.height = 2048;

      //图层，添加图标
      _this.layer = new Layer();
      _this.scene.addLayer(_this.layer);
      //请求索引文件
      fetch(ip + '/' + _this.v_data.publicVr[_this.vrIndex].url + "description.json").then(function (response) {
        return response.json();
      }).then(data => {
        console.log('-----------', data)
        _this.description = data;
        _this.changeSkyBox(0)
        _this.currentIndex = 0
      })
      _this.scene.addEventListener("click", function (e) {
        console.log(e.features[0])
        var feature = e.features[0];
        if (feature) {
          var index = feature._index;
          //切换
          _this.changeSkyBox(index);
        }
      })
    })
  },
  methods: {
    changeSkyBox (index) {
      var ip = window.page + this.modelData.ip;
      var v_data = this.v_data
      this.currentIndex = index
      let vm = this
      //路径拼接,获取地址
      console.log('====', v_data.publicVr, this.description)
      var url = ip + v_data.publicVr[this.vrIndex].url + this.description.address + this.description.children[index].url;
      if (this.skyBox) {
        this.skyBox.changUrl(url, () => {
          vm.scene.setHorizontalAngle(this.description.children[index].angle);
        }, 1);
        //设置角度
        this.scene.setHorizontalAngle(this.description.children[index].angle);
      } else {
        //创建
        console.log(url)
        this.skyBox = new SkyBox(url);
        this.scene.setPanorama(this.skyBox);
        // //添加地面图标
        // skyBox.setBottomMaker();
        //设置角度
        this.scene.setHorizontalAngle(this.description.children[index].angle);
      }
      //清空图层
      this.layer.empty();
      // 存在切换图标
      if (this.description.children[index].next) {
        this.description.children[index].next.forEach(element => {
          var maker = new TrendsMaker({
            url: "img/maker.png",//图标路径
            position: new THREE.Vector3().copy(element.position),//点位置
            verticalFrame: 25,//纵向数字
            interval: 50,//动画间隔
            horizontalFrame: 1  //横向间隔
          });
          // maker._name="下一个场景名称";
          maker._index = element.index;
          this.layer.addFeature(maker);
          //存在下一个场景的名称
          if (element.name) {
            var maker1 = new MarkSprite({
              // text:"4m",
              url: getTextTexture(element.name, {
                //字体大小
                fontSize: 32,
                //字体类型
                fontFamily: "Arial",
                //文字颜色
                fontColor: 'rgba(255,255,255,1.0)',
                //背景色
                backgroundColor: "rgba(51, 51, 51, 0.6)",
                //边距离
                padding: [10, 20, 10, 20],
                //圆角
                radius: 5,

              }),
              position: new THREE.Vector3().copy(element.position),

              scale: 0.5
            });
            //添加
            layer.addFeature(maker1)
            //设置锚点
            maker1.setCenter(-0.5, 1)
          }
        });
      }

    },

    left () {
      if (this.description.children.length > 0) {
        this.count -= 1;
        if (this.count < 0) {
          this.count = this.description.children.length - 8;
          this.length = -this.count * 130
        } else {
          this.length = -this.count * 130
        }
      }
    },
    right () {
      if (this.description.children.length > 0) {
        if (this.count < this.description.children.length - 8) {
          this.count += 1;
          this.length = -this.count * 130
        } else {
          this.count = 0
          this.length = 0
        }
      }
    },
    scrollCon (e) {
      console.log('scrollCon', e);
    }
  }
};
</script>

<style lang="less" scoped>
.vr {
  width: 100vw;
  height: 100vh;
  min-width: initial !important;
  overflow: hidden;
}
#vr {
  width: 100%;
  height: 100%;
}

@media only screen and (min-device-width: 768px) {
  .list {
    position: absolute;
    bottom: 100px;
    left: 50%;
    z-index: 100;
    // width: 520px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%);
    .scrollSlider {
      display: none;
    }
    i {
      width: 42px;
      height: 42px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(51, 51, 51, 0.5);
      display: inline-block;
      text-align: center;
      line-height: 42px;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      &:last-child {
        margin-left: 10px;
      }
    }
    main {
      max-width: 1040px;
      // max-width: 170px;
      height: 42px;
      overflow: hidden;
    }

    .list-box {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      height: 46px;
    }
    .item {
      display: inline-block;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      height: 42px;
      width: 120px;
      padding: 0 12px;
      margin-left: 10px;
      border-radius: 6px;
      background: rgba(51, 51, 51, 0.5);
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.active {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(92, 92, 92, 0.6) 100%
        );
        color: #fff;
      }
    }
  }
}
@media only screen and (max-device-width: 768px) {
  .list {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    max-width: 96%;
    // right: 6px;
    bottom: 10px;
    z-index: 100;
    height: 64px;
    padding: 0 8px;
    overflow: hidden;
    transition: height 0.1s linear 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      display: none;
    }

    .scrollSlider {
      position: absolute;
      top: -20px;
      margin: 0 auto;
      margin-top: 20px;
      height: 2.5px;
      width: 36px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      .contentSlider {
        width: 18px;
        height: 2.5px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(247, 117, 17, 1);
      }
    }
    main {
      width: 100%;
      overflow-x: hidden;
    }
    .list-box {
      display: flex;
      align-items: center;
      // justify-content: center;
      white-space: nowrap;
      transition: all 0.3s ease;
    }
    .list-item {
      display: flex;
      align-items: center;

      &:last-child {
        .item {
          margin-right: 0;
        }
      }
    }
    .item {
      width: 64px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      opacity: 1;
      margin-right: 4px;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(31, 31, 31, 0.8) 0%,
        rgba(117, 117, 117, 0.8) 99.31%
      );

      border: 0.6px solid rgba(230, 202, 181, 1);
      box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.5);
      font-size: 13px;
      color: rgba(255, 255, 255, 0.7);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.active {
        color: #fff;
        border-radius: 8px;
        background: linear-gradient(
          132.84deg,
          rgba(237, 103, 0, 1) 0%,
          rgba(255, 209, 140, 1) 100%
        );

        border: 1px solid rgba(255, 238, 222, 1);
        font-size: 14px;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>