<template>
  <div class="plant-rental">
    <!--详情头部-->
    <DetailsHeader />
    <!-- 搜索 -->
    <div class="logo-search">
      <div class="logo-tab">
        <div class="logo" @click="$router.push('/office')">
          <img src="../../assets/office/details/logo.png" alt="亿楼" />
          <!-- <Location styleType="black" /> -->
        </div>
        <div class="tab">
          <div
            class="tab-item"
            v-for="item in headerTabList"
            :key="item.id"
            :class="headTabIndex === item.id ? 'active' : ''"
            @click="tabItem(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="search">
        <el-input
          class="inp"
          placeholder="请输入关键字进行搜索"
          v-model="keyword"
          @keyup.enter.native="search"
        >
          <template slot="append">
            <div @click="search">找写字楼</div>
          </template>
        </el-input>
      </div>
    </div>
    <!--详情主内容头部-->
    <div class="plant-rental-header-content">
      <!-- <header>亿楼厂房 > 深圳厂房出租 > 宝安厂房出租 > 福永厂房出租</header> -->
      <main>
        <div class="option">
          <label class="label" for="">位置：</label>
          <div class="checkbox">
            {{ park.title }}
          </div>
        </div>

        <div class="option">
          <label class="label" for="">面积：</label>
          <div
            class="option-null"
            :class="areaLabelIds.length > 0 ? '' : 'active'"
            @click="
              ((areaLabelIds = []),
              (ruleForm.minArea = null),
              (ruleForm.maxArea = null)),
                search()
            "
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group
              v-model="areaLabelIds"
              @change="
                ((ruleForm.minArea = null), (ruleForm.maxArea = null)), search()
              "
            >
              <el-checkbox
                v-for="item in area"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
              <div class="min-max-inp">
                <el-input
                  class="min"
                  type="number"
                  v-model="ruleForm.minArea"
                  @input="e => NumberCheck(e, 'minArea')"
                  placeholder=""
                ></el-input>
                <span class="line">-</span>
                <el-input
                  class="max"
                  type="number"
                  v-model="ruleForm.maxArea"
                  @input="e => NumberCheck(e, 'maxArea')"
                  placeholder=""
                ></el-input>
                <span class="line">m²</span>
                <el-button class="btn" type="text" @click="setArea"
                  >确定</el-button
                >
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <div class="option">
          <label class="label" for="">工位数：</label>
          <div
            class="option-null"
            :class="workstationsIds.length > 0 ? '' : 'active'"
            @click="
              ((workstationsIds = []),
              (ruleForm.minWorkstations = null),
              (ruleForm.maxWorkstations = null)),
                search()
            "
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group
              v-model="workstationsIds"
              @change="
                ((ruleForm.minWorkstations = null),
                (ruleForm.maxWorkstations = null)),
                  search()
              "
            >
              <el-checkbox
                v-for="item in workstations"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
              <div class="min-max-inp">
                <el-input
                  class="min"
                  v-model="ruleForm.minWorkstations"
                  @input="e => NumberCheck(e, 'minWorkstations')"
                  placeholder=""
                ></el-input>
                <span class="line">-</span>
                <el-input
                  class="max"
                  v-model="ruleForm.maxWorkstations"
                  @input="e => NumberCheck(e, 'maxWorkstations')"
                  placeholder=""
                ></el-input>
                <span class="line">人</span>
                <el-button class="btn" type="text" @click="setWorkstations"
                  >确定</el-button
                >
              </div>
            </el-checkbox-group>
          </div>
        </div>

        <div class="option">
          <label class="label" for="">价格：</label>
          <div
            class="option-null"
            :class="priceLabelIds.length > 0 ? '' : 'active'"
            @click="
              ((priceLabelIds = []),
              (ruleForm.minPrice = null),
              (ruleForm.maxPrice = null)),
                search()
            "
          >
            不限
          </div>
          <div class="price">
            <div class="price-tab">
              <div
                class="price-tab-item"
                :class="priceTabIndex == 1 ? 'active' : ''"
                @click="setPriceTab(1)"
              >
                按单价（元/m²/月）
              </div>
              <div
                class="price-tab-item"
                :class="priceTabIndex == 2 ? 'active' : ''"
                @click="setPriceTab(2)"
              >
                按总价（万元/月）
              </div>
            </div>
            <div class="checkbox">
              <el-checkbox-group
                v-model="priceLabelIds"
                @change="
                  ((ruleForm.minPrice = null), (ruleForm.maxPrice = null)),
                    search()
                "
              >
                <el-checkbox
                  v-for="item in price"
                  :key="item.id"
                  :label="item.id"
                  >{{ item.name }}</el-checkbox
                >

                <div class="min-max-inp">
                  <el-input
                    class="min"
                    type="number"
                    v-model="ruleForm.minPrice"
                    placeholder=""
                    @input="e => NumberCheck(e, 'minPrice')"
                  ></el-input>
                  <span class="line">-</span>
                  <el-input
                    class="max"
                    type="number"
                    v-model="ruleForm.maxPrice"
                    placeholder=""
                    @input="e => NumberCheck(e, 'maxPrice')"
                  ></el-input>
                  <span class="line"
                    >{{ priceTabIndex == 1 ? '元/m²' : '万元' }}/月</span
                  >
                  <el-button class="btn" type="text" @click="setPrice"
                    >确定</el-button
                  >
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="option" v-show="drop">
          <label class="label" for="">楼层：</label>
          <div
            class="option-null"
            :class="floorIds.length > 0 ? '' : 'active'"
            @click="(floorIds = []), search()"
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="floorIds" @change="search">
              <el-checkbox
                v-for="item in floor"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="option" v-show="drop">
          <label class="label" for="">装修：</label>
          <div
            class="option-null"
            :class="decorationIds.length > 0 ? '' : 'active'"
            @click="(decorationIds = []), search()"
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="decorationIds" @change="search">
              <el-checkbox
                v-for="item in type"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="option" v-show="drop">
          <label class="label" for="">设施：</label>
          <div
            class="option-null"
            :class="facilitiesIds.length > 0 ? '' : 'active'"
            @click="(facilitiesIds = []), search()"
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="facilitiesIds" @change="search">
              <el-checkbox
                v-for="item in facility"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="option" v-show="drop">
          <label class="label" for="">特色：</label>
          <div
            class="option-null"
            :class="buildLabelIds.length > 0 ? '' : 'active'"
            @click="(buildLabelIds = []), search()"
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="buildLabelIds" @change="search">
              <el-checkbox
                v-for="item in feature"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="option" v-show="drop">
          <label class="label" for="">朝向：</label>
          <div
            class="option-null"
            :class="orientationIds.length > 0 ? '' : 'active'"
            @click="(orientationIds = []), search()"
          >
            不限
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="orientationIds" @change="search">
              <el-checkbox
                v-for="item in orientation"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="drop-down" @click="dropDown">
          <span>{{ drop ? '收起' : '展开' }}更多选项</span>
          <i :class="drop ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </div>
      </main>
    </div>
    <!-- 出租、出售列表 -->
    <div class="info-main">
      <!-- 列表tab -->
      <div class="info-main-tab">
        <div
          class="tab-item"
          v-for="(item, index) in tabList"
          :key="index"
          :class="item.id.indexOf(sortMethod) != -1 ? 'active' : ''"
          @click="sort(item, index)"
        >
          <span>{{ item.text }}</span>
          <i
            v-show="index == 2 || index == 3 || index == 4"
            :class="`el-icon-caret-${item.status ? 'bottom' : 'top'}`"
          ></i>
        </div>
      </div>
      <div class="info-main-box">
        <div class="info-main-left" v-if="plantList.length > 0">
          <div class="info-main-list" v-loading="loading">
            <HouseListItem :plantList="plantList" />
          </div>
          <div class="info-main-page" v-show="total">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="15"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div
          class="info-main-left"
          :class="!plantList.length ? 'empty' : ''"
          v-else
        ></div>
        <div class="info-main-right">
          <img src="../../assets/xcx-code.png" alt="" />
          <p>扫码打开小程序随时关注最新房源</p>
        </div>
      </div>
      <!-- 列表 -->
      <!-- 分页 -->
    </div>
    <!-- footer -->
    <Footer />
    <!-- 侧边悬浮 -->
    <Sidebar />
  </div>
</template>
<script>
import { searchLeaseLabel, searchPage } from 'services/office'
import { getAreaByParent } from 'services/common';
import DetailsHeader from 'components/office/DetailsHeader.vue';
import Sidebar from 'components/home/sidebar.vue';
// import Location from 'components/home/location.vue';
import HouseListItem from 'components/other/HouseListItem.vue';
import Footer from 'components/home/footer.vue';
export default {
  name: 'PlantRentalInPark',
  components: {
    DetailsHeader,
    HouseListItem,
    // Location,
    Sidebar,
    Footer
  },
  data () {
    return {
      priceTabIndex: 1,
      ruleForm: {
        minArea: '',
        maxArea: '',
        minPrice: '',
        maxPrice: '',
        minWorkstations: '',
        maxWorkstations: '',
      },
      // 筛选选项-start
      place: [],
      districtId: null,
      street: [],
      streetId: null,
      buildingId: '',
      area: [],
      areaLabelIds: [],
      workstations: [],
      workstationsIds: [],
      setOfprice: [], // 价格（单价、总价）
      price: [],
      priceLabelIds: [],
      ageLimit: [],
      yearIds: [],
      floor: [],
      floorIds: [],
      type: [],
      decorationIds: [],
      structure: [],
      structureIds: [],
      specialIndustryArr: [],
      facility: [],
      facilitiesIds: [],
      feature: [],
      buildLabelIds: [],
      orientation: [],
      orientationIds: [],
      letMethod: '',
      sourceType: '',
      totalFloor: '',
      drop: false,
      // 筛选选项-end
      headerTabList: [
        { id: 1, text: '租写字楼' },
        // { id: 2, text: '买写字楼' },
        { id: 2, text: '找楼盘' },
        { id: 3, text: '地图找房' },
      ],
      headTabIndex: 1,
      tabList: [
        { id: [0], text: '默认排序' },
        { id: [1], text: '最新发布' },
        { id: [2, 3], text: '面积排序', status: true },
        { id: [4, 5], text: '单价排序', status: true },
        { id: [6, 7], text: '总价排序', status: true },
      ],
      sortMethod: 0,
      keyword: '',
      total: 0,
      page: 1,
      loading: false,
      plantList: [],
      park: {}
    }
  },
  mounted () {
    // 判断token是否失效
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
            clearTimeout(timer)
          }, 500)
      }
    })
    // 获取位置
    this.getPlace()

    this.getSearch()
    // 百度词条找房
    if (this.$route.query.districtId) { this.districtId = this.$route.query.districtId; this.getStreet(this.districtId) }
    if (this.$route.query.streetId) this.streetId = this.$route.query.streetId

    // 首页跳转

    this.park = {
      id: this.$route.params.id,
      title: this.$route.params.title,
    }
    this.buildingId = this.$route.params.id.split('p')[1]

    this.getList()


    this.sortMethod = 0;
    let nav = document.querySelector('.info-main-right')
    let details = document.querySelector('.info-main-tab')

    window.addEventListener('scroll', () => {
      // 客服悬浮
      if (window.pageYOffset > details.offsetTop) {
        nav.style.position = 'fixed'
        nav.style.top = '100px'
        nav.style.right = 'calc(50% - 600px)'
        nav.style['z-index'] = '100'
      } else {
        nav.style.position = 'static'
      }
    })
  },
  methods: {
    // 获取位置
    getPlace () {
      let parentId = JSON.parse(localStorage.city).cityId
      getAreaByParent({ parentId }).then(res => {
        if (res.data.status === 200) {
          this.place = res.data.data
        }
      })
    },
    // 获取街道
    getStreet (parentId) {
      getAreaByParent({ parentId }).then(res => {
        if (res.data.status === 200) {
          this.street = res.data.data
        }
      })
      this.search()
    },
    // 获取搜索参数
    getSearch () {
      searchLeaseLabel().then(res => {
        if (res.data.status === 200) {
          // 处理数据
          //类型 1.面积  2.单价 3.总价 5.楼层 6.装修 7.朝向 8.特色 9.配套设施
          this.area = res.data.data.filter(item => item.type === 1)
          this.price = res.data.data.filter(item => item.type === 2)
          this.setOfprice = res.data.data.filter(item => item.type === 3 || item.type === 2)
          this.floor = res.data.data.filter(item => item.type === 5)
          this.type = res.data.data.filter(item => item.type === 6)
          this.orientation = res.data.data.filter(item => item.type === 7)
          this.feature = res.data.data.filter(item => item.type === 8)
          this.facility = res.data.data.filter(item => item.type === 9)
          this.workstations = res.data.data.filter(item => item.type === 10)
        }
      })
    },
    // 限制输入框
    NumberCheck (num, val) {
      let str = val === 'totalArea' ? Number(Number(num).toFixed(0)) : Number(Number(num).toFixed(2));
      if (str < 0) str = str * -1;
      // if (str.toString().length > 10) str = str.toString().substring(0, 10);
      this.ruleForm[val] = str.replace(/[^\d.]/g, '')
    },
    // 排序
    sort (item, i) {
      if (i === 0) this.sortMethod = 0
      if (i === 1) this.sortMethod = 1
      if (i === 2) {
        if (item.status) {
          this.sortMethod = 2
        } else {
          this.sortMethod = 3
        }
        item.status = !item.status
      }
      if (i === 3) {
        if (item.status) {
          this.sortMethod = 4
        } else {
          this.sortMethod = 5
        }
        item.status = !item.status
      }
      if (i === 4) {
        if (item.status) {
          this.sortMethod = 6
        } else {
          this.sortMethod = 7
        }
        item.status = !item.status
      }
      this.getList()
    },
    setArea () {
      if (this.ruleForm.maxArea < this.ruleForm.minArea) {
        const maxArea = this.ruleForm.minArea
        this.ruleForm.minArea = this.ruleForm.maxArea
        this.ruleForm.maxArea = maxArea
      }
      this.areaLabelIds = []
      this.search()
    },
    setWorkstations () {
      if (this.ruleForm.maxWorkstations < this.ruleForm.minWorkstations) {
        const maxWorkstations = this.ruleForm.minWorkstations
        this.ruleForm.minWorkstations = this.ruleForm.maxWorkstations
        this.ruleForm.maxWorkstations = maxWorkstations
      }
      this.workstationsIds = []
      this.search()
    },
    setPrice () {
      if (this.ruleForm.maxPrice < this.ruleForm.minPrice) {
        const maxPrice = this.ruleForm.minPrice
        this.ruleForm.minPrice = this.ruleForm.maxPrice
        this.ruleForm.maxPrice = maxPrice
      }
      this.priceLabelIds = []
      this.search()
    },
    setPriceTab (index) {
      this.priceTabIndex = index
      if (index === 1) this.price = this.setOfprice.filter(item => item.type === 2)
      if (index === 2) this.price = this.setOfprice.filter(item => item.type === 3)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList()
    },
    tabItem (val) {
      this.headTabIndex = val.id
      if (val.id === 2) {
        this.$router.push({
          path: `/searchGardenArea/kw/dr`,
        })
      }
      if (val.id === 3) {
        // this.$router.push({
        //   name: 'mapSearch'
        // })
        let routeUrl = this.$router.resolve({
          path: '/mapSearch',
        })
        window.open(routeUrl.href, '_blank');
      }
    },
    search () {
      this.page = 1;
      this.getList()
    },
    dropDown () {
      this.drop = !this.drop
    },
    getList () {
      let cityId = JSON.parse(localStorage.city).cityId
      let params = {
        cityId,
        keywords: this.keyword,
        page: this.page,
        streetId: this.streetId,
        districtId: this.districtId,
        buildingId: this.buildingId,
        orientationIds: this.orientationIds,
        decorationIds: this.decorationIds,
        floorIds: this.floorIds,
        buildLabelIds: this.buildLabelIds,
        facilitiesIds: this.facilitiesIds,
        minArea: this.ruleForm.minArea,
        maxArea: this.ruleForm.maxArea,
        areaLabelIds: this.areaLabelIds,
        minPrice: this.ruleForm.minPrice,
        maxPrice: this.ruleForm.maxPrice,
        minTotalPrice: this.ruleForm.minPrice,
        maxTotalPrice: this.ruleForm.maxPrice,
        priceLabelIds: this.priceLabelIds,
        totalPriceLabelIds: this.priceLabelIds,
        sortMethod: this.sortMethod,
        workstationsIds: this.workstationsIds,
        minWorkstations: this.ruleForm.minWorkstations,
        maxWorkstations: this.ruleForm.maxWorkstations,
      }
      if (this.priceTabIndex == 1) {
        delete params.totalPriceLabelIds
        delete params.minTotalPrice
        delete params.maxTotalPrice
      }
      if (this.priceTabIndex == 2) {
        delete params.priceLabelIds
        delete params.minPrice
        delete params.maxPrice
      }
      this.loading = true
      searchPage(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data.map(item => {
            return {
              ...item,
              coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
            }
          })
          this.total = res.data.total
        }
        this.loading = false
      })
    },
  }
};
</script>
<style lang="less" scoped>
.plant-rental {
  .logo-search {
    height: 180px;
    opacity: 1;
    background: rgba(246, 245, 244, 1);
    padding: 30px calc(50% - 600px) 24px;
    margin-bottom: 20px;
    .logo-tab {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .logo {
        display: flex;
        align-items: center;
        margin-right: 40px;
        img {
          width: 114px;
          height: 34px;
          margin-right: 16px;
        }
      }
      .tab {
        display: flex;
        align-items: center;
        &-item {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          margin-left: 40px;
          cursor: pointer;
          &.active {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
    .search {
      width: 640px;

      .inp {
        height: 46px;
        /deep/ .el-input__inner {
          height: 46px;
          line-height: 46px;
          border: 1px solid rgba(255, 226, 204, 1);
          border-radius: 0;
        }
        /deep/ .el-input-group__append {
          width: 140px;
          height: 44px;
          background: rgba(255, 225, 201, 1);
          border: 1px solid rgba(255, 225, 201, 1);
          box-sizing: border-box;
          font-size: 18px;
          font-weight: 600;
          line-height: 44px;
          text-align: center;
          color: rgba(236, 102, 0, 1);
          border-radius: 0;
          cursor: pointer;
        }
      }
    }
  }
  &-header-content {
    padding: 0px calc(50% - 600px) 24px;
    header {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(153, 153, 153, 1);
      margin-bottom: 10px;
    }
    main {
      width: 100%;
      // height: 240px;
      padding: 0px 20px;
      border: 1px solid rgba(238, 238, 238, 1);
      .option {
        display: flex;
        // align-items: center;
        padding: 16px 0;
        border-bottom: 1px dashed rgba(238, 238, 238, 1);
        .label {
          font-size: 16px;
          font-weight: 400;
          line-height: 36px;
          color: rgba(51, 51, 51, 1);
        }
        .option-null {
          width: 28px;
          cursor: pointer;
          margin: 0 30px 0 20px;
          line-height: 36px;
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
          &.active {
            color: rgba(236, 102, 0, 1);
          }
        }
        /deep/ .el-select {
          margin-right: 32px;
          height: 26px;
          .el-input__inner {
            width: 120px;
            // height: 26px;
            // line-height: 26px;
            border-radius: 2px;
            background: rgba(255, 255, 255, 1);
            border: 0.6px solid rgba(221, 221, 221, 1);
          }
          .el-input .el-select__caret {
            line-height: 26px;
          }
        }
        .city {
          width: calc(100% - 158px);
        }
        .price {
          width: calc(100% - 158px);
          &-tab {
            display: flex;
            align-items: center;
            &-item {
              display: inline-block;
              line-height: 19px;
              font-size: 14px;
              margin-top: 8px;
              margin-right: 20px;
              cursor: pointer;
              color: #606266;
              &.active {
                color: #ec6600;
              }
            }
          }
          .checkbox {
            width: 100%;
          }
        }
        .checkbox {
          display: flex;
          align-items: center;
          width: calc(100% - 158px);
          /deep/ .el-checkbox-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .el-checkbox {
              margin: 8px 0;
              margin-right: 20px;
            }
          }
          .min-max-inp {
            width: 250px;
            display: flex;
            align-items: center;
            height: 26px;
            // margin-left: 36px;
            .min {
              width: 60px;
              height: 26px;
              /deep/ .el-input__inner {
                height: 26px;
                border: 0.6px solid rgba(221, 221, 221, 1);
                border-radius: 2px;
              }
            }
            .max {
              width: 60px;
              height: 26px;
              /deep/ .el-input__inner {
                height: 26px;
                border: 0.6px solid rgba(221, 221, 221, 1);
                border-radius: 2px;
              }
            }
            .line {
              padding: 0 4px;
              font-size: 14px;
              line-height: 26px;
            }
            .btn {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(236, 102, 0, 1);
              margin-left: 12px;
            }
          }
        }
      }
      .drop-down {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        cursor: pointer;
        span {
          margin-right: 8px;
        }
      }
    }
  }
  .info-main {
    padding: 0px calc(50% - 600px) 0px;
    background-color: #fff;
    margin-bottom: 77px;
    &-tab {
      height: 44px;
      background: rgba(246, 245, 244, 1);
      border: 1px solid rgba(246, 245, 244, 1);
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .tab-item {
        padding: 0 12px;
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          background: rgba(255, 255, 255, 1);
          color: rgba(236, 102, 0, 1);
          border-top: 1px solid rgba(236, 102, 0, 1);
        }
      }
    }
    &-box {
      display: flex;
    }
    &-left {
      width: calc(100% - 348px);
    }
    &-list {
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: rgba(236, 102, 0, 1);
      }
    }
    &-right {
      width: 168px;
      margin-left: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 12px;
        width: 100%;
      }
      p {
        width: 112px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
}
/deep/ .details-search {
  background: #fff;
  padding-top: 32px;
  padding-bottom: 24px;
  height: auto;
  .logo-search {
    margin-bottom: 0px;
  }
}

/deep/ .el-radio-button {
  margin-right: 30px;
  color: rgba(102, 102, 102, 1);
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
}
/deep/ .el-radio-button__inner {
  padding: 0;
  border: 0 !important;
  &:hover {
    color: rgba(236, 102, 0, 1);
  }
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border: 0;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border: 0;
  box-shadow: 0 0 0 0;
  background-color: #fff;
  color: rgba(236, 102, 0, 1);
}

/deep/ .el-checkbox-button {
  margin-right: 30px;
  color: rgba(102, 102, 102, 1);
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
}
/deep/ .el-checkbox-button__inner {
  padding: 0;
  border: 0;
}
/deep/ .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 0;
}
/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  border: 0;
  box-shadow: 0 0 0 0;
  background-color: #fff;
  color: rgba(236, 102, 0, 1);
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: rgba(236, 102, 0, 1);
  border-color: rgba(236, 102, 0, 1);
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(236, 102, 0, 1);
}
</style>
  