<template>
  <div class="model">
    <div id="model"></div>
    <div class="cut" v-if="url3" @click="toInroodModel">
      <span>室内三维</span>
      <i class="icon icon-model-left"></i>
    </div>
    <div class="operation">
      <div
        v-if="url2"
        class="model-show"
        @click="showTo"
        :class="show ? 'active' : ''"
      >
        <i class="icon icon-model-indoor"></i>
        <p>室内场景</p>
      </div>
      <div
        v-else-if="panoramaUrl"
        class="model-show"
        @click="toVR"
        :class="show ? 'active' : ''"
      >
        <i class="icon icon-model-indoor"></i>
        <p>室内全景</p>
      </div>
      <div class="item">
        <div class="model-show dis-none" @click="zoomIn">
          <i class="icon icon-model-plus"></i>
          <p>放大</p>
        </div>
        <div class="model-show dis-none" @click="zoomOut">
          <i class="icon icon-model-minus"></i>
          <p>缩小</p>
        </div>
      </div>
      <div class="item">
        <div class="model-show" @click="flipUp">
          <i class="icon icon-model-top"></i>
          <p>角度向上</p>
        </div>
        <div class="model-show" @click="flipDown">
          <i class="icon icon-model-bottom"></i>
          <p>角度向下</p>
        </div>
      </div>

      <div class="item">
        <div class="model-show" @click="rotateLeft">
          <i class="icon icon-model-refresh-left"></i>
          <p>向左旋转</p>
        </div>
        <div class="model-show" @click="rotateRight">
          <i class="icon icon-model-refresh-right"></i>
          <p>向右旋转</p>
        </div>
      </div>
      <div class="model-show" :class="!type ? 'active' : ''" @click="mapType">
        <i class="icon icon-model-satellite-image"></i>
        <p>卫星图</p>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from 'axios';
// const modelData = window.modelData
import locationMiddle from 'assets/model/house-location-middle.png'
import locationBottom from 'assets/model/house-location-bottom.png'
import circle from 'assets/model/circle.png'
import location from 'assets/model/vr-location.png'
export default {
  name: 'vr',
  components: {
  },
  data () {
    return {
      modelData: {},
      v_data: {},
      show: false,
      // layeringModel: null,
      // provider: null,
      type: true,
      operation: null,
      url2: null,
      url3: null,
      panoramaUrl: null,
      detailsId: ''
    }
  },
  beforeCreate () {
    window._bd_share_main = ""
    window.BMap_loadScriptTime = (new Date()).getTime()
  },
  beforeDestroy () {
    this.engineMap.destroy()
    this.map.destroy();
    console.log('室外三维注销', this.engineMap);
  },
  destroyed () {
    // this.engineMap.destroy()
    // this.map.destroy();
  },
  mounted () {
    console.log(this.$route.params);
    this.detailsId = this.$route.params.detailsId
    let _this = this
    this.$nextTick(() => {
      _this.getMap().then(Map => {
        fetch(window.modelDataIp + "/modelData.json").then(function (response) {
          return response.json();
        }).then(data => {
          console.log(data)
          _this.modelData = data

          // 判断id cs- 1314658588555370496-1310993948029902848
          _this.v_data = _this.modelData.data[this.detailsId] ? _this.modelData.data[_this.detailsId] : _this.modelData.data['1314658588555370496']

          if (_this.v_data) {
            let { url2, panoramaUrl, url3 } = _this.v_data
            if (url2) _this.url2 = _this.url2 = url2
            if (panoramaUrl) _this.panoramaUrl = panoramaUrl
            if (url3) _this.url3 = url3
          } else {
            _this.tab = []
          }

          // 初始化百度地图（需要引入地图jsapi）
          const map = new Map('model', {
            // enableRotate: true,
            // enableTilt: true,
            minZoom: 5,
            maxZoom: 25
          });
          map.centerAndZoom(new BMapGL.Point(_this.v_data.location[0], _this.v_data.location[1]), 20);
          map.enableScrollWheelZoom();
          _this.map = map
          _this.t = false
          map.addEventListener('tilesloaded', _this.initModel);
          map.addEventListener("click", function (e) {
            console.log(e, [e.point.lng, e.point.lat])
          })
          // this.initModel()

          // 判断3秒内是否初始化，如果没有就自动刷新页面
          let ti = setTimeout(() => {
            if (!_this.t) {
              _this.$router.go(0)
              clearInterval(ti)
            }
          }, 5000)
        })
      })
    })

  },
  methods: {
    toInroodModel () {
      this.$router.push({
        path: '/indoorModel/2/' + this.detailsId
      })
    },
    getMap () {
      return new Promise(resolve => {
        if (window.BMapGL && window.BMapGL.Map) {
          let Map = window.BMapGL.Map;
          resolve(Map)
        } else {
          let timer = setInterval(() => {
            if (window.BMapGL && window.BMapGL.Map) {
              let Map = window.BMapGL.Map;
              resolve(Map)
              clearInterval(timer)
            }
          })
        }
      })
    },
    initModel () {
      if (!this.engine && !this.engineMap) {
        this.addModel(this.v_data, window.page + this.modelData.ip);
        this.t = true
      }
      this.map.removeEventListener('tilesloaded', this.initModel);
    },
    addModel (data, ip) {
      let _this = this
      //初始化 mapvthree 引擎(第一个参数为地图实例或DOM)
      const engine = new mapvthree.Engine(_this.map, {
        rendering: {
          enableAnimationLoop: true,
        }
      });
      this.engine = engine

      var engineMap = new EngineMap(engine)
      this.engineMap = engineMap
      // engine.map.setCenter(data.location);
      engine.map.setPitch(data.pitch);
      engine.map.setHeading(data.heading);
      var layer = new THREE.Group();
      engine.add(layer);
      //保存人工模型
      engineMap.addModel(ip + data.url2).then((model) => {
        model.traverse(e => {
          if (e.isMesh && (e.name == 'ding' || e.name == 'ding_zhu')) {
            e.visible = false
          }
        })
        //隐藏
        model.visible = false;
        _this.layeringModel = model;
      });
      //转换成三维空间坐标
      engineMap.addModel(ip + data.url1).then((e) => {
        //绘制楼层颜色标注
        e.setUniforms({
          flatState: true,
          stateMum: 0,
          range: data.range,//范围
          height: data.height,//
          floorHeight: data.floorHeight
        })
        _this.provider = e;
      })

      engine._container.addEventListener('click', function (e) {
        //点击获取属性
        var features = engineMap.getFeatures(e.offsetX, e.offsetY);
        console.log('features->', features)
        if (!features[0]) return
        if (features[0].featureType === 'house-label') {
          console.log('你点了楼栋标签');
        } else if (features[0].featureType === 'vr-label') {
          console.log('你点了全景标签', features[0]);
          _this.toOutdoorVr(features[0].featureData)
        } else {
          //手工模型隐藏
          _this.layeringModel.visible = true;
          //模型剪切上面部分
          _this.provider.setUniforms({
            stateMum: 1,
          })
          _this.show = true
        }
      });

      let { houseLabel, publicVr } = data
      if (houseLabel) {
        houseLabel.forEach(item => {
          this.addHouseLabel(item)
        })
      }
      if (publicVr) {
        publicVr.forEach((item, index) => {
          this.addOutdoorVr({ ...item, vrIndex: index })
        })
      }
    },
    // 设置楼栋
    addHouseLabel (item) {
      var line = new LineMarker({
        bottom: {
          //路径
          url: locationBottom,
          //大小
          scale: 0.45,
          center: { x: 0.5, y: 0.5 }
        },
        text: {
          //文字canvas标签
          canvas: getTextTexture(item.name, {
            //字体大小
            fontSize: 20,
            //字体类型
            fontFamily: "Arial",
            //文字颜色
            fontColor: 'rgba(255, 255, 255, 1)',
            //背景色
            backgroundColor: "rgba(245, 122, 0, 1)",
            //边距离
            padding: [8, 15, 8, 15],
            //圆角
            radius: 4,

          }),
          //放缩
          scale: 1,
          center: { x: 0.5, y: -1.2 }
        },
        middle: {
          url: locationMiddle,
          scale: 0.6,
          center: { x: 0.5, y: 0 }
        },
        //位置
        position: new THREE.Vector3().set(...item.position)
      })
      line.featureType = 'house-label'
      line.featureData = item
      this.engineMap.add(line);




      // 上一版
      // var maker = new TrendsMaker({
      //   color: new THREE.Color("#FFE1BD"),
      //   position: new THREE.Vector3().set(...item.position),//点位置
      //   verticalFrame: 25,//纵向数字
      //   interval: 50,//动画间隔
      //   horizontalFrame: 1,  //横向间隔
      //   scale: 0.4,
      //   type: 'house'
      // });

      // maker.featureType = 'house-label'
      // maker.featureData = item
      // this.engineMap.add(maker);
      // var marler = new Marker({
      //   url: location,
      //   position: new THREE.Vector3(...item.position),
      //   scale: 0.25,
      //   type: 'house'
      // })
      // //设置动画，暂无
      // marler.setAnimation(1, 0.5)

      // marler.featureType = 'house-label'
      // marler.featureData = item
      // this.engineMap.add(marler);
      // //调整锚点
      // marler.setCenter(0.5, 0.2);
      // var canvas = getTextTexture(item.name, {
      //   //字体大小
      //   fontSize: 20,
      //   //字体类型
      //   fontFamily: "Arial",
      //   //文字颜色
      //   fontColor: 'rgba(255, 255, 255, 1)',
      //   //背景色
      //   backgroundColor: "rgba(245, 122, 0, 1)",
      //   //边距离
      //   padding: [7, 14, 7, 14],
      //   //圆角
      //   radius: 4,

      // })
      // var text = new Marker({
      //   url: canvas,
      //   position: new THREE.Vector3(item.position[0], item.position[1], item.position[2]),
      //   scale: 1,
      //   type: 'house'
      // })

      // text.featureType = 'house-label'
      // text.featureData = item
      // this.engineMap.add(text);
      // //调整锚点
      // text.setCenter(0.5, -1.5);
    },
    // 设置室外全景
    addOutdoorVr (item, index) {
      // var maker = new TrendsMaker({
      //   color: new THREE.Color("#1A7DFF"),
      //   position: new THREE.Vector3().set(...item.position),//点位置
      //   verticalFrame: 25,//纵向数字
      //   interval: 50,//动画间隔
      //   horizontalFrame: 1,  //横向间隔
      //   scale: 0.4,
      // });
      //设置动画，暂无
      // marler.setAnimation(1, 0.5)

      var maker = new Marker({
        url: circle,
        position: new THREE.Vector3(...item.position),
        scale: 0.7,
        type: 'house'
      })
      maker.setAnimation(2, 0.5)

      maker.featureType = 'vr-label'
      maker.featureData = item
      this.engineMap.add(maker);


      var marler = new Marker({
        url: location,
        position: new THREE.Vector3(...item.position),
        scale: 0.55,
        type: 'house'
      })
      //设置动画，暂无
      marler.setAnimation(1, 0.2)

      marler.featureType = 'house-label'
      marler.featureData = item
      this.engineMap.add(marler);
      //调整锚点
      marler.setCenter(0.5, 0.2);


      var canvas = createImageCanvas(
        getTextTexture(item.name, {
          //字体大小
          fontSize: 16,
          //字体类型
          fontFamily: "Arial",
          //文字颜色
          fontColor: 'rgba(75, 250, 145, 1)',

        })
      )
      var text = new Marker({
        url: canvas,
        position: new THREE.Vector3(...item.position),
        scale: 1.02
      })
      this.engineMap.add(text);
      text.featureType = 'vr-label'
      text.featureData = item
      //调整锚点
      text.setCenter(0.5, -1.0);
    },

    // 切换室外全景
    toOutdoorVr (item) {
      let routeUrl = this.$router.resolve({
        path: '/outdoorVr',
      })
      window.open(routeUrl.href + "/0/" + this.detailsId + '/' + item.vrIndex, '_blank');
    },
    // 切换室外、室内、全景
    setType (item) {
      console.log(item);
    },
    toVR () {
      // let routeUrl = this.$router.resolve({
      //   path: '/vr',
      // })
      // window.open(routeUrl.href + '/3/'+ this.detailsId, '_blank');
      this.$router.push({
        path: '/vr/3/' + this.detailsId
      })
    },
    showTo () {
      if (!this.layeringModel) {
        return;
      }
      if (this.layeringModel.visible) {
        this.show = false
        this.layeringModel.visible = false;
        this.provider.setUniforms({
          stateMum: 0,
        })
      } else {
        this.show = true
        this.layeringModel.visible = true;
        this.provider.setUniforms({
          stateMum: 1,
        })

      }
    },
    zoomIn () {
      this.operation = 1
      this.engineMap.zoomIn()
    },
    zoomOut () {
      this.operation = 2
      this.engineMap.zoomOut()
    },
    rotateLeft () {
      this.operation = 5
      this.engineMap.rotateLeft()
    },
    rotateRight () {
      this.operation = 6
      this.engineMap.rotateRight()
    },
    flipUp () {
      this.operation = 3
      this.engineMap.flipUp()
    },
    flipDown () {
      this.operation = 4
      this.engineMap.flipDown()
    },
    mapType () {
      this.type = !this.type
      if (this.type) {
        this.map.setMapType(BMAP_NORMAL_MAP);
      } else {
        this.map.setMapType(BMAP_SATELLITE_MAP);
      }
    }
  }
};
</script>

<style lang="less" scoped>
#model {
  width: 100%;
  height: 100%;
}

@media only screen and (min-device-width: 768px) {
  .model {
    position: relative;
    width: 100vw;
    height: 100vh;
    min-width: initial !important;
    overflow: hidden;
    .cut {
      display: none;
    }
    .operation {
      position: absolute;
      top: 100px;
      right: 20px;
      z-index: 100;
    }
    &-show {
      width: 88px;
      height: 82px;
      border-radius: 8px;
      background: rgba(102, 102, 102, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 30px;
        color: #fff;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(255, 255, 255, 1);
      }
      &:active {
        background: linear-gradient(
          -180deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
      &.active {
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
  }
  .item {
    border-radius: 8px;
    margin: 10px 0;
    background: rgba(102, 102, 102, 0.7);
    .model-show {
      background: transparent;
      &:active {
        background: linear-gradient(
          -180deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
      &.active {
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
  }
}
@media only screen and (max-device-width: 768px) {
  .dis-none {
    display: none !important;
  }
  .model {
    position: relative;
    width: 100vw;
    height: 100vh;
    min-width: initial !important;
    overflow: hidden;

    .cut {
      position: absolute;
      top: 10px;
      right: 8px;
      z-index: 100;
      width: 91px;
      height: 30px;
      border-radius: 4px;
      background: rgba(51, 51, 51, 0.6);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      span {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(255, 255, 255, 1);
        margin-right: 10px;
      }
      i {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
    }
    .operation {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translate(-50%);
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: rgba(34, 34, 34, 0.4);
    }
    &-show {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        width: 22px;
        height: 22px;
      }
      p {
        margin-top: 4px;
        font-size: 11px;
        font-weight: 400;
        line-height: 15.4px;
        color: rgba(255, 255, 255, 1);
      }
      &:active {
        background: rgba(34, 34, 34, 0.4);
      }
      &.active {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    .model-show {
      background: transparent;
      &:active {
        background: rgba(34, 34, 34, 0.4);
      }
      &.active {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
    }
  }
}
</style>