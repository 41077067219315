<template>
  <div class="look-house-subscribe">
    <div class="title">我的评价</div>
    <div class="tab">
      <div
        class="tab-item"
        v-for="item in tabList"
        :key="item.id"
        :class="tab === item.id ? 'active' : ''"
        @click="tab = item.id"
      >
        {{ item.text }}
      </div>
    </div>
    <main class="main">
      <div class="main-list" v-if="tab === 1">
        <div class="main-list-item" v-for="item in plantList" :key="item.id">
          <div class="main-list-item-left">
            <img :src="item.imgUrl" alt="" />
            <div class="left-content">
              <p class="name">{{ item.titel }}</p>
              <p class="gs">{{ item.count }}</p>
              <div class="city">
                <i class="icon icon-plant-details-location"></i>
                龙岗区坪地低碳城
              </div>
            </div>
          </div>
          <div class="main-list-item-right">
            <div class="phone" @click="goRate(item)">
              <span>去评价</span>
            </div>
          </div>
        </div>
      </div>
      <div class="main-list" v-else>
        <div class="main-list-item" v-for="item in plantList" :key="item.id">
          <div class="main-list-item-left">
            <img :src="item.imgUrl" alt="" />
            <div class="left-content">
              <div class="rate">
                <label>满意度：</label>
                <div class="rate">
                  <i
                    v-for="i in 5"
                    class="icon icon-rate"
                    :key="i"
                    :class="item.rate < i ? 'icon-rate-gray' : ''"
                  ></i>
                </div>
              </div>
              <div class="time">{{ item.time }}</div>
              <div class="content">{{ item.titel }}</div>
              <div class="left-content-label">
                <div
                  class="left-content-label-item"
                  v-for="(i, index) in item.label2"
                  :key="index"
                  :title="i"
                >
                  {{ i }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="total, prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div>
    </main>

    <!-- 评价弹窗 -->
    <el-dialog
      title="评价"
      :visible.sync="rateVisible"
      width="560px"
      :before-close="rateHandleClose"
    >
      <div class="body">
        <div class="user">
          <img class="photo" :src="imgUrl" alt="" />
          <div class="user-content">
            <p class="name">
              李晓明
              <span>深圳市利达地产</span>
            </p>
            <p class="content">坪地低碳城商业写字楼综合体100平起租</p>
            <div class="count">18元/m²/月</div>
          </div>
        </div>
        <div class="rate">
          <p>评价<span>已对咨询顾问匿名</span></p>
          <div class="rate-item">
            <label for="">房源真实度</label>
            <el-rate v-model="value1"></el-rate>
          </div>
          <div class="rate-item">
            <label for="">服务态度</label>
            <el-rate v-model="value1"></el-rate>
          </div>
          <div class="rate-item">
            <label for="">专业能力</label>
            <el-rate v-model="value1"></el-rate>
          </div>
          <el-input
            class="textarea"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" type="primary" @click="rateVisible = false"
          >提交评价</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'LookHouseSubscribe',
  components: {},
  data () {
    return {
      tabList: [
        { id: 1, text: '待评价' },
        { id: 2, text: '已评价' }
      ],
      value1: 4,
      textarea: '',
      tab: 1,
      navIndex: 1,
      currentPage3: 1,
      rateVisible: false,
      imgUrl: '',
      plantList: [
        {
          id: 1,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库',]
        },
        {
          id: 2,
          type: 'video', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '18/㎡/月',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 3,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 4,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 5,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 6,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 7,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
        {
          id: 8,
          type: 'vr', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          time: '2023-09-25  12:31:21',
          rate: 3,
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        }
      ]
    }
  },
  mounted () { },
  methods: {
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    toPush (item) {
      this.navIndex = item.id
    },
    rateHandleClose () {
      this.rateVisible = false
    },
    goRate (item) {
      console.log(item)
      this.imgUrl = item.imgUrl
      this.rateVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
.look-house-subscribe {
  height: 100%;
  padding: 40px 36px 24px 24px;
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(51, 51, 51, 1);
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      color: rgba(236, 102, 0, 1);
    }
  }
  .tab {
    height: 40px;
    background: rgba(246, 245, 244, 1);
    border: 1px solid rgba(246, 245, 244, 1);
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 16px;
    .tab-item {
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 1);
        color: rgba(236, 102, 0, 1);
        border-top: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
  .main {
    height: calc(100% - 100px);
    &-list {
      overflow: auto;
      height: calc(100% - 60px);
      &-item {
        height: 112px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          display: flex;
          align-items: center;
          img {
            width: 100px;
            height: 75px;
            border-radius: 4px;
            margin-right: 12px;
          }
          .left-content {
            .name {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: rgba(51, 51, 51, 1);
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .gs {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              color: rgba(51, 51, 51, 1);
              margin-bottom: 4px;
            }
            .rate {
              display: flex;
              align-items: center;
              label {
                line-height: 20px;
                color: rgba(51, 51, 51, 1);
              }
              i {
                margin-right: 4px;
                width: 12px;
                height: 12px;
              }
            }
            .time {
              font-size: 12px;
              line-height: 16px;
              color: rgba(153, 153, 153, 1);
            }
            .content {
              font-size: 12px;
              line-height: 16px;
              color: rgba(51, 51, 51, 1);
              margin-bottom: 4px;
            }
            &-label {
              display: flex;
              align-items: center;
              &-item {
                padding: 3px 10px;
                border-radius: 2px;
                background: rgba(245, 245, 245, 1);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
                margin-right: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        &-right {
          text-align: end;
          display: flex;
          flex-direction: column;
          align-items: end;
          .time {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 15px;
            i {
              font-size: 14px;
              color: rgba(236, 102, 0, 1);
              margin-right: 8px;
            }
          }

          .phone {
            width: 110px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background: rgba(255, 243, 235, 1);
            font-size: 14px;
            font-weight: 400;
            border: 1px solid rgba(236, 102, 0, 1);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(236, 102, 0, 1);
            cursor: pointer;
          }
        }
      }
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: rgba(236, 102, 0, 1);
      }
    }
  }
}

.city {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(153, 153, 153, 1);
  i {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .user {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    margin-bottom: 20px;
    .photo {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 50%;
    }
    &-content {
      .name {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: rgba(0, 0, 0, 1);
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(136, 136, 136, 1);
          margin-left: 8px;
        }
      }
      .content {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
        margin: 4px 0;
      }
      .count {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .rate {
    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(136, 136, 136, 1);
        margin-left: 8px;
      }
    }
    &-item {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      label {
        width: 70px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        margin-right: 20px;
      }
    }
    .textarea {
      margin-top: 8px;
      /deep/ .el-textarea__inner {
        height: 100px;
        border-radius: 4px;
        background: rgba(247, 247, 247, 1);
        border: 0;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>