<template>
  <div>
    <div class="date-time-picker">
      <div class="time">
        <div class="hour">
          <div
            class="hour-item"
            :class="hourIndex === item.id ? 'active' : ''"
            v-for="item in hour"
            :key="item.id"
            @click="hourIndex = item.id"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="minute">
          <div
            class="minute-item"
            :class="
              !item.disabled && hourIndex === 1
                ? 'disabled'
                : minuteIndex === item.id
                ? 'active'
                : ''
            "
            v-for="item in minute"
            :key="item.id"
            @click="getMinute(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="btn">立即预约</div>
    </div>
    <div class="model" @click="$emit('close')"></div>
  </div>
</template>
<script>
export default {
  name: 'DatetimePicker',
  data () {
    return {
      navIndex: 2,
      hour: [
        { id: 1, time: '', text: '' },
        { id: 2, time: '', text: '' },
        { id: 3, time: '', text: '' },
        { id: 4, time: '', text: '' },
        { id: 5, time: '', text: '' },
        { id: 6, time: '', text: '' },
        { id: 7, time: '', text: '' },
      ],
      hourIndex: 1,
      minute: [
        { id: 1, min: 24, max: 6, disabled: false, text: '06:00之前' },
        { id: 2, min: 6, max: 8, disabled: false, text: '06:00-08:00' },
        { id: 3, min: 8, max: 10, disabled: false, text: '08:00-10:00' },
        { id: 4, min: 10, max: 12, disabled: false, text: '10:00-12:00' },
        { id: 5, min: 12, max: 14, disabled: false, text: '12:00-14:00' },
        { id: 6, min: 14, max: 16, disabled: false, text: '14:00-16:00' },
        { id: 7, min: 16, max: 18, disabled: false, text: '16:00-18:00' },
        { id: 8, min: 18, max: 20, disabled: false, text: '18:00-20:00' },
        { id: 9, min: 20, max: 24, disabled: false, text: '20:00之后' },
      ],
      minuteIndex: null
    }
  },
  mounted () {
    this.hour = this.hour.map(item => {
      const obj = this.getFuture(item.id)
      const oldObj = { id: item.id }
      return Object.assign(oldObj, obj)
    })
    const date = new Date();
    var H = date.getHours();//获取当前日
    var M = date.getMinutes()//获取当前分
    if (M > 0) {
      H = Number(H) + 1
    }
    this.minute = this.minute.map(item => {
      let disabled = false
      if (H < item.max) {
        disabled = true
      }
      return {
        ...item,
        disabled
      }

    })
  },
  methods: {
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    },
    getFuture (i = 1) {
      const date = new Date();
      var milliseconds = date.getTime() + 1000 * 60 * 60 * 24 * i; //将当前日期转换为毫秒，加上需要增加的天数的毫秒，i表示天数
      var newMyDate = new Date(milliseconds);  //再将毫秒转化为日期
      var weekDay = newMyDate.getDay(); //获取当前星期X(0-6,0代表星期天)
      var year = newMyDate.getFullYear();//获取当前年
      var month = newMyDate.getMonth() + 1;//获取当前月
      var day = newMyDate.getDate();//获取当前日
      var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      var week = weeks[weekDay];
      var text = null;
      if (i === 1) {
        text = '今天'
      } else if (i === 2) {
        text = '明天'
      } else if (i === 3) {
        text = '后天'
      } else {
        text = `${month}月${day} ${week}`
      }
      return {
        time: `${year}-${month}-${day}`,
        text
      }
    },
    getMinute (item) {
      // 未禁用
      if (!(!item.disabled && this.hourIndex === 1)) {
        this.minuteIndex = item.id;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.date-time-picker {
  position: absolute;
  top: 55px;
  left: -50px;
  z-index: 999;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  .time {
    width: 100%;
    height: 441px;
    display: flex;
    align-items: center;
    .hour {
      width: 150px;
      height: 100%;
      &-item {
        height: 62px;
        line-height: 62px;
        cursor: pointer;
        padding: 0 16px;
        border-bottom: 1px solid #f5f5f5;
      }
    }
    .minute {
      width: 150px;
      height: 100%;
      border-left: 1px solid #f5f5f5;
      &-item {
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        padding: 0 16px;
        border-bottom: 1px solid #f5f5f5;
      }
    }
    .active {
      color: rgba(236, 102, 0, 1);
    }
    .disabled {
      color: #ccc;
    }
  }
  .btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    justify-content: center;
    background: rgba(236, 102, 0, 1);
    color: #fff;
    cursor: pointer;
  }
}
.model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 899;
  background-color: rgba(0, 0, 0, 0.06);
}
</style>