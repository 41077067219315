<template>
  <div class="details-header-info">
    <div class="title">{{ details.title }}</div>
    <div class="count">
      <span class="num">
        {{
          details.totalPrice > 1000
            ? (details.totalPrice / 10000).toFixed(2)
            : details.totalPrice
        }}<span class="price-unit"
          >{{ details.totalPrice > 1000 ? '万' : '' }}元/月</span
        >
      </span>
      <span class="num" style="font-size: 12; color: rgba(153, 153, 153, 1)">
        {{ details.price
        }}<span class="price-unit f-s-12" style="color: rgba(153, 153, 153, 1)"
          >元/㎡/月</span
        >
      </span>
    </div>
    <div class="info-label">
      <div class="info-label-item">
        <div class="text">{{ details.totalArea || '-' }}m²</div>
        <div class="label">总面积</div>
      </div>
      <div class="info-label-item">
        <div class="text">
          {{ details.minWorkstations || '无数据' }}~{{
            details.maxWorkstations || '无数据'
          }}
        </div>
        <div class="label">工位数</div>
      </div>
      <div class="info-label-item">
        <div class="text">{{ details.decorationName || '-' }}</div>
        <div class="label">装修程度</div>
      </div>
      <div class="info-label-item">
        <div class="text">{{ details.businessRegister ? '是' : '否' }}</div>
        <div class="label">工商注册</div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'DetailsHeaderInfo',
  data () {
    return {
      value1: null,
      dialogVisible: false,
      detail: '',
      reason: '虚假房源',
      type: 1,
      image: [],
      user: {}
    }
  },
  props: {
    details: Object
  },
  mounted () {
  },
  methods: {}
};
  </script>
  
  <style lang="less" scoped>
.details-header-info {
  width: 100%;
  padding: 12px 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 248, 247, 1) 100%
  );
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: rgba(51, 51, 51, 1);
  }
  .count {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: rgba(236, 102, 0, 1);
  }
  .info-label {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      // width: 25%;
    }
    .text {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
    }
    .label {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: rgba(153, 153, 153, 1);
      text-align: center;
    }
  }
}
</style>
  