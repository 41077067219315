<template>
  <div class="details-carousel">
    <div class="back" @click="$emit('back')">
      <img src="../../../assets/back.png" alt="" />
    </div>

    <div class="look-house-type">
      <div
        v-if="modelExist"
        class="type-item"
        :class="type === 1 ? 'active' : ''"
        @click="toType(1)"
      >
        三维
      </div>
      <div class="line"></div>
      <div
        v-if="vrExist"
        class="type-item"
        :class="type === 2 ? 'active' : ''"
        @click="toType(2)"
      >
        全景
      </div>
      <div
        v-if="videoExist"
        class="type-item"
        :class="type === 3 ? 'active' : ''"
        @click="toType(3)"
      >
        视频
      </div>
      <div
        v-if="imgVisible"
        class="type-item"
        :class="type === 4 ? 'active' : ''"
        @click="toType(4)"
      >
        图片
      </div>
    </div>
    <van-swipe
      v-if="imgList.length > 0"
      class="swipe"
      ref="swipe"
      height="328"
      @change="onChange"
    >
      <van-swipe-item
        class="swipe-item"
        v-for="(url, index) in imgList"
        :key="index"
      >
        <img
          @click="toImg(url)"
          style="width: 100%; height: 100%"
          :src="los + url.url + '?' + Date.now()"
          alt=""
        />

        <div>
          <img
            class="gif"
            v-if="url.type === 1 && modelExist"
            src="../../../assets/office/details/3D.png"
            alt=""
            @click="toImg(url)"
          />
          <img
            class="gif"
            v-if="url.type === 2 && vrExist"
            src="../../../assets/office/details/VR.png"
            alt=""
            @click="toImg(url)"
          />
          <img
            class="gif"
            v-if="url.type === 3 && videoExist"
            src="../../../assets/office/details/video.png"
            alt=""
            @click="toImg(url)"
          />
        </div>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator" v-show="indicatorShow">
          {{ imgIndex + 1 }}/{{ img.length }}
        </div>
      </template>
    </van-swipe>
    <van-swipe v-else class="swipe" ref="swipe" height="328" @change="onChange">
      <van-swipe-item class="swipe-item">
        <img
          style="width: 100%; height: 100%"
          :src="require('../../../assets/no-img-details.png')"
          alt=""
        />
      </van-swipe-item>
    </van-swipe>
    <van-image-preview
      v-model="show"
      :startPosition="previewIndex"
      :images="images"
      @change="onChangePre"
    >
      <template v-slot:index> {{ previewIndex + 1 }}/{{ img.length }}</template>
    </van-image-preview>
  </div>
</template>
<script>
const los = window.los
export default {
  name: 'DetailsCarousel',
  components: {
  },
  props: {
    details: Object
  },
  data () {
    return {
      los,
      url: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
      indicatorShow: false,
      imgIndex: 0,
      previewIndex: 0,
      type: 1,
      img: [],
      length: null,
      count: 1,
      imgList: [],
      modelExist: true,
      vrExist: true,
      videoExist: false,
      imgVisible: true,
      show: false,
      images: []
    }
  },
  mounted () {
    let arr = []

    this.$nextTick(() => {
      if (this.$store.state.modelData.data[this.details.id]) {
        let { url1, panoramaUrl } = this.$store.state.modelData.data[this.details.id]
        if (!url1) this.modelExist = false
        if (!panoramaUrl) this.vrExist = false
      } else {
        this.modelExist = false
        this.vrExist = false
        this.videoExist = false
        this.imgVisible = false
        this.indicatorShow = true
      }
      // 判断是否存在模型、全景、视频
      if (this.modelExist) arr.push({ url: this.details.coverImg, type: 1 })
      if (this.vrExist) arr.push({ url: this.details.coverImg, type: 2 })
      // if (this.videoExist) arr.push({ url: this.details.coverImg, type: 3 })
      if (this.details.images)
        arr = arr.concat(this.details.images || [])
      this.img = arr.map((item, index) => {
        return {
          url: (item.url || item) + '?' + Date.now(),
          id: index + 1,
          type: item.type || 4
        }
      })
      console.log(this.img);
      this.imgList = this.img
      this.img = this.img.filter(item => item.type === 4)
    })
  },
  methods: {
    onChange (index) {
      this.indicatorShow = this.imgList[index].type === 4 ? true : false
      let length = this.imgList.filter(item => item.type !== 4).length
      this.type = this.imgList[index].type
      if (this.imgList[index].type === 4) {
        this.imgIndex = index - length;
      }
    },
    onChangePre (index) {
      this.previewIndex = index
    },
    toImg (item) {
      if (item.type === 1) {
        console.log('model');
        this.toModel()
      }
      if (item.type === 2) {
        console.log('vr');
        this.toVR()
      }
      // if(item.type===3){}
      if (item.type === 4) {
        this.previewIndex = this.imgIndex
        this.images = this.img.map(item => {
          return window.los + item.url + '?' + Date.now()
        })
        this.show = true
      }

    },
    toType (type) {
      console.log(type);
      let index = []
      this.imgList.forEach((item, i) => {
        if (item.type === type) {
          index.push(i)
        }
      })
      this.$refs.swipe.swipeTo(index[0])
    },

    toVR () {
      window.open(window.model + 'vr/3/' + this.details.id, '_self');
      // window.open(window.model + 'vr/3/1314658588555370496', '_blank');
    },
    toModel () {
      window.open(window.model + '/model/3/' + this.details.id, '_self');
    },
  }
};
</script>
<style lang="less" scoped>
.details-carousel {
  height: 328px;
  position: relative;
  .back {
    position: absolute;
    left: 25px;
    top: 25px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.36);
    padding: 8px;
    display: flex;
    cursor: pointer;
    z-index: 10;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .look-house-type {
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.36);
    overflow: hidden;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    .type-item {
      padding: 0 12px;
      font-size: 12px;
      height: 28px;
      color: rgba(255, 255, 255, 1);
      line-height: 28px;
      &.active {
        // padding: 0 16px;
        height: 26px;
        line-height: 26px;
        border-radius: 20px;
        background: rgba(236, 102, 0, 1);
      }
    }
    .line {
      width: 1px;
      height: 100%;
      background: #eee;
    }
  }
  .custom-indicator {
    position: absolute;
    right: 12px;
    bottom: 37px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.36);
  }
}
.swipe-item {
  position: relative;
  .gif {
    position: absolute;
    z-index: 100;
    width: 60px;
    height: 60px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
</style>