<template>
  <div class="footer">
    <div class="footer-left">
      <div class="nav">
        <div
          class="nav-item"
          :class="navIndex === item.id ? 'active' : ''"
          v-for="item in nav"
          :key="item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <p>地址：深圳市南山区科研路9号比克科技大厦1201D</p>
      <p>联系电话：0755-86336555/19926633862</p>
      <!-- <p>神州龙空间技术（深圳）有限公司 丨 粤ICP备16127842号</p> -->
      <p>
        亿楼网 丨 <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          alt=""
          style="font-size: 14px; font-weight: 400; color: #a6a6a6"
          >粤ICP备16127842号
        </a>
        <span class="about" @click="toAbout">关于我们</span>
      </p>
    </div>
    <div class="footer-right">
      <div class="code">
        <img src="../../assets/xcx-code.png" alt="" />
        <p>扫码关注小程序</p>
      </div>
      <div class="code">
        <img src="../../assets/gzh-code.png" alt="" />
        <p>扫码关注公众号</p>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'Footer',
  data () {
    return {
      navIndex: 2,
      nav: [
        // { id: 1, url: '', name: '写字楼' },
        // { id: 2, url: '', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' }
      ]
    }
  },
  methods: {
    toPush (item) {
      // this.$router.push({
      //   path: item
      // })
      let routeUrl = this.$router.resolve({
        path: item,
      })
      window.open(routeUrl.href, '_blank');
    },
    toAbout () {
      // let routeUrl = this.$router.resolve({
      //   path: '/my',
      // })
      window.open(window.plantUrl + 'my', '_blank');
    },

  }
};
  </script>
  
  <style lang="less" scoped>
.footer {
  width: 100%;
  height: 220px;
  padding: 0 calc(50% - 600px);
  background: rgba(51, 51, 51, 1);
  display: flex;
  justify-content: space-between;
  &-left {
    overflow: hidden;
    .nav {
      display: flex;
      align-items: center;
      margin: 39px 0 26px;
      &-item {
        margin-right: 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        color: rgba(153, 153, 153, 1);
        cursor: pointer;
        &.active {
          color: #fff;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(187, 187, 187, 1);
      margin-bottom: 12px;
      .about {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &-right {
    display: flex;
    align-items: center;
    .code {
      margin-left: 50px;
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
        background-color: #fff;
      }
      p {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
  